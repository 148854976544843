import { APP_URL } from "./common/constants"

export const routes = {
  generic: {
    dashboard: (): string => "/",
    yourChecklists: (): string => "/your-checklists",
    yourSubscriptions: (): string => "/your-subscriptions",
    admin: (): string => "/admin",
    adminCategories: (): string => "/admin/categories",
    yourStarred: (): string => "/your-stars",
    community: (category?: string): string =>
      category ? `/community/${category}` : "/community/explore",
    dashboardView: (): string => "/dashboard",
    aiChecklist: (search: string): string => `/ai-checklist/?search=${search}`,
  },
  auth: {
    login: (redirect?: string): string =>
      `/login${redirect && redirect !== "/community" ? `?redirect=${redirect}` : ""
      }`,
    signup: (redirect?: string): string =>
      `/signup${redirect ? `?redirect=${redirect}` : ""}`,
    forgotPassword: (): string => "/auth/password/forgot",
  },
  checklists: {
    activities: (slug: string, owner?: string): string =>
      owner ? `/${owner}/${slug}/activities` : `/checklists/${slug}/activities`,
    new: (): string => `/checklists/new`,
    show: (slug: string, owner?: string): string =>
      owner ? `/${owner}/${slug}` : `/checklists/${slug}`,
    qr: (slug: string, owner?: string, qrSlug?: string): string =>
      owner
        ? `/${owner}/${slug}/qr/${qrSlug}`
        : `/checklists/${slug}/qr/${qrSlug}`,
    runQr: (slug: string, owner?: string, runSlug?: string): string =>
      APP_URL + `/${owner}/${slug}/checklists/${runSlug}`,
    checklistQR: (slug: string, owner: string): string => `/${owner}/${slug}`,
    edit: (slug: string, owner?: string): string =>
      owner ? `/${owner}/${slug}/edit` : `/checklists/${slug}/edit`,
    copy: (slug: string): string => `/checklists/${slug}/copy`,
    favorite: (slug: string): string => `/checklists/${slug}/favorite`,
    unfavorite: (slug: string): string => `/checklists/${slug}/unfavorite`,
    runs: (slug: string, owner?: string): string =>
      owner ? `/${owner}/${slug}/checklists` : `/checklists/${slug}/runs`,
    comments: (slug: string, owner: string, commentId?: string): string =>
      `/${owner}/${slug}/comments${commentId ? `?id=${commentId}` : ""}`,
    schedule: (slug: string, owner?: string): string =>
      owner ? `/${owner}/${slug}/schedule` : `/schedule/${slug}`,
    old: (slug: string, owner?: string): string =>
      owner ? `/${owner}/${slug}/template` : `/checklists/${slug}`,
  },
  runs: {
    index: (slug: string): string => `/checklists/${slug}/checklists`,
    runs: (slug: string, owner: string): string =>
      `/${owner}/${slug}/checklists`,
    schedule: (slug: string): string => `/schedule`,
    show: (id: string): string => `/checklists/${id}`,
  },
  users: {
    profile: (username: string): string => `/${username}`,
    setUsername: (): string => "/set-username",
    editUser: (username: string): string => "/" + username + "/edit",
  },
}
