import { Context } from "overmind"

export function setFeaturedChecklists(
  { state }: Context,
  featuredChecklists: number
): void {
  state.system.featuredChecklists = featuredChecklists
}

export function changeFeaturedChecklistsCounter(
  { state }: Context,
  isFeatured: boolean
): void {
  if (isFeatured) state.system.featuredChecklists++

  state.system.featuredChecklists--
}

export function setSideBySide({ state }: Context, opened: boolean) {
  state.system.isSideBySideOpened = opened
}
