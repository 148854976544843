import classnames from "classnames"

export function inputClassNames({
  className,
  disabled,
  focused,
  size = "md",
}: {
  className?: string
  disabled?: boolean
  focused?: boolean
  size?: "sm" | "md" | "lg"
}): string {
  return classnames(
    "input",
    {
      "input--sm": size === "sm",
      "input--lg": size === "lg",

      "input--focus": focused,

      "input--disabled": disabled,
    },
    className
  )
}
