import React from "react"
import { DateHelper } from "@bonsaichecklist/bonsai-utils"

export function RunStatus({ run }: { run: ChecklistRun }): JSX.Element {
  let statusClasses: string
  let statusText: string

  if (run?.finishedAt) {
    statusClasses = "bg-secondaryGreen text-white"
    statusText = `Finished ${DateHelper.localeDate(run.finishedAt)}`
  } else if (run?.startedAt) {
    statusClasses = "bg-blue-500 text-white"
    statusText = `Started ${DateHelper.localeDate(run.startedAt)}`
  } else {
    statusClasses = "bg-secondaryGray text-customGray"
    statusText = "Unstarted"
  }

  return (
    <div className={`${statusClasses} rounded px-2 py-1 text-sm leading-none`}>
      {statusText}
    </div>
  )
}
