import classnames from "classnames"
import React from "react"

export interface PageHeadingProps extends React.HTMLProps<HTMLDivElement> {
  className?: string
  children?: React.ReactNode
}

export function PageHeading({
  className,
  children,
  ...props
}: PageHeadingProps): JSX.Element {
  const classes = classnames("text-3xl text-gray-900 font-bold", className)
  return (
    <h1 className={classes} {...props}>
      {children}
    </h1>
  )
}
