import debug from "debug"
import * as qs from "qs"
import { generateSlug } from "@bonsaichecklist/bonsai-utils"
import {
  CHECKLIST_VISIBILITY_UNLISTED,
  NEW_CHECKLIST_SLUG,
} from "~src/common/constants"
import {
  APIError,
  checklistTitle,
  cleanBody,
  FileHelper,
} from "~src/common/lib"
import { CHECKLISTS } from "~src/common/apiRoutes"
import { chainListAxios, errorReporter } from "~src/common/lib"
import axios from "axios"
const d = debug("bonsai/services/ChecklistService")

export interface FindFilters {
  username?: string
}

export interface IChecklistParams {
  verifiedOnly?: boolean
  page?: number
  limit?: number
  categoryId?: string
}

export class ChecklistService {
  public static async all(
    params?: IChecklistParams,
    headers?: GenericObject
  ): Promise<Checklist[]> {
    const query = qs.stringify({
      verifiedOnly: params?.verifiedOnly,
      page: params?.page,
      limit: params?.limit,
      ...(params.categoryId && { categoryId: params?.categoryId }),
    })

    const response = await chainListAxios.get(CHECKLISTS.findAll(query), {
      headers,
    })
    return response.data
  }

  public static async getAiGeneratedChecklist(
    text: string,
    prefix?: string
  ): Promise<[]> {
    const option = {
      model: process.env.NEXT_PUBLIC_OPEN_AI_MODEL,
      prompt: prefix && text ? prefix + " " + text : text,
      temperature: 0.5,
      max_tokens: 2048,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0.1,
    }
    const response = await axios.post(
      process.env.NEXT_PUBLIC_OPEN_AI_URL,
      option,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.NEXT_PUBLIC_OPEN_AI_KEY}`,
        },
      }
    )

    const chkl = response.data.choices?.[0].text
      .trim()
      .split("\n")
      .map((item: any) => item.replace(/\d\d\.|\d\./g, "").trim())
      .filter((i: any) => i)

    return chkl
  }

  public static async save(checklist: Checklist): Promise<Checklist> {
    try {
      const { slug, items, title, description, visibility } = checklist

      const response = await chainListAxios.put(CHECKLISTS.update(slug), {
        items: this.cleanItems(items),
        title: checklistTitle(title),
        description: description || "",
        visibility: visibility || CHECKLIST_VISIBILITY_UNLISTED,
      })
      return response.data
    } catch (error) {
      errorReporter.notify(error?.response?.data)

      throw new Error(
        "we have detected an error in (Save), the bug has been filed and team will be notified"
      )
    }
  }

  public static getTempChecklist({
    user,
    title,
    items,
  }: {
    user: User
    title: string
    items: ChecklistItem[]
  }): Checklist {
    const chklSlug = NEW_CHECKLIST_SLUG

    const checklistData: Checklist = {
      id: "62a2f3505229e007b0a0bee89999",
      verified: false,
      title: title,
      items,
      owner: {
        id: user.id,
        email: user.email,
        role: "user",
        accountStatus: user.accountStatus,
        username: user.username,
        createdAt: user.createdAt,
      },
      unscheduleItems: [],
      schedules: [],
      followers: [],
      slug: chklSlug,
      visibility: "unlisted",
      createdAt: new Date(),
      updatedAt: new Date(),
    }

    return checklistData
  }

  public static async create({
    title,
    items,
  }: {
    title: string
    items: ChecklistItem[]
  }): Promise<Checklist> {
    const response = await chainListAxios.post(CHECKLISTS.create, {
      title,
      items,
    })
    return response.data
  }

  public static async copy(
    slug: string,
    copySchedules: boolean
  ): Promise<{ slug: string }> {
    try {
      const response = await chainListAxios.post(CHECKLISTS.copy(slug), {
        copySchedules,
      })
      return response.data
    } catch (error) {
      throw error.response.data
    }
  }

  public static async getCopiedFrom(slug: string): Promise<Checklist> {
    try {
      const response = await chainListAxios.get(CHECKLISTS.isCopied(slug))
      return response.data
    } catch (error) {
      return null
    }
  }

  public static async findBySlug(
    slug: string,
    username?: string,
    headers?: GenericObject,
    forPage?: string
  ): Promise<Checklist> {
    const response = await chainListAxios.get(
      encodeURI(CHECKLISTS.getChecklist(slug, username, forPage)),
      headers && { headers }
    )
    return response.data
  }

  public static async findByRunSlug(runSlug: string): Promise<Checklist> {
    const response = await chainListAxios.get(
      CHECKLISTS.getChecklistByRunSlug(runSlug)
    )
    return response.data
  }

  public static async getMetaData(
    slug: string,
    headers?: GenericObject
  ): Promise<GenericObject> {
    try {
      const response = await chainListAxios.get(
        CHECKLISTS.meta(slug),
        headers && { headers }
      )
      return response.data
    } catch (error) {
      return null
    }
  }

  public static async findForUser(
    params: GenericObject,
    headers?: GenericObject,
    page?: number,
    limit?: number
  ): Promise<Checklist[]> {
    const query = qs.stringify({
      username: params.username,
      page: params.page,
      limit: params.limit,
      visibility: params.visibility,
    })

    const response = await chainListAxios.get(
      CHECKLISTS.findAll(query),
      headers && { headers }
    )
    return response.data
  }

  public static async trash(slug: string): Promise<void> {
    await chainListAxios.delete(CHECKLISTS.trash(slug))
  }

  public static async countFeaturedChecklists(
    headers: GenericObject
  ): Promise<number> {
    const response = await chainListAxios.get(
      CHECKLISTS.getFeaturedChecklists(),
      { headers }
    )
    return response.data
  }

  public static async toggleFeatureChecklist(
    slug: string,
    isFeatured: boolean
  ): Promise<boolean> {
    const response = await chainListAxios.put(
      CHECKLISTS.toggleFeatureChecklist(slug),
      {
        isFeatured: isFeatured,
      }
    )
    return response.data
  }

  public static async restoreFromTrash(slug: string): Promise<void> {
    await chainListAxios.post(CHECKLISTS.restore(slug))
  }

  public static async getUpcomingRun(
    slug: string,
    rootItems?: string[]
  ): Promise<GenericObject> {
    try {
      const upcomingRun = await chainListAxios.post(
        CHECKLISTS.upcomingRun(slug),
        {
          ...(rootItems?.length && { rootItems }),
        }
      )

      return upcomingRun
    } catch (error) {
      return null
    }
  }

  public static async follow(checklistSlug: string): Promise<Checklist | null> {
    try {
      const response = await chainListAxios.post(
        CHECKLISTS.follow(checklistSlug)
      )
      return response.data
    } catch (error) {
      throw error.response.data
    }
  }

  public static async unFollow(
    followedChecklistSlug: string,
    username: string
  ): Promise<Checklist> {
    const response = await chainListAxios.post(
      CHECKLISTS.unfollow(followedChecklistSlug, username)
    )
    return response.data
  }

  public static async subscribe(
    checklistSlug: string,
    email: string
  ): Promise<SubscribedTemplate> {
    try {
      const response = await chainListAxios.post(
        CHECKLISTS.subscribeTemplate(checklistSlug),
        { email: email }
      )
      return response.data
    } catch (error) {
      throw error.response.data
    }
  }

  public static async confirmSubscription(
    checklistSlug: string,
    token: string
  ): Promise<SubscribedTemplate | boolean> {
    try {
      const response = await chainListAxios.put(
        CHECKLISTS.confimrSubscribeTemplate(checklistSlug, token)
      )
      return response.data
    } catch (error) {
      return false
    }
  }

  public static async resubscribe(
    checklistSlug: string,
    email: string
  ): Promise<SubscribedTemplate> {
    try {
      const response = await chainListAxios.put(
        CHECKLISTS.resubscribeTemplate(checklistSlug),
        { email: email }
      )
      return response.data
    } catch (error) {
      throw error.response.data
    }
  }

  public static generateNewItem(values: {
    body?: string
    parent?: string
    order?: number
    itemType?: ItemType
  }): ChecklistItem {
    return {
      slug: generateSlug(),
      body: values.body || "",
      order: values.order || 1,
      itemType: values.itemType,
      ...values,
    }
  }

  private static cleanItems(items: ChecklistItem[]): ChecklistItemInput[] {
    const filteredItems = Object.values(items).filter(
      (item) => !item.linkedListItem
    )
    return filteredItems.map((item) => {
      // Strip out HTML
      return {
        id: item.id,
        order: item.order,
        slug: item.slug,
        attachments: item.attachments,
        body: cleanBody(item.body),
        parent: item.parent,
        copiedFrom: item?.copiedFrom,
        copiedAt: item?.copiedAt,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
      } as ChecklistItemInput
    })
  }

  public static async activities(
    slug: string,
    headers: GenericObject,
    queryParams?: IChecklistParams
  ): Promise<{ activityLogs: ActivityLog[]; countActivityLogs: number }> {
    const query = qs.stringify({
      ...queryParams,
    })

    try {
      const {
        data: { activityLogs = [], countActivityLogs = 0 },
      } = await chainListAxios.get(
        CHECKLISTS.activities(slug, query),
        headers && { headers }
      )

      return { activityLogs, countActivityLogs }
    } catch (error) {
      return error
    }
  }

  static async starChecklist(slug: string): Promise<boolean | void> {
    try {
      const { data } = await chainListAxios.put(CHECKLISTS.starChecklist(slug))

      return data
    } catch (error) {
      errorReporter.notify(error?.response?.data)
    }
  }
  static async toggleEmailNotification(
    slug: string,
    bodyData: any
  ): Promise<boolean | void> {
    try {
      const { data } = await chainListAxios.patch(
        CHECKLISTS.toggleEmailNotification(slug),
        bodyData
      )

      return data
    } catch (error) {
      errorReporter.notify(error?.response?.data)
    }
  }

  static async unStarChecklist(slug: string): Promise<boolean | void> {
    try {
      const { data } = await chainListAxios.put(
        CHECKLISTS.unStarChecklist(slug)
      )

      return data
    } catch (error) {
      errorReporter.notify(error?.response?.data)
    }
  }

  static async markChecklistVerified(
    slug: string,
    verified: boolean
  ): Promise<boolean | void> {
    try {
      const { data } = await chainListAxios.put(
        CHECKLISTS.markChecklistVerified(slug),
        {
          verified: verified,
        }
      )

      return data
    } catch (error) {
      errorReporter.notify(error?.response?.data)
      throw new Error("API Failed")
    }
  }

  static async setVisibility(slug: string): Promise<Checklist> {
    try {
      const { data } = await chainListAxios.put(CHECKLISTS.setVisibility(slug))
      return data
    } catch (error) {
      errorReporter.notify(error?.response?.data)
      return null
    }
  }

  static async getRunsScheduledRuns(
    slug: string
  ): Promise<ChecklistRunsScheduledRuns> {
    const response = await chainListAxios.get(
      encodeURI(CHECKLISTS.getActiveScheduledRunsCount(slug))
    )
    return response.data
  }

  static async getAllComments(slug: string): Promise<UserComment[]> {
    try {
      const response = await chainListAxios.get(
        encodeURI(CHECKLISTS.getComments(slug))
      )

      return response.data
    } catch (error) {
      throw new APIError(error)
    }
  }

  static async postComment(
    slug: string,
    description: string
  ): Promise<UserComment> {
    try {
      const response = await chainListAxios.post(
        encodeURI(CHECKLISTS.postComment(slug)),
        {
          content: description,
        }
      )
      return response.data
    } catch (error) {
      throw new APIError(error)
    }
  }

  static async updateComment(
    slug: string,
    id: string,
    content: string
  ): Promise<UserComment> {
    try {
      const response = await chainListAxios.patch(
        CHECKLISTS.updateComment(slug, id),
        {
          content,
        }
      )

      return response.data
    } catch (error) {
      throw new APIError(error)
    }
  }

  static async deleteComment(slug: string, id: string): Promise<UserComment> {
    try {
      const response = await chainListAxios.delete(
        CHECKLISTS.deleteComment(slug, id)
      )

      return response.data
    } catch (error) {
      throw new APIError(error)
    }
  }

  static async updateCommentVisibility(
    slug: string,
    id: string,
    hide: boolean
  ): Promise<UserComment> {
    try {
      const response = await chainListAxios.patch(
        CHECKLISTS.commentVisibility(slug, id),
        {
          hide,
        }
      )
      return response.data
    } catch (error) {
      throw new APIError(error)
    }
  }

  /**for template category works*/
  static async getTemplateCategories(): Promise<TemplateCategory[]> {
    try {
      const response = await chainListAxios.get(
        CHECKLISTS.getTemplateCategories()
      )
      return response.data
    } catch (error) {
      throw new APIError(error)
    }
  }

  static async assignCategoryToTemplate(
    slug: string,
    category?: TemplateCategory
  ): Promise<void> {
    try {
      const response = await chainListAxios.put(
        CHECKLISTS.updateTemplateCategory(slug),
        {
          categoryId: category?.id,
        }
      )
      return response.data
    } catch (error) {
      throw new APIError(error)
    }
  }

  public static async uploadCover(
    chklSlug: string,
    file: File
  ): Promise<string> {
    try {
      const response = await chainListAxios.post(
        CHECKLISTS.uploadCoverUrl(chklSlug),
        {
          fileName: file.name,
        }
      )

      const { uploadUrl, publicUrl } = response.data

      await FileHelper.uploadFile(uploadUrl, file)

      return publicUrl
    } catch (error) {
      throw new APIError(error)
    }
  }

  public static async setCoverFileUrl(
    chklSlug: string,
    fileUrl: string
  ): Promise<boolean> {
    try {
      const response = await chainListAxios.post(
        CHECKLISTS.setCoverFileUrl(chklSlug),
        {
          fileUrl,
        }
      )

      return response.data
    } catch (error) {
      throw new APIError(error)
    }
  }

  public static async removeCoverUrl(chklSlug: string): Promise<boolean> {
    try {
      const response = await chainListAxios.put(
        CHECKLISTS.removeCoverUrl(chklSlug)
      )

      return response.data
    } catch (error) {
      throw new APIError(error)
    }
  }

  public static async saveChecklist(checklist: Checklist): Promise<Checklist> {
    try {
      const {
        slug,
        items,
        title,
        description,
        visibility,
        unscheduleItems,
      } = checklist

      const response = await chainListAxios.put(
        CHECKLISTS.saveChecklist(slug),
        {
          items: this.cleanItems(items),
          title: checklistTitle(title),
          description: description || "",
          visibility: visibility || CHECKLIST_VISIBILITY_UNLISTED,
          unscheduleItems: unscheduleItems,
          ...checklist,
        }
      )
      return response.data
    } catch (error) {
      errorReporter.notify(error?.response?.data)

      throw new Error(
        "we have detected an error in (Save), the bug has been filed and team will be notified"
      )
    }
  }

  public static async getChecklist(
    slug: string,
    headers?: GenericObject,
    needEnode?: boolean
  ): Promise<Checklist> {
    try {
      let url = CHECKLISTS.getChecklistToEdit(slug)
      if (needEnode) url = encodeURI(url)
      const response = await chainListAxios.get(url, { headers })
      return response.data
    } catch (error) {
      // console.trace(error.message)
      throw new APIError(error)
    }
  }
}
