import React from "react"
import { connectInfiniteHits } from "react-instantsearch-dom"

import { useState } from "~src/store"
import { CommunityChecklistView } from "../community-checklist-view"
import { CustomHitsProps } from "./community-hits.types"

function CustomHits({
  hits,
  isSearchResultOpened,
  layout,
  categories,
  search,
}: CustomHitsProps) {
  const {
    auth: { user },
  } = useState()

  if (hits.length <= 20 && user?.username) {
    hits.sort((x, y) => {
      let n = 0
      if (x?.owner?.username === user?.username) n = -1
      else if (y?.owner?.username === user?.username) n = 1
      return n
    })
  }

  return (
    <CommunityChecklistView
      categories={categories}
      checklistData={hits}
      isSearchResultOpened={isSearchResultOpened}
      layout={layout}
      search={search}
    />
  )
}
export const CommunityHits = connectInfiniteHits(CustomHits)
