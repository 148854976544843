import classnames from "classnames"
import debug from "debug"

const d = debug("ChainList/components/Editor/utils")

interface ButtonClassesParams {
  disabled?: boolean
  active?: boolean
}

export function buttonClasses(opts?: ButtonClassesParams): string {
  const disabled = opts?.disabled
  const active = opts?.active
  const baseClasses =
    "block text-sm text-blue-800 p-2 leading-none rounded transition duration-200 ease-in-out"
  const enabledClasses =
    "text-blue-800 cursor-pointer hover:text-gray-900 hover:bg-blue-100"
  const disabledClasses = "text-gray-300"
  return classnames(baseClasses, {
    [enabledClasses]: !disabled,
    [disabledClasses]: disabled,
    "bg-blue-100": active,
  })
}
