import { OnInitialize } from "overmind"
import debug from "debug"
// import { APIService } from "./effects"

const d = debug("ChainList/store/onInitialize")

export const onInitialize: OnInitialize = async (
  { state, actions, effects },
  overmind
) => {
  d("initializing app")

  // Configure the application origin on load of application
  // for the client. This is handled differently for the
  // server.
  // APIService.origin = window?.location?.origin
}
