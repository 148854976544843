import React from "react"

interface SidebarProps extends React.HTMLProps<HTMLDivElement> {
  children?: React.ReactNode
  showSidebar?: boolean
  sideBarRef?: React.MutableRefObject<HTMLDivElement>
}

export function Sidebar({
  children,
  showSidebar,
  sideBarRef,
}: SidebarProps): JSX.Element {
  const backdropClasses = `overflow-hidden right-0 z-10 bg-gray-900 bg-opacity-25 transform ease-in-out absolute h-screen ${
    showSidebar
      ? "transition-opacity opacity-100 duration-500 translate-x-0 w-full"
      : "transition-all delay-500 w-0 duration-500 opacity-0"
  }`

  return (
    <main className={backdropClasses}>
      <section
        className={`w-max max-w-sm right-0 absolute bg-white h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform
        ${showSidebar ? "translate-x-0" : "translate-x-full"}`}
      >
        <div
          className={`relative h-full w-max max-w-sm right-0 p-4 sm:p-8 border-l border-gray-500 duration bg-white text-gray-700`}
          ref={sideBarRef}
        >
          {children}
        </div>
      </section>
    </main>
  )
}
