import debug from "debug"
import { chainListAxios, FileHelper } from "~src/common/lib"
import { CHECKLISTS } from "~src/common/apiRoutes"
import { generateSlug } from "@bonsaichecklist/bonsai-utils"

const d = debug("ChainList/services/AttachmentService")

type UploadURLResponse = {
  publicUrl: string
  uploadUrl: string
}

export class AttachmentService {
  public static async upload(
    chklSlug: string,
    itemSlug: string,
    files: File[]
  ): Promise<Attachment[]> {
    const attachments = await Promise.all(
      files.map(async (file) => {
        d("creating attachment:", { chklSlug, itemSlug, filename: file.name })
        const { uploadUrl, publicUrl } = await this.getUploadURL(
          chklSlug,
          file.name
        )
        await FileHelper.uploadFile(uploadUrl, file)
        return AttachmentService.generateNewAttachment(publicUrl, file)
      })
    )

    d("created attachments:", attachments)

    return attachments
  }

  private static async getUploadURL(
    chklSlug: string,
    fileName: string
  ): Promise<UploadURLResponse> {
    const response = await chainListAxios.post(
      CHECKLISTS.attachmentUrl(chklSlug),
      { fileName }
    )
    return response.data
  }

  public static generateNewAttachment(url: string, file: File): Attachment {
    return {
      slug: generateSlug(),
      url,
      fileName: file.name,
      type: file.type,
    }
  }
}
