import React from "react"
import { Link } from "~src/components"
import { routes } from "~src/routes"

export interface ServerErrorMessageProps {
  error?: Error | string
}

export function ServerErrorMessage({
  error,
}: ServerErrorMessageProps): JSX.Element {
  return (
    <>
      <div>
        <h1 className="text-2xl mb-8 mt-4">
          Oops{" "}
          <span aria-label="sad-face" role="img">
            😢
          </span>
        </h1>
      </div>
      <div className="mb-6 leading-normal">
        Something went wrong, please try going back or heading to the{" "}
        <Link href={routes.generic.dashboard()}>homepage</Link>.
      </div>
      <div className="mb-6 leading-normal">
        <strong>Or</strong> Let us know by emailing{" "}
        <Link href="mailto:support@chainlist.com">support@chainlist.com</Link>
      </div>
      {error && (
        <pre className="text-red-600 mt-3">
          ERROR: <span>{error.toString()}</span>
        </pre>
      )}
    </>
  )
}
