import { ChecklistItemsHelper } from "@bonsaichecklist/bonsai-utils"

interface Item {
  parent?: string
  order: number
  slug: string
}

interface ItemMap<T> {
  [Key: string]: T
}

export function sortRunItems<T extends Item>(
  itemsToRun: string[],
  items: T[],
  itemMap?: ItemMap<T>,
  showChilds?: boolean
): T[] {
  if (!itemsToRun?.length) return ChecklistItemsHelper.getRootItems(itemMap)

  const itemsToRunMap: ItemMap<string> = itemsToRun.reduce((prev, curr) => {
    return { ...prev, [curr]: curr }
  }, {})

  return items.filter((item) => filteringItems(item, itemsToRunMap, showChilds))
}

function filteringItems<T extends Item>(
  item: T,
  itemsToRunMap: ItemMap<string>,
  showChilds = true
): boolean {
  if (
    item?.parent &&
    item?.parent === itemsToRunMap[item.parent] &&
    !showChilds
  )
    return false

  if (item?.slug === itemsToRunMap[item.slug]) return true

  return false
}
