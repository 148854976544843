import React from "react"
import { useClickAway } from "react-use"
import { Alert, Radio } from "~src/components"
import { useStore } from "~src/store"

export function VisibilitySelector(): JSX.Element {
  const [expanded, setExpanded] = React.useState<boolean>(false)
  const [showWarning, setShwoWarning] = React.useState<boolean>(false)

  const ref = React.useRef<HTMLDivElement>(null)

  const {
    state: {
      checklist: { current, itemMap },
    },
    actions: {
      checklist: { setVisibility },
    },
  } = useStore()

  function toggleExpanded(): void {
    setExpanded(!expanded)
  }

  useClickAway(ref, (e) => {
    if (!expanded) return
    toggleExpanded()
  })

  let canShareToCommunity: boolean
  for (const itemSlug in itemMap) {
    if (itemMap[itemSlug]?.body?.length > 0) {
      canShareToCommunity = itemMap[itemSlug]?.body.length > 0

      break
    }
  }

  function handleSelection(visibility: ChecklistVisibility): void {
    if (visibility === "public" && !canShareToCommunity) {
      setShwoWarning(true)
      setTimeout(() => setShwoWarning(false), 10000)
      return
    }
    setShwoWarning(false)

    setVisibility(visibility)
    toggleExpanded()
  }

  const visibilityOptions = {
    unlisted: (
      <div className="flex flex-col md:flex-row leading-5">
        <strong className="flex-none">Unlisted (default)</strong>
        <p className="hidden md:inline-block mx-0 md:mx-1">-</p>
        <p className="text-gray-600 md:text-black leading-loose md:leading-5">
          Hidden on ChainList and search engines. Anyone with the link can view
          it.
        </p>
      </div>
    ),
    public: (
      <div className="flex flex-col md:flex-row leading-5 content-center">
        <strong className="flex-none">Community</strong>
        <p className="hidden md:inline-block mx-0 md:mx-1">-</p>
        <p className="text-gray-600 md:text-black leading-loose md:leading-5">
          Share to Community, where it can be publicly viewed by others.
        </p>
      </div>
    ),
  }

  return (
    <div className="mt-8 border border-gray4 p-6" ref={ref}>
      <label
        className="text-md mb-2 mr-2 text-black font-bold"
        htmlFor="visibility"
      >
        Set the viewers of this template
      </label>
      <div className="py-3 text-sm">
        {Object.keys(visibilityOptions).map((visibility) => {
          const isActive = visibility === current.visibility

          return (
            <div
              className={`pl-2 hover:bg-gray-100 cursor-pointer ${
                visibility === "public" && !canShareToCommunity
                  ? "hover:text-red-600"
                  : "hover:text-blue-600"
              }`}
              key={visibility}
              onClick={(): void =>
                handleSelection(visibility as ChecklistVisibility)
              }
            >
              <Radio
                checked={isActive}
                id={visibility}
                label={
                  visibilityOptions[
                    visibility as keyof typeof visibilityOptions
                  ]
                }
                labelFor={visibility}
                name="visibility"
              />
            </div>
          )
        })}
      </div>
      {showWarning && (
        <Alert
          keepOpen
          text="Your checklist needs some items before it can be shared to the Community."
          type="warning"
        />
      )}
    </div>
  )
}
