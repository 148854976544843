import { RRule, Weekday } from "rrule"
import { ScheduleHelper } from "./"
import dayjs from "dayjs"
import { weekDays as WeekDays } from "~src/components/EditScheduleForm/helpers"
export class RecurringRule {
  public static RunFrequencyMapping = {
    yearly: 0,
    monthly: 1,
    weekly: 2,
    daily: 3,
    hourly: 4,
    minutely: 5,
  }
  public static createRRuleInstance(schedule: Schedule): RRule {
    const {
      recurrencePattern: pattern,
      startedAt,
      until,
      customOptions,
    } = schedule

    if (pattern === "custom") {
      // if you place bymonthday: [], this will result in different behaviour

      const daysArray = Object.keys(WeekDays) as Weekdays[]
      const dayIndex = dayjs(startedAt).day() - 1
      const rruleDay = daysArray[
        dayIndex === -1 ? daysArray?.length - 1 : dayIndex
      ] as Weekdays
      const days = customOptions?.byweekday as Weekdays[]
      const yearlyWeekday =
        customOptions?.byweekday &&
        (customOptions?.byweekday as SingleWeekDay[])[0]

      const config = {
        freq: RecurringRule.RunFrequencyMapping[customOptions.freq],
        dtstart:
          typeof startedAt === "string" ? new Date(startedAt) : startedAt,
        interval: customOptions?.interval || 1,
        ...(until && {
          until: typeof until === "string" ? new Date(until) : until,
        }),

        ...(customOptions?.freq === "weekly" && {
          byweekday: days.map((day) => RRule[day]),
        }),
        ...(customOptions?.freq === "monthly" && {
          byweekday: RRule[daysArray[yearlyWeekday.weekday]].nth(
            yearlyWeekday.n
          ),
        }),
        ...(customOptions?.freq === "yearly" && {
          ...(yearlyWeekday && {
            byweekday: RRule[daysArray[yearlyWeekday.weekday]].nth(
              yearlyWeekday.n
            ),
          }),
          bymonth: customOptions.bymonth,
        }),
        // ...(customOptions?.freq === "monthly" && {
        //   ...(customOptions?.monthRepeat?.value !== "monthDay" && {
        //     byweekday:
        //       customOptions?.monthRepeat?.value === "firstDay"
        //         ? RRule[rruleDay].nth(1)
        //         : RRule[rruleDay].nth(-1),
        //   }),
        //   ...(customOptions?.monthRepeat?.value === "monthDay" && {
        //     bymonthday: new Date(startedAt).getDate(),
        //   }),
        // }),
      }
      return new RRule(config)
    }

    const scheduleConfig = ScheduleHelper.getScheduleConfig({
      pattern: pattern as RECURRENCE_PATTERNS,
      startedAt,
      until,
    })
    const {
      byWeekday,
      byMonthday,
      interval,
      frequency,
      weekOfMonth,
    } = scheduleConfig
    const weekDays = byWeekday?.map((day) =>
      weekOfMonth ? RRule[day].nth(weekOfMonth) : day
    )

    return new RRule({
      freq: frequency ? RecurringRule.RunFrequencyMapping[frequency] : 0,
      interval,
      ...(frequency !== "yearly" && {
        byweekday: weekDays,
        bymonthday: byMonthday,
      }),
      dtstart: typeof startedAt === "string" ? new Date(startedAt) : startedAt,
      until: typeof until === "string" ? new Date(until) : until,
    })
  }
}
