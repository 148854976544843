import { EditorState } from "draft-js"
import { derived } from "overmind"
import { ChecklistItemsHelper } from "@bonsaichecklist/bonsai-utils"
import { hasChecklistItems } from "@bonsaichecklist/bonsai-utils"

type NameChangeState = "rename" | "renaming"
export type EditorMap = { [id: string]: EditorState }

type State = {
  // Current checklist state (detail/edit)
  current?: Checklist
  rootItems?: ChecklistItem[]
  hasItems: boolean
  itemMap?: ChecklistItemMap
  checklistScheduleMap?: ChecklistScheduleMap
  schedules?: string[]
  focusedTitle?: boolean
  showScheduleDialogue?: boolean
  seeMoreChklSlug?: string
  focusedDescription?: boolean
  activeItemSlug?: string
  activeItem?: ChecklistItem
  firstItem?: ChecklistItem
  saving?: boolean
  redirecting?: boolean
  unsavedChanges?: boolean
  checklistVerified?: boolean
  visibilityChanged?: Checklist
  featuredChecklists?: number

  // Checklist name change (detail/edit)
  nameChangeState?: NameChangeState
  renamingError?: Error

  //checklist edit state
  editMode?: boolean

  showUnsavedAlert?: boolean
  //dashboard copyChecklistHashMap
  copyChecklistHashMap?: CopyChecklistHashMap
}

export const state: State = {
  activeItem: derived(({ itemMap, activeItemSlug }: State) => {
    if (!activeItemSlug || !itemMap) return undefined
    return itemMap[activeItemSlug]
  }),
  firstItem: derived(({ itemMap }: State) =>
    ChecklistItemsHelper.getRootItems<ChecklistItem>(itemMap).find(
      (i) => i.order === 1
    )
  ),
  hasItems: derived(({ itemMap }: State) => hasChecklistItems(itemMap)),
  rootItems: derived(({ itemMap }: State) =>
    ChecklistItemsHelper.getRootItems<ChecklistItem>(itemMap)
  ),
  checklistVerified: false,
  visibilityChanged: null,
  editMode: false,
  showUnsavedAlert: false,
  checklistScheduleMap: {},
  schedules: [],
}
