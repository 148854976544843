import { EditorState } from "draft-js"
import React, { RefObject } from "react"
import { useClickAway } from "react-use"
import {
  Editor,
  EditorCommand,
  EditorProps,
  ToolbarProps,
} from "~src/components/Editor"

export interface DescriptionFieldProps {
  value: string
  placeholder?: string
  id: string
  handleSetContent: (html: string) => void
  size?: EditorProps["size"]
  toolbarVariant?: ToolbarProps["variant"]
}

function DescriptionField(
  {
    value,
    handleSetContent,
    placeholder = "",
    id,
    size = "md",
    toolbarVariant = "description",
  }: DescriptionFieldProps,
  ref: RefObject<HTMLDivElement>
): JSX.Element {
  const [focused, setFocused] = React.useState<boolean>(false)

  function handleBlur(): void {
    setFocused(false)
  }

  function handleFocus(): void {
    setFocused(true)
  }
  useClickAway(ref, (e) => {
    setFocused(false)
  })

  return (
    <div ref={ref} id={id}>
      <Editor
        className={`flex flex-row justify-between   `}
        cursorAtEnd={focused}
        defaultValue={value}
        editorKey={id}
        focused={focused}
        onFocus={handleFocus}
        keyBindingFn={keyBindingFn}
        onBlur={handleBlur}
        placeholder={placeholder}
        setContent={handleSetContent}
        size={size}
        toolbarProps={{
          toolbarItemSlug: id,
          variant: toolbarVariant,
        }}
      />
    </div>
  )
}

const DescriptionFieldWithRef = React.forwardRef(DescriptionField)
export default DescriptionFieldWithRef
/**
 * Handle our custom actions by returning a command that
 * handleKeyCommand will then use to trigger the associated actions.
 *
 * Moved this out of the component so we don't have to re-create on
 * every render.
 */
function keyBindingFn(editorState: EditorState) {
  return (e: React.KeyboardEvent<HTMLInputElement>): EditorCommand | void => {
    const key = e.key.toLowerCase()

    const shift = e.shiftKey
    const cursorAtBeginning = editorState.getSelection().getStartOffset() === 0

    switch (key) {
      case "arrowup":
        return shift ? "move-up" : "focus-prev"
      case "arrowdown":
        return shift ? "move-down" : "focus-next"
      case "tab":
        return shift ? "unindent" : "indent"
      case "enter":
        if (e.metaKey) return "save"
        return shift || cursorAtBeginning ? "insert-above" : "insert-below"
      default:
        break
    }
  }
}
