import React from "react"

import { errorReporter } from "~src/common/lib"
import { Alert, GoogleLoginButton, OtpInput, Button } from "~src/components"
import { SignUpForm } from "../signup-form"
import { MODAL_TYPES, PASSWOR_REGEX } from "~src/common/constants"
import { AuthLayout } from "~src/layouts"
import { useStore } from "~src/store"
import { AuthService } from "~src/services"
import Markverified from "~public/mark-verified.svg"
import { useShowAuthModals } from "~src/hooks/useShowAuthModals"
import router from "next/router"
import { routes } from "~src/routes"
import { AppleLoginButton } from "~src/components/AppleLoginButton/AppleLoginButton"
import { props } from "./signup-modal-content.types"

export default function SignupModalContent({
  onEmailSent,
}: props): JSX.Element {
  const [signingUp, setSigningUp] = React.useState<boolean>(false)
  const [emailSentTo, setEmailSentTo] = React.useState<string>()
  const [passwordError, setPasswordError] = React.useState<boolean>(false)
  const [otp, setOtp] = React.useState<string>("")
  const [isEmailVerified, setIsEmailVerified] = React.useState<boolean>(false)
  const [errorMsg, setErrorMsg] = React.useState()
  const onChange = (value: string) => setOtp(value)

  const {
    actions: {
      auth: { register, setTempAuthResponse },
      system: { removeToastAlert },
    },
    state: {
      system: { toastAlerts },
      auth: { action },
    },
  } = useStore()

  const { handleModal } = useShowAuthModals()

  React.useEffect(() => {
    return (): void => {
      toastAlerts.forEach((toast) => {
        removeToastAlert(toast.id)
      })
    }
  }, [removeToastAlert, toastAlerts])

  async function handleFormSubmit(values: {
    email: string
    password: string
    username: string
  }): Promise<void> {
    try {
      if (
        !PASSWOR_REGEX.test(values?.password) ||
        values?.password?.length < 8
      ) {
        setPasswordError(true)
        return
      } else {
        setPasswordError(false)
      }

      setSigningUp(true)
      const response = await register(values)
      if (response) {
        setEmailSentTo(values.email)
        onEmailSent && onEmailSent(values.email)
      }
    } catch (err) {
      errorReporter.notify(err)
    }
    setSigningUp(false)
  }

  const verifyOtp = async () => {
    try {
      const response = await AuthService.verifyEmail(otp)
      if (response) {
        setTempAuthResponse(response)
        setIsEmailVerified(true)
      }
    } catch (error) {
      setErrorMsg(error)
    }
  }

  if (isEmailVerified) {
    return (
      <div className="flex flex-col items-center ">
        <img alt="Mark-Verified" src={Markverified} />
        <p className="text-xl font-bold text-black mt-5 ">
          Your e-mail has been verified
        </p>
        <p className="text-sm font-normal text-gray-900 mt-2">
          Welcome to ChainList
        </p>
        <Button
          className="text-sm font-normal mt-9"
          onClick={() => {
            handleModal(MODAL_TYPES.signup, { key: action?.key })
            if (router.asPath === "/") {
              router.push(routes.generic.community())
            }
          }}
        >
          Let’s get started
        </Button>
      </div>
    )
  }

  return (
    <AuthLayout>
      <>
        {toastAlerts?.map((toast) => (
          <Alert
            className="mb-4"
            key={toast.id}
            {...toast}
            onHide={removeToastAlert}
          />
        ))}
        {!emailSentTo ? (
          <>
            <GoogleLoginButton fullWidth />
            <div className="my-6 text-center">
              <AppleLoginButton fullWidth />
            </div>
            <div className="text-gray-400 my-8 text-center border-t">OR</div>
            <SignUpForm
              onSubmit={handleFormSubmit}
              passwordError={passwordError}
              signingUp={signingUp}
            />
          </>
        ) : (
          <div>
            <p className=" text-xl font-bold text-black mb-4">
              Please verify your email
            </p>
            <p className="text-sm font-normal text-gray-900 ">
              {" "}
              The verification email has been sent to{" "}
              <strong>{emailSentTo}</strong>. Please verify your account to
              proceed.
            </p>
            <p className="text-sm font-bold mt-10 text-gray-900">
              Verification code
            </p>
            <OtpInput
              errorMsg={errorMsg}
              onChange={onChange}
              setErrorMsg={setErrorMsg}
              value={otp}
              valueLength={6}
              verifyOtp={verifyOtp}
            />
          </div>
        )}
      </>
    </AuthLayout>
  )
}
