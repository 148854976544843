import React from "react"
import * as yup from "yup"
import { Button, Form, Input, Link } from "~src/components"
import { routes } from "~src/routes"

const schema = yup.object().shape({
  email: yup.string().email().required(),
})

export interface ForgotPasswordFormProps {
  onSubmit: (values: any) => void | Promise<void>
  isSubmitting: boolean
}

export function ForgotPasswordForm({
  onSubmit,
  isSubmitting,
}: ForgotPasswordFormProps): JSX.Element {
  return (
    <>
      <p>
        No problem, Just enter your ChainList email address below and we&apos;ll
        send you an email to reset your password.
      </p>
      <div className="my-6">
        <Form onSubmit={onSubmit} schema={schema} values={{}}>
          {({ values, errors, handleChange, handleSubmit }): JSX.Element => (
            <form onSubmit={handleSubmit}>
              {errors?.email && (
                <div className="mb-2 text-red-600">{errors.email}</div>
              )}
              <Input
                autoCapitalize="none"
                autoComplete="off"
                autoCorrect="off"
                autoFocus
                id="email"
                onChange={handleChange}
                placeholder="Enter your email..."
                value={values.email}
              />
              <div className="mt-8">
                <Button
                  loading={isSubmitting}
                  onClick={handleSubmit}
                  type="submit"
                >
                  {isSubmitting ? "Sending..." : "Send Recovery Email"}
                </Button>
              </div>
            </form>
          )}
        </Form>
      </div>
      <div className="mt-6">
        Changed your mind? <Link href={routes.generic.community()}>Log in</Link>
      </div>
    </>
  )
}
