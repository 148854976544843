export const AUTH = {
  signup: "auth/signup",
  login: "auth/login",
  googleLogin: "auth/social-login/google",
  appleLogin: "auth/social-login/apple",
  logout: "auth/logout",
  forgotPassword: "auth/forgot-password",
  resetPassword: "auth/reset-password",
  verifyResetPasswordToken: (token: string): string =>
    `auth/token/verify/${token}`,
  isUsernameAvailable: (username: string): string =>
    `auth/is-username-available/${username}`,
  setUsername: "auth/set-username",
  me: "auth/me",
  publicSearchToken: "auth/search-token",
  communitySearchToken: "auth/community-search-token",
  verifyEmailToken: (token: string): string => `auth/email/verify/${token}`,
  resendVerifyEmailToken: (): string => "auth/resend-verify-email/token",
}

export const USERS = {
  find: (username: string): string => `users/${username}`,
  updateProductTour: (): string => `users/product-tour`,
  resetProductTour: (): string => `users/reset-user-tour-guides`,
  subscribedChecklist: (query?: string): string =>
    `users/your-subscriptions?${query || ""}`,
  starredChecklist: (query?: string): string =>
    `users/starred/checklists?${query || ""}`,
  userActivityLog: (query?: string): string =>
    `activity-logs/for/user/?${query || ""}`,
  updateInfoDialog: (): string => `users/info/dialog`,
  createNewCategory: (): string => `categories/create`,
  deleteCategory: (slug: string): string => `categories/${slug}`,
  updateCategory: (slug: string): string => `categories/${slug}`,
  getAvatar: (username: string): string => `users/avatar/${username}`,
  uploadAvatar: (): string => `users/profile/update/upload`,
  updateProfile: (): string => `users/profile/update`,
}

const chklBaseUrl = (slug?: string): string =>
  `checklists${slug ? `/${slug}` : ""}`

export const CHECKLISTS = {
  activities: (slug: string, query?: string): string =>
    `activity-logs/${slug}/?${query || ""}`,
  meta: (slug: string): string => `${chklBaseUrl(slug)}/meta`,
  create: "checklists",
  update: (slug: string): string => chklBaseUrl(slug),
  attachmentUrl: (slug: string): string =>
    `${chklBaseUrl(slug)}/attachments/url`,
  getChecklist: (slug: string, username: string, forPage?: string): string =>
    `${chklBaseUrl(slug)}/user/${username}?page=${forPage || "view"}`,
  getChecklistByRunSlug: (runSlug: string): string =>
    `${chklBaseUrl()}/findByRun/${runSlug}`,
  getActiveScheduledRunsCount: (slug: string): string =>
    `${chklBaseUrl(slug)}/schedules/runs`,
  toggleFeatureChecklist: (slug: string): string =>
    `${chklBaseUrl(slug)}/toggle/feature`,
  getFeaturedChecklists: () => `${chklBaseUrl()}/featured`,

  findAll: (query?: string): string => `${chklBaseUrl()}?${query || ""}`,
  follow: (slug: string): string => `${chklBaseUrl(slug)}/follow`,
  unfollow: (slug: string, username: string): string =>
    `${chklBaseUrl(slug)}/${username}/unfollow`,
  copy: (slug: string): string => `${chklBaseUrl(slug)}/copy`,
  isCopied: (slug: string): string => `${chklBaseUrl(slug)}/copy/is-copied`,
  trash: (slug: string): string => `${chklBaseUrl(slug)}/trash`,
  restore: (slug: string): string => `${chklBaseUrl(slug)}/trash/restore`,
  upcomingRun: (slug: string): string => `${chklBaseUrl(slug)}/upcoming-runs`,
  starChecklist: (slug: string): string => `${chklBaseUrl(slug)}/star`,
  toggleEmailNotification: (slug: string): string =>
    `${chklBaseUrl(slug)}/toggle-email-notification`,
  unStarChecklist: (slug: string): string => `${chklBaseUrl(slug)}/unStar`,
  markChecklistVerified: (slug: string): string =>
    `${chklBaseUrl(slug)}/set-verified`,
  setVisibility: (slug: string): string =>
    `${chklBaseUrl(slug)}/set-visibility`,
  postComment: (slug: string): string => `${chklBaseUrl(slug)}/comment`,
  getComments: (slug: string): string => `${chklBaseUrl(slug)}/comment`,
  updateComment: (slug: string, id: string): string =>
    `${chklBaseUrl(slug)}/comment/${id}`,
  deleteComment: (slug: string, id: string): string =>
    `${chklBaseUrl(slug)}/comment/${id}`,
  commentVisibility: (slug: string, id: string): string =>
    `${chklBaseUrl(slug)}/comment/${id}/hide`,
  getTemplateCategories: (): string => `categories`,
  updateTemplateCategory: (slug: string): string =>
    `${chklBaseUrl(slug)}/changeCategory`,
  subscribeTemplate: (slug: string): string => `${chklBaseUrl(slug)}/subscribe`,
  resubscribeTemplate: (slug: string): string =>
    `${chklBaseUrl(slug)}/subscribe/resend`,
  confimrSubscribeTemplate: (slug: string, token: string): string =>
    `${chklBaseUrl(slug)}/subscribe/confirm/${token}`,
  uploadCoverUrl: (slug: string): string => `${chklBaseUrl(slug)}/uploadCover`,
  setCoverFileUrl: (slug: string): string => `${chklBaseUrl(slug)}/setCoverUrl`,
  removeCoverUrl: (slug: string): string =>
    `${chklBaseUrl(slug)}/removeChecklistCover`,
  saveChecklist: (slug: string): string => `${chklBaseUrl()}/timeline/${slug}`,
  getChecklistToEdit: (slug: string): string =>
    `${chklBaseUrl()}/timeline/${slug}`,
}

export const SCHEDULES = {
  create: (slug: string): string => `${chklBaseUrl(slug)}/schedules`,
  findAll: (slug: string, query?: string): string =>
    `${chklBaseUrl(slug)}/schedules/?${query || ""}`,
  find: (slug: string, id: string): string =>
    `${chklBaseUrl(slug)}/schedules/${id}`,
  info: (slug: string): string => `${chklBaseUrl(slug)}/schedules/info`,
  cancelOccurrence: (slug: string, id: string): string =>
    `${chklBaseUrl(slug)}/schedules/${id}/cancel`,
  cancelSchedule: (slug: string, id: string): string =>
    `${chklBaseUrl(slug)}/schedules/${id}/cancel/all`,

  // chainList2.0 routes
  getScheduleChecklist: (slug: string, username: string): string =>
    username
      ? `${chklBaseUrl(slug)}/scheduled-checklist/${username}`
      : `${chklBaseUrl(slug)}/scheduled-checklist`,
  getScheduleChecklistItems: ():string => `${chklBaseUrl()}/schedules`,
  getUnScheduleChecklist: (slug: string): string =>
    `${chklBaseUrl(slug)}/unscheduled`,
  getUnScheduleChecklistData: (slug: string, username: string): string =>
    `${chklBaseUrl("timeline")}/${slug}/unscheduled/${username}`,

  upcomingChecklistCheck: (slug: string, username: string): string =>
    `${chklBaseUrl(slug)}/upcoming/run/${username}`,
  unScheduleItemCheck: (slug: string, username: string): string =>
    `${chklBaseUrl("timeline")}/${slug}/unscheduled/${username}`,

  viewPastItems: (
    slug: string,
    username: string,
    date: Date | string,
    runSlug?: string
  ): string =>
    `${chklBaseUrl(slug)}/history/${username}/${date}${
      runSlug && runSlug.length ? `?runSlug=${runSlug}` : ""
    }`,

  viewPastItemsCount: (
    slug: string,
    username: string,
    runSlug?: string
  ): string => {
    return `${chklBaseUrl(slug)}/history/${username}/count${
      runSlug && runSlug.length ? `?runSlug=${runSlug}` : ""
    }`
  },
}

const runsBase = (slug: string, runSlug?: string) =>
  `${chklBaseUrl(slug)}/runs/${runSlug}`

const itemsBase = (slug: string, runSlug: string, itemSlug?: string) =>
  `${runsBase(slug)}/items/${itemSlug}`

export const CHECKLIST_RUNS = {
  create: (slug: string): string => `${chklBaseUrl(slug)}/runs`,
  update: (slug: string, runSlug: string): string =>
    `${chklBaseUrl(slug)}/runs/${runSlug}`,
  findAll: (slug: string, query?: string): string =>
    `${chklBaseUrl(slug)}/runs/?${query || ""}`,
  find: (slug: string, runSlug: string): string =>
    `${chklBaseUrl(slug)}/runs/${runSlug}`,
  checkItem: (slug: string, runSlug: string, itemSlug: string): string =>
    `${chklBaseUrl(slug)}/runs/${runSlug}/items/${itemSlug}/check`,
  uncheckItem: (slug: string, runSlug: string, itemSlug: string): string =>
    `${chklBaseUrl(slug)}/runs/${runSlug}/items/${itemSlug}/uncheck`,
  deleteRun: (slug: string, runSlug: string): string =>
    `${chklBaseUrl(slug)}/runs/${runSlug}`,
}

export const FEEDBACK = {
  send: "feedback",
}

export const DASHBOARD = {
  activeChecklists: (): string => "dashboard/runs/active",
  completedChecklists: (): string => "dashboard/runs/completed",
  timelineGroup: (username: string): string => `dashboard/timeline/${username}`,
  timelineHistory: (username: string): string =>
    `dashboard/timeline/history/${username}`,

  schedules: (): string => "dashboard/schedules",
}
