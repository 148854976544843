import debug from "debug"
import { Context } from "overmind"
import { getLinkedListName } from "@bonsaichecklist/bonsai-utils"
import { itemsWithParent, reOrderItems } from "./utils"
import { ChecklistService } from "../effects"

const d = debug("ChainList/store/checklist/actions/indent")

export function indent(
  {
    actions: {
      checklist: { focusItemWithDelay },
    },
    state: {
      checklist: { itemMap, rootItems, current, checklistScheduleMap },
    },
  }: Context,
  data: {
    itemSlug: string
    previousItemSlug?: string
  }
): void {
  const { itemSlug } = data
  d("indent", itemSlug)

  if (!itemSlug) return

  const itemType = itemMap[itemSlug].itemType

  const item = ChecklistService.generateNewItem({
    body: "",
    order: rootItems.length + 1,
    itemType: itemType,
  })

  itemMap[item.slug] = item

  // Can't find the item to indent? Let's stop here
  if (!item) return

  // Find the previous item to indent this item under.
  const itemParent = item.parent
  const previousItem = itemMap[itemSlug]

  if (!previousItem) return

  // Do not allow indenting if the new parent is a linked list
  if (getLinkedListName(previousItem.body) || getLinkedListName(item.body))
    return

  const parentSlug = previousItem.slug

  // Find the sub-items for the previous item
  // so we properly add the item to the end of the list
  // of sub items.
  const subItems = itemsWithParent(Object.values(itemMap), parentSlug)

  d("subItems", subItems)

  // If there are subItems, put the item at the end, otherwise assume
  // it is the first item on the parent.
  item.order = subItems?.length ? subItems.length + 1 : 1
  item.parent = parentSlug

  // Re-order root item list to reset the "order" values by first
  // getting all parent items, sorting by existing order index
  // then reindexing sequentially.
  const updatedPeers = itemsWithParent(Object.values(itemMap), itemParent)
  reOrderItems(updatedPeers)

  if (itemType === "scheduled") {
    checklistScheduleMap[itemSlug].itemsToSchedule.push(item.slug)
  }

  focusItemWithDelay(item.slug)
  d("resorted items", itemMap)
}

export function unIndent(
  {
    actions: {
      checklist: { placeAfter, focusItemWithDelay, setSchedules },
    },
    state: {
      checklist: { itemMap, current, schedules },
    },
  }: Context,
  slug: string
): void {
  d("unindent", slug)

  const item = itemMap[slug]

  // Can't unindent non-existent item or an item with no parent.
  if (!item || !item.parent) return

  if (item?.itemType === "unScheduled") {
    const unScheduledItems = current?.unscheduleItems || []

    const parentIndex = unScheduledItems.findIndex(
      (itemSlug) => itemSlug === slug
    )

    if (!parentIndex) return

    const reIndexedItems = [
      ...unScheduledItems.slice(0, parentIndex + 1),
      slug,
      ...unScheduledItems.slice(parentIndex + 1),
    ]

    current.unscheduleItems = reIndexedItems
  }

  if (item?.itemType === "scheduled") {
    const parentIndex = schedules.findIndex((itemSlug) => itemSlug === slug)

    if (!parentIndex) return

    const reIndexedItems = [
      ...schedules.slice(0, parentIndex + 1),
      slug,
      ...schedules.slice(parentIndex + 1),
    ]

    setSchedules(reIndexedItems)
  }

  placeAfter({ currentItemSlug: item.slug, targetItemSlug: item.parent })

  focusItemWithDelay(slug)
}
