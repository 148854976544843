import { Context } from "overmind"
import { PRODUCT_TOUR_TYPES } from "~src/common/constants"

type ButtonLabels = {
  firstButtonlabel?: string
  nextButtonlabel?: string
  doneButtonlabel?: string
}

export function setTourButtonLables(
  { state: { productTour } }: Context,
  buttonsLables: ButtonLabels
): void {
  const { firstButtonlabel, nextButtonlabel, doneButtonlabel } = buttonsLables

  productTour.firstButtonlabel = firstButtonlabel
  productTour.nextButtonlabel = nextButtonlabel
  productTour.doneButtonlabel = doneButtonlabel
}

export function updateBtnLabel(
  { state: { productTour } }: Context,
  { btnLabel }: { btnLabel: string }
): void {
  productTour.doneButtonlabel = btnLabel
}

export function setShowProductTour(
  { state: { productTour } }: Context,
  { showTour }: { showTour: boolean }
): void {
  productTour.showProductTour = false
}

export function setFullProductTour(
  { state: { productTour } }: Context,
  { stepsData }: { stepsData: any }
): void {
  productTour.fullProductTour = stepsData
}

export function handleOnExitIntro(
  {
    state: { productTour },
    actions: {
      auth: { updateProductTour },
    },
  }: Context,
  { stepIndex, page, tour }: { stepIndex: number; page: string; tour?: string }
): void {
  const stepIndexesToExclude = [5]
  if (
    tour === PRODUCT_TOUR_TYPES.full &&
    stepIndex &&
    !stepIndexesToExclude.includes(stepIndex)
  ) {
    productTour.showFullProductTour = false
    productTour.startFromChecklistPage = false
    return
  }

  if (page === "editChecklist" && stepIndex <= 4 && stepIndex > -1) {
    productTour.showProductTour = false
    updateProductTour({ tour: PRODUCT_TOUR_TYPES.advance, skipped: true })
  }

  if ((page === "community" || page === "checklistDetail") && stepIndex > -1) {
    productTour.showProductTour = false
    updateProductTour({ tour: PRODUCT_TOUR_TYPES.basic, skipped: true })
  }

  if (page === "checklistRuns" && stepIndex < 5) {
    productTour.showProductTour = false
    updateProductTour({ tour: PRODUCT_TOUR_TYPES.basic, skipped: true })
  }

  if (page === "accountDropdown" && stepIndex) {
    updateProductTour({ tour: PRODUCT_TOUR_TYPES.advance, skipped: true })
  }
}

export async function handleCompleteIntro(
  {
    state: { productTour },
    actions: {
      auth: { updateProductTour },
    },
  }: Context,
  { tour }: { tour: string }
): Promise<boolean | void> {
  if (PRODUCT_TOUR_TYPES.basic === tour) {
    productTour.showProductTour = false
    productTour.startFromChecklistPage = false

    return updateProductTour({ tour: PRODUCT_TOUR_TYPES.basic })
  } else if (PRODUCT_TOUR_TYPES.advance === tour) {
    productTour.showProductTour = false

    return updateProductTour({ tour: PRODUCT_TOUR_TYPES.advance })
  } else if (PRODUCT_TOUR_TYPES.fullTour === tour) {
    productTour.showFullProductTour = false
    productTour.startFromChecklistPage = false
  }

  return
}

export function handleFullProductTour({
  state: { productTour },
}: Context): void {
  productTour.showFullProductTour = false
}

export function setStartFromChecklistPage(
  { state: { productTour } }: Context,
  { startManualTour }: { startManualTour: boolean }
): void {
  productTour.startFromChecklistPage = startManualTour
}

export function setMultiPageFlag(
  { state: { productTour } }: Context,
  { value }: { value: boolean }
): void {
  productTour.multiPage = value
}

export function handleProductTourExit({
  state: { productTour },
}: Context): void {
  productTour.startFromChecklistPage = false
  productTour.showFullProductTour = false
}
