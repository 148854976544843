import dayjs from "dayjs"
import { Context } from "overmind"

export function setStartedAt(
  {
    state: {
      checklist: { checklistScheduleMap },
    },
  }: Context,
  values: { slug: string; startDate: Date }
): void {
  const { slug, startDate } = values

  const data = checklistScheduleMap[slug]

  checklistScheduleMap[slug] = {
    ...data,
    startedAt: startDate,
  }
}

export function setFrequency(
  {
    state: {
      checklist: { checklistScheduleMap },
    },
  }: Context,
  values: {
    slug: string
    pattern: { title: string; value: string }
  }
): void {
  const { slug, pattern } = values

  const data = checklistScheduleMap[slug]

  checklistScheduleMap[slug] = {
    ...data,
    recurrencePattern: pattern.value,
    recurring: pattern.value !== "single-occurrence",
  }
}

export function setUntilDate(
  {
    state: {
      checklist: { checklistScheduleMap },
    },
  }: Context,
  values: {
    slug: string
    date: Date
  }
): void {
  const { slug, date } = values

  const data = checklistScheduleMap[slug]

  checklistScheduleMap[slug] = {
    ...data,
    until: new Date(dayjs(date).format(`YYYY-MM-DD`)),
  }
}

export function setSchedules(
  { state: { checklist } }: Context,
  value: string[]
): void {
  checklist.schedules = value
}

export function setCustomOptions(
  {
    state: {
      checklist: { checklistScheduleMap },
    },
  }: Context,
  values: {
    slug: string
    customOptions: CustomOptions
  }
): void {
  const { slug, customOptions } = values

  const data = checklistScheduleMap[slug]

  checklistScheduleMap[slug] = {
    ...data,
    customOptions,
  }
}

export function deleteCustomOption(
  {
    state: {
      checklist: { checklistScheduleMap },
    },
  }: Context,
  slug: string
): void {
  const data = checklistScheduleMap[slug]

  checklistScheduleMap[slug] = {
    ...data,
    customOptions: undefined,
  }
}

export function deleteUntilDate(
  {
    state: {
      checklist: { checklistScheduleMap },
    },
  }: Context,
  values: {
    slug: string
  }
): void {
  const { slug } = values

  const data = checklistScheduleMap[slug]

  checklistScheduleMap[slug] = {
    ...data,
    until: null,
  }
}
