import React from "react"
import { Modal } from "src/components"
import SignupModalContent from "../signup-modal-content/signup-modal-content"
import { MODAL_TYPES } from "~src/common/constants"
import { useShowAuthModals } from "~src/hooks/useShowAuthModals"

export function SignupModal(): JSX.Element {
  const { handleModal } = useShowAuthModals()
  const [emailSentTo, setEmailSentTo] = React.useState<string>()

  const onEmailSent = (value: string) => setEmailSentTo(value)

  return (
    <Modal
      classNames={{
        titleClassNames: "font-bold text-xl text-black ",
      }}
      config={{
        width: "md",
        showCloseButton: false,
      }}
      onClose={() => handleModal(MODAL_TYPES.signup)}
      title={!emailSentTo ? "Join ChainList  💙" : ""}
    >
      <SignupModalContent onEmailSent={onEmailSent} />
    </Modal>
  )
}
