import React from "react"
import loaderImage from "~public/icons/chainlist-loading.svg"

export function Loading(): JSX.Element {
  return (
    <div
      className="text-gray-600 flex flex-row items-center h-full text-center justify-center"
    >
      <div className="mx-auto loader">
        <img
          alt="loader"
          className="mr-4 animate-spin h-12 w-12"
          src={loaderImage}
        />
      </div>
    </div>
  )
}
