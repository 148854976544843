export function getCopyChecklistRouterHelper(
  copyChecklistHashMap: any,
  item: ChecklistItem
) {
  let slug = item?.copiedFrom?.slug
  let username = item?.copiedFrom?.owner?.username
  let title = item?.copiedFrom?.title

  if (
    (!slug || !username || !title) &&
    typeof item.copiedFrom === "string" &&
    copyChecklistHashMap &&
    Object.keys(copyChecklistHashMap).length
  ) {
    const copyChecklistInfo = copyChecklistHashMap[item.copiedFrom]
    if (copyChecklistInfo) {
      slug = copyChecklistInfo.slug
      username = copyChecklistInfo.owner.username
      title = copyChecklistInfo.title
    }
  }

  return { slug, username, title }
}