import { DraftHandleValue, EditorState } from "draft-js"
import React from "react"
import { useClickAway } from "react-use"
import { useStore } from "~src/store"
import { ActionField, Editor, EditorCommand } from "~src/components/Editor"

export interface ChecklistFieldProps {
  item: ChecklistItem
  parentItemSlug?: string
  actions?: ActionField[]
  expandParent?: () => void
  placeholder?: string,
  shouldBold?:boolean
}

export function ChecklistField({
  item,
  actions,
  expandParent,
  parentItemSlug,
  shouldBold,
  placeholder = "",
}: ChecklistFieldProps): JSX.Element {
  const {
    actions: {
      checklist: {
        setContent,
        moveUp,
        moveDown,
        focusPrev,
        focusNext,
        focusItem,
        unIndent,
        indent,
        save,
        insertItemAbove,
        insertItemBelow,
        setUnsavedChanges,
      },
    },
    state: {
      checklist: { saving },
    },
  } = useStore()

  const [focused, setFocused] = React.useState<boolean>(false)
  const ref = React.useRef<HTMLDivElement>(null)

  function handleFocus(): void {
    focusItem(item.slug)
    setFocused(true)
    //expandParent()
  }

  function handleBlur(): void {
    setFocused(false)
  }

  useClickAway(ref, (e) => {
    setFocused(false)
  })

  function handleKeyCommand(
    command: EditorCommand,
    editorState: EditorState
  ): DraftHandleValue {
    const slug = item.slug

    switch (command) {
      case "move-up":
        moveUp(slug)
        return "handled"
      case "move-down":
        moveDown(slug)
        return "handled"
      case "focus-prev":
        focusPrev(slug)
        return "handled"
      case "focus-next":
        focusNext(slug)
        return "handled"
      case "unindent":
        unIndent(slug)
        return "handled"
      case "indent":
        indent({ itemSlug: parentItemSlug })
        return "handled"
      case "save":
        save()
        return "handled"
      case "insert-above":
        insertItemAbove(slug)
        return "handled"
      case "insert-below":
        insertItemBelow(slug)
        return "handled"
      default:
        return "not-handled"
    }
  }

  if (!item) return <></>

  function handleSetContent(html: string): void {
    setUnsavedChanges(true)
    setContent({ html, slug: item.slug })
  }

  return (
    <div ref={ref}>
      <Editor
        actions={actions}
        className={`flex flex-row justify-between ${shouldBold && 'font-bold'}  ${
          item.isCopied && "item-copied-bg item-copied-bb"
        }`}
        cursorAtEnd={focused}
        defaultValue={item.body}
        disabled={saving}
        editorKey={item.slug}
        focused={focused}
        keyBindingFn={keyBindingFn}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyCommand={handleKeyCommand}
        placeholder={placeholder}
        setContent={handleSetContent}
        size="sm"
        toolbarProps={{
          variant: "title",
          toolbarItemSlug: item.slug,
        }}
      />
    </div>
  )
}

/**
 * Handle our custom actions by returning a command that
 * handleKeyCommand will then use to trigger the associated actions.
 *
 * Moved this out of the component so we don't have to re-create on
 * every render.
 */
function keyBindingFn(editorState: EditorState) {
  return (e: React.KeyboardEvent<HTMLInputElement>): EditorCommand | void => {
    const key = e.key.toLowerCase()

    const shift = e.shiftKey
    const cursorAtBeginning = editorState.getSelection().getStartOffset() === 0

    switch (key) {
      case "arrowup":
        return shift ? "move-up" : "focus-prev"
      case "arrowdown":
        return shift ? "move-down" : "focus-next"
      case "tab":
        return shift ? "unindent" : "indent"
      case "enter":
        if (e.metaKey) return "save"
        return shift || cursorAtBeginning ? "insert-above" : "insert-below"
      default:
        break
    }
  }
}
