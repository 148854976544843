import React from "react"

export type MarkdownLinkProps = React.HTMLProps<HTMLAnchorElement>

export function MarkdownLink({
  children,
  ...props
}: MarkdownLinkProps): JSX.Element {
  return (
    <a className="link" rel="noreferrer nofollow" target="_blank" {...props}>
      {children}
    </a>
  )
}
