import { Context } from "overmind"
import { ChecklistService } from "../effects"

export async function toggleEmailNotification(
  {
    state: {
      auth: { user },
    },
  }: Context,
  { payload, checklist }: { checklist: Checklist; payload: { enable: boolean } }
): Promise<void> {
  const bodyData = { ...payload, email: user.email }
  await ChecklistService.toggleEmailNotification(checklist.slug, bodyData)
}
