import React from "react"
import ReactMarkdown from "react-markdown"
import { MarkdownHelper } from "@bonsaichecklist/bonsai-utils"
import { MarkdownLink } from "~src/components"
import styles from "./ChecklistDescriptionDisplay.module.css"
import { EditorHelper } from "~src/common/lib"
import remarkGfm from "remark-gfm"
export type Page = "listing" | "detail"

export interface ChecklistDescriptionDisplayProps {
  description: string
  textOnly?: boolean
}

export function ChecklistDescriptionDisplay({
  description,
  textOnly,
}: ChecklistDescriptionDisplayProps): JSX.Element {
  const descriptionObj = new MarkdownHelper(description || "")
  const sourceHtml = EditorHelper.htmlToMarkdown(description)
  return (
    <div className={styles.body}>
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{ a: MarkdownLink }}
      >
        {textOnly ? descriptionObj.text : sourceHtml}
      </ReactMarkdown>
    </div>
  )
}
