import React from "react"
import { checklistTitle, getChecklistOwner } from "~src/common/lib"

import { Link, ChecklistVerifiedTick, Tooltip } from "~src/components"
import { routes } from "~src/routes"
import styles from "./grid-view.module.css"
import PinIcon from "~public/icons/pin.svg"
import { MetaData } from "../meta-data"
import { gridViewProps } from "./grid-view.types"

export function GridView({ data }: gridViewProps): JSX.Element {
  const {
    slug,
    title,
    coverUrl,
    description,
    verified,
    isFeatured,
    category,
  } = data

  const chklOwner = getChecklistOwner(data)

  return (
    <div className="border border-gray-200 rounded-t-md">
      <Link
        className="text-white text-lg font-bold hover:text-white"
        href={routes.checklists.show(slug, chklOwner)}
      >
        <div className={`w-full h-44 relative`}>
          {data.coverUrl && (
            <img
              alt="Template cover"
              className="h-full w-full object-cover rounded-t-md"
              src={coverUrl}
            />
          )}
          <div
            className={`w-full h-full absolute inset-0 rounded-b-md ${
              data.coverUrl ? styles.noCoverUrl : styles.noImageBackground
            }`}
          />
          <div className="absolute bottom-4 left-4 right-4 space-y-2">
            <div className="flex items-center">
              <h3 className={`max-w-lg line-clamp-2 `}>
                {checklistTitle(title)}
                {verified && <ChecklistVerifiedTick />}
              </h3>
            </div>
            <h4
              className={`text-sm text-white font-normal line-clamp-2 hover:line-clamp-none`}
            >
              {description}
            </h4>
          </div>

          {isFeatured && (
            <Tooltip tooltipTitle="Featured by ChainList">
              <img
                alt="Pic Icon"
                className="absolute top-0 rounded-tl-md"
                src={PinIcon}
              />
            </Tooltip>
          )}

          {category && (
            <div className=" bg-blackWithOpacity tracking-wide leading-3 p-2 absolute  right-2  top-3 rounded-full">
              <span className="text-xs font-bold text-white">
                {category?.name?.toUpperCase()}
              </span>
            </div>
          )}
        </div>
      </Link>
      <MetaData checklist={data} />
    </div>
  )
}
