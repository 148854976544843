import React from "react"
import dayjs from "dayjs"

export function ActivityDate({
  activity,
  className,
}: {
  activity: ActivityLog
  className?: string
}): JSX.Element {
  return (
    <div className={className}>
      <p className="text-gray-500 text-xs">
        {dayjs(activity.createdAt).format("M/DD/YY h:mmA")}
      </p>
    </div>
  )
}
