import axios from "axios"
import * as Cookies from "js-cookie"
import { AUTH_COOKIE_NAME } from "../constants"

const chainListAxios = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL || "http://localhost:3002",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
})

chainListAxios.interceptors.request.use(function (config) {
  const token = Cookies.get(AUTH_COOKIE_NAME)
  if (token) {
    config.headers.Authorization = token
  }

  return config
})

export { chainListAxios }
