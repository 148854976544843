import { Formik } from "formik"
import React from "react"
import * as yup from "yup"

export interface FormProps {
  children: (props: any) => React.ReactNode
  onChange?: (values: GenericObject) => void
  onSubmit: (values: any) => void | Promise<void>
  schema: yup.ObjectSchema<GenericObject>
  values: any
}

export function Form({
  children,
  onChange,
  onSubmit,
  schema,
  values,
}: FormProps): JSX.Element {
  return (
    <Formik
      enableReinitialize
      initialValues={values || {}}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={schema}
    >
      {(props: any): React.ReactNode => {
        onChange && onChange(props.values)
        return children(props)
      }}
    </Formik>
  )
}
