import { IConfig } from "overmind"
import {
  createActionsHook,
  createEffectsHook,
  createHook,
  createReactionHook,
  createStateHook,
} from "overmind-react"
import { namespaced } from "overmind/config"
import { onInitialize } from "./onInitialize"
import * as checklist from "./checklist"
import * as auth from "./auth"
import * as system from "./system"
import * as productTour from "./productTour"

const app = { onInitialize }
export const config = namespaced({ app, auth, checklist, system, productTour })

declare module "overmind" {
  // TODO: need to disable auto fixing because this needs to be
  // an interface not a `type` which the autofix changes this to
  /* eslint-disable  */
  interface Config extends IConfig<typeof config> {}
  /* eslint-enable  */
}

// Hooks
export const useStore = createHook<typeof config>()
export const useState = createStateHook<typeof config>()
export const useActions = createActionsHook<typeof config>()
export const useEffects = createEffectsHook<typeof config>()
export const useReaction = createReactionHook<typeof config>()
