import React from "react"
import { useStore } from "~src/store"
import { PrismicService } from "~src/services"
import { PRISMIC_PAGES, PRODUCT_TOUR_TYPES } from "~src/common/constants"
import { errorReporter } from "~src/common/lib"

export function useGetProductTourData(prismicPageKey: string): any {
  const {
    state: { auth },
  } = useStore()

  const [productTourData, setProductTourData] = React.useState([])
  const [buttonLabels, setButtonLabels] = React.useState({})

  React.useEffect(() => {
    if (auth.state === "authenticated") {
      const productTour = auth?.user?.productTours

      const basicTourValidity =
        !productTour?.basicTour?.viewed && !productTour?.basicTour?.skipped
      const advanceTourValidity =
        !productTour?.advanceTour?.viewed && !productTour?.advanceTour?.skipped

      const isRequireTourData =
        prismicPageKey === PRODUCT_TOUR_TYPES.basic
          ? basicTourValidity
          : advanceTourValidity

      ;(async function () {
        try {
          if (isRequireTourData) {
            const { steps, buttonLabels } = await PrismicService.getPageData(
              auth,
              PRISMIC_PAGES[prismicPageKey]
            )
            setProductTourData(steps)
            setButtonLabels(buttonLabels)
          }
        } catch (e) {
          errorReporter.notify(e)
          console.log(e, "error")
        }
      })()
    }
  }, [auth, auth.state, prismicPageKey])

  return {
    steps: productTourData,
    buttonLabels,
  }
}
