import * as qs from "qs"
import { CHECKLIST_RUNS } from "~src/common/apiRoutes"
import { APIError, chainListAxios } from "~src/common/lib"

export class RunService {
  public static async run(
    slug: string,
    runData?: {
      overrides?: { title: string; description: string }
      itemsToRun?: string[]
    },
    selectedItemSlug?: string,
    runType?: string
  ): Promise<ChecklistRun> {
    const itemSlug = selectedItemSlug ? { itemSlug: selectedItemSlug } : {}
    const data = {
      itemSlug,
      runData,
      ...(runType?.length && { runType }),
    }

    const response = await chainListAxios.post(
      CHECKLIST_RUNS.create(slug),
      data
    )

    return response.data
  }

  public static async findRun(
    chklSlug: string,
    runSlug: string,
    headers?: GenericObject
  ): Promise<{ run: ChecklistRun; checklist: Checklist }> {
    const response = await chainListAxios.get(
      encodeURI(CHECKLIST_RUNS.find(chklSlug, runSlug)),
      headers && { headers }
    )

    return {
      run: response.data?.run,
      checklist: response.data?.checklist,
    }
  }

  public static async allRunsByChecklistSlug(
    chklSlug: string,
    headers?: GenericObject,
    queryParams?: {
      page?: number
      limit?: number
      status?: RunStatus
      qrSlug?: string
    }
  ): Promise<{ runs: ChecklistRun[]; runsCount: number }> {
    const query = qs.stringify({
      page: queryParams?.page,
      limit: queryParams?.limit,
      status: queryParams?.status,
      qrSlug: queryParams?.qrSlug,
    })

    const response = await chainListAxios.get(
      CHECKLIST_RUNS.findAll(chklSlug, query),
      headers && { headers }
    )

    return response.data
  }

  // public static async setCheck(id: string, checked: boolean): Promise<void> {
  //   checked ? this.checkItem(id) : this.unCheckItem(id)
  // }

  public static async checkItem(
    chklSlug: string,
    runSlug: string,
    itemSlug: string
  ): Promise<ChecklistRun> {
    try {
      const checked = await chainListAxios.post(
        CHECKLIST_RUNS.checkItem(chklSlug, runSlug, itemSlug)
      )
      return checked.data
    } catch (error) {
      return null
    }
  }

  public static async unCheckItem(
    chklSlug: string,
    runSlug: string,
    itemSlug: string
  ): Promise<ChecklistRun> {
    try {
      const unchecked = await chainListAxios.post(
        CHECKLIST_RUNS.uncheckItem(chklSlug, runSlug, itemSlug)
      )
      return unchecked.data
    } catch (error) {
      return null
    }
  }

  public static async update(
    chklSlug: string,
    runSlug: string,
    updates: {
      title?: string
      description?: string
    }
  ): Promise<ChecklistRun> {
    const response = await chainListAxios.put(
      CHECKLIST_RUNS.update(chklSlug, runSlug),
      updates
    )

    return response.data
  }

  public static async deleteRun(
    ChklSlug: string,
    runSlug: string
  ): Promise<ChecklistRun> {
    try {
      const response = await chainListAxios.delete(
        CHECKLIST_RUNS.deleteRun(ChklSlug, runSlug)
      )

      return response.data
    } catch (error) {
      throw new APIError(error)
    }
  }
}
