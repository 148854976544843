// Modified from:
// https://github.com/arthurvr/split-array

/**
 * Split an array up into sub-arrays of a given length.
 */
export function splitArray<T>(input: T[], maxLength: number = 1): T[][] {
  if (!Array.isArray(input)) {
    throw new TypeError("Expected an array to split")
  }

  if (typeof maxLength !== "number") {
    throw new TypeError("Expected a number of groups to split the array in")
  }

  if (!input.length) return []

  const result: T[][] = []
  let part: T[] = []

  const overallLength = input.length
  const lastIndex = overallLength - 1

  let i = 0
  for (; i < overallLength; i++) {
    part.push(input[i])

    // Check if we reached the maximum amount of items in a partial
    // or just if we reached the last item
    if (part.length === maxLength || i === lastIndex) {
      result.push(part)
      part = []
    }
  }

  return result
}
