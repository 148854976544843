import React from "react"

interface IChecklistTemplateContext {
  checklist?: Checklist
  isOwner?: boolean
  rootItems?: ChecklistItem[]
  checklistItems?: ChecklistItem[]
  checkedItems?: GenericObject
  handleCheck?: (
    item: ChecklistRunItem,
    event?: any,
    hasItems?: boolean,
    setExpanded?: (value: boolean) => void,
    expanded?: boolean,
    itemMap?: ChecklistItemMap
  ) => void
}

const initialState = {
  checklist: {} as Checklist,
  isOwner: false as boolean,
  rootItems: [] as ChecklistItem[],
  checkedItems: [] as ChecklistItem[],
}

const checklistTemplateContext = React.createContext<IChecklistTemplateContext>(
  initialState
)
checklistTemplateContext.displayName = "ChecklistTemplateContext"

export { checklistTemplateContext, initialState }
