import React from "react"
import { splitArray } from "~src/common/lib"

export interface TileGridProps {
  Component: (props: GenericObject) => JSX.Element
  items: GenericObject[]
  columns?: number
  spacing?: number
}

export function TileGrid({
  Component,
  items,
  columns = 1,
  spacing = 4,
}: TileGridProps): JSX.Element {
  const rows = splitArray(items, columns)

  return (
    <div>
      {rows.map((items, rowKey) => (
        <div
          className="flex flex-1 flex-row items-stretch justify-space-between"
          key={rowKey}
        >
          {items.map((item, itemKey) => (
            <div
              className={`flex-1 pb-${rows.length - 1 > rowKey && spacing} pr-${
                items.length - 1 > itemKey && spacing
              }`}
              key={itemKey}
              style={{ width: `${100 / columns}%` }}
            >
              <Component {...item} />
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}
