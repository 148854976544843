import classnames from "classnames"
import NextLink from "next/link"
import React from "react"
import { Tooltip } from "~src/components"

export interface LinkProps extends React.HTMLProps<HTMLAnchorElement> {
  children?: React.ReactNode
  className?: string
  tooltip?: string
  href?: string
  disabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  variant?: "normal" | "success" | "muted" | "destructive"
  unstyled?: boolean
}

export function Link({
  children,
  className = "",
  variant = "normal",
  unstyled,
  tooltip,
  ...props
}: LinkProps): JSX.Element {
  const { disabled, href, onClick } = props

  const classes = unstyled
    ? className
    : classnames(
        "link",
        {
          "link--muted": !disabled && variant === "muted",
          "link--destructive": !disabled && variant === "destructive",
          "link--success": !disabled && variant === "success",
          "link--disabled": disabled,
        },
        className
      )

  if (onClick)
    return (
      <span className={classes} {...props} role="link">
        {children}
      </span>
    )

  if (!href)
    throw new Error("cannot create link without href or onClick argument")

  return tooltip ? (
    <Tooltip tooltipTitle={tooltip}>
      <span>
        <NextLink href={href}>
          <a className={classes} {...props}>
            {children}
          </a>
        </NextLink>
      </span>
    </Tooltip>
  ) : (
    <NextLink href={href}>
      <a className={classes} {...props}>
        {children}
      </a>
    </NextLink>
  )
}
