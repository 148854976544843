import debug from "debug"
import { Context } from "overmind"
import { flatOrderedList } from "./utils"

const d = debug("ChainList/store/checklist/actions/focus")

export function focusTitle(
  { state: { checklist } }: Context,
  value: void
): void {
  d("focus name")
  checklist.focusedTitle = true
  checklist.focusedDescription = false
}

export function focusDescription(
  { state: { checklist } }: Context,
  value: void
): void {
  d("focus name")

  checklist.focusedDescription = true
  checklist.focusedTitle = false
}

export function focusItem(
  { state: { checklist } }: Context,
  slug?: string
): void {
  d("focus item", slug)
  checklist.focusedTitle = false
  checklist.focusedDescription = false
  const e = document.getElementById(`editor-${slug}`)
  if (e) e.click()
}

export function focusItemWithDelay(
  {
    actions: {
      checklist: { focusItem },
    },
  }: Context,
  slug?: string
): void {
  setTimeout(() => focusItem(slug), 20)
}

export function focusPrev(
  {
    actions: {
      checklist: { focusItem },
    },
    state: { checklist },
  }: Context,
  curItemSlug: string
): void {
  d("focus previous", curItemSlug)

  const item = checklist.itemMap[curItemSlug]

  if (!item) return

  const flat = flatOrderedList(Object.values(checklist.itemMap))
  const prevIndex = flat.indexOf(item) - 1

  // If pressing up arrow, focus name
  if (prevIndex < 0) {
    checklist.focusedDescription = true
    delete checklist.activeItemSlug
    return
  }

  const prev = flat[prevIndex]
  if (prev) focusItem(prev.slug)
}

export function focusNext(
  {
    actions: {
      checklist: { focusItem },
    },
    state: {
      checklist: { itemMap, activeItemSlug },
    },
  }: Context,
  curItemSlug: string
): void {
  d("focus next", curItemSlug)

  const item = itemMap[curItemSlug]

  if (!item) return

  const flat = flatOrderedList(Object.values(itemMap))
  const nextIndex = flat.indexOf(item) + 1

  // Don't try to focus an item above the first item
  if (nextIndex >= flat.length) return

  const next = flat[nextIndex]

  if (next) focusItem(next.slug)
}
