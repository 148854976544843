import React from "react"
import { createPopper } from "@popperjs/core"
import { DateHelper } from "@bonsaichecklist/bonsai-utils"
import { Link } from "~src/components"
import { routes } from "~src/routes"
import { getChecklistOwner } from "~src/common/lib"

interface PopoverTypes {
  showInfoPoppper: boolean
  item: ChecklistItem
  parentRef: any
}

export function ItemInfoPopover({
  showInfoPoppper,
  item,
  parentRef,
}: PopoverTypes): JSX.Element {
  const [popoverShow, setPopoverShow] = React.useState(showInfoPoppper)
  const popoverRef = React.useRef()

  React.useEffect(() => {
    if (showInfoPoppper && parentRef.current) {
      openPopover()
    }

    if (!showInfoPoppper) closePopover()
  }, [showInfoPoppper])

  const openPopover = () => {
    createPopper(parentRef.current, popoverRef.current, {
      placement: "top-start",
    })
    setPopoverShow(true)
  }

  const closePopover = () => {
    setPopoverShow(false)
  }

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full text-center">
          <div
            className={
              (popoverShow ? "block " : "hidden ") +
              "bg-blue-100 mr-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg"
            }
            ref={popoverRef}
          >
            <div className="p-3">
              <>
                This item was copied from{" "}
                <Link
                  href={routes.checklists.show(
                    item?.copiedFrom?.slug,
                    getChecklistOwner(item?.copiedFrom)
                  )}
                >
                  {item?.copiedFrom?.title}
                </Link>{" "}
                at {DateHelper.localeDate(item?.copiedAt)}.
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
