import { ChecklistItemsHelper } from "@bonsaichecklist/bonsai-utils"

interface Item {
  parent?: string
  order: number
  slug: string
}

interface ItemMap<T> {
  [Key: string]: T
}

export function sortChecklistItems<T extends Item>(itemMap: ItemMap<T>): T[] {
  let sortedItems: T[] = []
  const rootItems = ChecklistItemsHelper.getRootItems(itemMap)

  rootItems.forEach((rootItem) => {
    const arr = [
      rootItem,
      ...ChecklistItemsHelper.getChildItems(itemMap, rootItem.slug),
    ]
    sortedItems = [...sortedItems, ...arr]
  })

  return sortedItems
}
