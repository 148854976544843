import { AnimatePresence, motion } from "framer-motion"
import { useRouter } from "next/router"
import React from "react"
import * as yup from "yup"
import { Alert, Button, Form, Input, Link, Icon } from "~src/components"
import { getQueryValue, ScheduleHelper } from "~src/common/lib"
import { useStore } from "~src/store"
import { useShowAuthModals } from "~src/hooks/useShowAuthModals"
import { MODAL_TYPES, SUBBED_AS_LOCAL_KEY } from "~src/common/constants"
import { ChecklistService } from "~src/services"
import Envelope from "~public/envelope.svg"
import Markverified from "~public/mark-verified.svg"

const schema = yup.object().shape({
  email: yup.string().email().required(),
})

type SubscriberFormProps = {
  onClose: () => void
  checklist: Checklist
  scheduleInfo?: ScheduleInfo | Record<string, any>
  showConfirmModal: boolean
}

enum CustomErrorType {
  subbed = "subbed",
  signedup = "signedup",
}

export function SubscriberForm({
  onClose,
  checklist,
  scheduleInfo,
  showConfirmModal,
}: SubscriberFormProps): JSX.Element {
  const router = useRouter()
  const { handleModal } = useShowAuthModals()
  const emailInputRef = React.useRef({ email: "" })

  const [token, setToken] = React.useState<string>("")
  const [isAlreadySubscribed, setIsAlreadySubscribed] = React.useState<boolean>(
    false
  )
  const [errorMsg, setErrorMsg] = React.useState<string>()
  const [alreadySubbed, setAlreadySubbed] = React.useState(false)
  const [alreadySignedUp, setAlreadySignedUp] = React.useState(false)

  const {
    actions: {
      auth: { resetError },
    },
    state: {
      auth: { error },
    },
  } = useStore()

  const redirect = getQueryValue(router.query?.redirect)

  React.useEffect(() => {
    localStorage.setItem("dosub", checklist?.slug)
  }, [])

  React.useEffect(() => {
    return (): void => resetError()
  }, [resetError])

  function handleOnFocus(): void {
    resetError()
  }

  const alreadySubbedMessage = {
    prefix:
      "This email address has already been used to subscribe to this checklist.",
    mid: " Sign up",
    postfix: " for a ChainList account now to save your subscriptions.",
  }
  const alreadySignedUpMessage = {
    prefix:
      "There is already a ChainList account registered with this email address. Do you want to ",
    postfix: "Login",
  }

  const clearCustomErrorView = () => {
    setAlreadySignedUp(false)
    setAlreadySubbed(false)
    setErrorMsg("")
  }

  const showErrorView = (errorType?: CustomErrorType) => {
    if (errorType === CustomErrorType.subbed) {
      setAlreadySignedUp(false)
      setAlreadySubbed(true)
    } else if (errorType === CustomErrorType.signedup) {
      setAlreadySignedUp(true)
      setAlreadySubbed(false)
    }
    setErrorMsg("")
  }

  const handleSubscribe = React.useCallback(
    async ({ email }: { email: string }): Promise<void> => {
      clearCustomErrorView()
      try {
        emailInputRef.current.email = email
        const slug = checklist?.slug
        const response = await ChecklistService.subscribe(slug, email)
        if (response?.statusCode === 208) {
          setToken("already")
        } else if (response?.statusCode === 207) {
          showErrorView(CustomErrorType.subbed)
        } else if (response?.token) {
          setToken(response?.token)
          setIsAlreadySubscribed(
            response?.isSubscribedToAnotherChecklist || false
          )
          writeSubbedListLocally(slug, email)
        }
      } catch (e) {
        if (e.statusCode === 400) {
          showErrorView(CustomErrorType.signedup)
        } else {
          clearCustomErrorView()
          setErrorMsg(e.mssage)
        }
      }
    },
    [redirect, router]
  )

  const handleReSubscribe = async () => {
    try {
      const email = emailInputRef.current.email
      const slug = checklist?.slug
      const response = await ChecklistService.resubscribe(slug, email)
      if (response?.token) {
        setToken(token)
      } else if (response?.statusCode === 208) {
        showErrorView(CustomErrorType.subbed)
      }
    } catch (e) {
      console.log(e?.message)
    }
  }

  const writeSubbedListLocally = (slug: string, email: string): boolean => {
    try {
      const alreadySubbed = localStorage.getItem(SUBBED_AS_LOCAL_KEY)
      const subbedList = JSON.parse(alreadySubbed)
      const hasItAlready = subbedList?.find(
        (item: { slug: string; email: string }) => item.slug === slug
      )

      if (hasItAlready) {
        return true
        /**TODO user is already subbed to this list **/
      }
      if (subbedList) {
        subbedList.push({ slug, email, isSubscribed: false })
        localStorage.setItem(SUBBED_AS_LOCAL_KEY, JSON.stringify(subbedList))
      } else {
        const subbedAsGuest = []
        subbedAsGuest.push({ slug, email, isSubscribed: false })
        localStorage.setItem(SUBBED_AS_LOCAL_KEY, JSON.stringify(subbedAsGuest))
      }
      return true
    } catch (e) {
      console.log(e)
      return false
    }
  }

  function Divider() {
    return <div className="border border-gray4 flex-1" />
  }

  if (showConfirmModal) {
    return (
      <div className="flex flex-col items-center p-5 md:px-0 ">
        <img alt="Mark-Verified" src={Markverified} />
        <p className="text-xl font-bold text-black mt-5 ">Email Confirmed</p>
        <p className="text-sm font-normal text-gray-900 mt-2 text-center">
          You have successfully confirmed your email address, and are now
          subscribed to <strong>{checklist?.title}</strong>.
        </p>
        <Button className="text-sm font-normal mt-9" onClick={onClose}>
          Back to Checklist
        </Button>
      </div>
    )
  }

  return (
    <>
      {token ? (
        <div className="flex flex-col items-center px-5 md:px-0">
          <img alt="envelope" src={Envelope} />
          <p className="text-xl font-bold text-black mt-5 ">
            You’re only one step away
          </p>
          <p className="text-sm font-normal text-gray-900 mt-2 text-center">
            We have just sent a confirmation email to{" "}
            <strong>{emailInputRef.current.email}</strong>. Please click on the
            URL in the email to confirm your email address.
          </p>
          <p className="text-13px font-normal text-gray-900 mt-5 text-center">
            Didn’t get an email?{" "}
            <Link
              className="text-13px"
              onClick={() => {
                handleReSubscribe()
              }}
            >
              Click here
            </Link>{" "}
            to resend the confirmation email.
          </p>
          {isAlreadySubscribed && (
            <div className="bg-blue-100 p-2 text-center">
              We noticed that you have subscribed to another checklist in the
              past. Do you want to subscribe to checklist immediately, without
              having to confirm your email address every time?
              <div>
                <Link
                  href="#"
                  onClick={() => {
                    onClose()
                    handleModal(MODAL_TYPES.signup, { key: "subscribe" })
                  }}
                >
                  Sign up
                </Link>{" "}
                for a ChainList account now.
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={"px-5 md:px-4 my-6"}>
          <AnimatePresence>
            {error && (
              <motion.div
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                transition={{ duration: 0.4 }}
              >
                <Alert className="my-6" keepOpen>
                  {error.toString()}
                </Alert>
              </motion.div>
            )}
          </AnimatePresence>
          <>
            <h2
              className={
                "font-bold text-xl text-black text-center leading-6 mb-2"
              }
            >
              Subscribe to this checklist
            </h2>
            <p className={" text-gray1 text-center mb-10"}>
              You’ll receive scheduled reminders in your email
            </p>

            {/* <div
              className={
                "bg-blue-100 py-2 px-2  mt-5 flex flex-row rounded-b items-center "
              }
            >
              <Icon className={"text-blue-500 mr-2"} name="info-circle" />
              <p className={"text-black text-xs"}>
                This schedule comes with approximately{" "}
                {ScheduleHelper.getFormatedNotifications(scheduleInfo?.notifications)}
              </p>
            </div> */}
            <Form onSubmit={handleSubscribe} schema={schema} values={{}}>
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
              }: any): JSX.Element => (
                <form onSubmit={handleSubmit}>
                  <div className="my-3">
                    <Input
                      autoCapitalize="none"
                      autoComplete="off"
                      autoCorrect="off"
                      id="email"
                      className={
                        (errorMsg || errors?.email) && "border-red-500 border-2"
                      }
                      onChange={handleChange}
                      onFocus={handleOnFocus}
                      placeholder="Email address"
                      type="email"
                      value={values.email}
                    />
                  </div>
                  <div className="my-3">
                    {alreadySignedUp && (
                      <div className="mb-2 text-black">
                        {alreadySignedUpMessage.prefix}
                        <Link
                          onClick={() => {
                            onClose()
                            handleModal(MODAL_TYPES.login, { key: "subscribe" })
                          }}
                        >
                          {alreadySignedUpMessage.postfix}
                        </Link>
                      </div>
                    )}

                    {alreadySubbed && (
                      <div className="mb-2 text-black">
                        {alreadySubbedMessage.prefix}
                        <Link
                          onClick={() => {
                            onClose()
                            handleModal(MODAL_TYPES.signup)
                          }}
                        >
                          {alreadySubbedMessage.mid}
                        </Link>
                        {alreadySubbedMessage.postfix}
                      </div>
                    )}

                    {errors?.email && (
                      <div className="mb-2 text-red-600">{errors.email}</div>
                    )}
                    {errorMsg && (
                      <div className="mb-2 text-red-600">{errorMsg}</div>
                    )}
                  </div>
                  <div className="my-6 flex">
                    <Button
                      aria-disabled={true}
                      className="flex-1"
                      disabled={!values.email}
                      type={"submit"}
                      variant="primary"
                    >
                      Subscribe to this checklist
                    </Button>
                  </div>

                  <div className="flex items-center">
                    <Divider />
                    <span className=" text-xs font-normal text-gray2 p-3">
                      OR
                    </span>
                    <Divider />
                  </div>
                  <p className=" text-base font-normal text-black my-5">
                    Already have an account?{" "}
                    <Link
                      onClick={() => {
                        onClose()
                        handleModal(MODAL_TYPES.login, { key: "subscribe" })
                      }}
                    >
                      Login here
                    </Link>
                  </p>
                </form>
              )}
            </Form>
          </>
        </div>
      )}
    </>
  )
}
