import { AxiosResponse } from "axios"
import * as Cookies from "js-cookie"
import * as cookie from "cookie"
import { AUTH } from "~src/common/apiRoutes"
import { chainListAxios, APIError } from "~src/common/lib"
import {
  AUTH_COOKIE_NAME,
  AUTH_COOKIE_DOMAIN,
  AUTH_COOKIE_EXPIRATION,
} from "~src/common/constants"
import { IncomingMessage } from "http"

export interface AuthResponse {
  user: User
  jwtToken: string
  requestUsername?: boolean
  searchAPIToken: string
  productTours?: ProductTours
  runActivityExists?: boolean
}

export class AuthService {
  public static async register({
    username,
    email,
    password,
  }: {
    username: string
    email: string
    password: string
  }): Promise<boolean> {
    try {
      const response = await chainListAxios.post(AUTH.signup, {
        username,
        email,
        password,
      })
      return response.data
    } catch (error) {
      throw new APIError(error)
    }
  }

  /**
   * Login the user from their locally stored credentials, which exist
   * in localstorage. If no credentials exist or they are invalid,
   * the user will not be logged in.
   */
  public static async getCurrentUser(
    headers?: GenericObject
  ): Promise<AuthResponse> {
    try {
      const response = await chainListAxios.get(AUTH.me, headers && { headers })
      this.setAuthCookie(response)
      return AuthService.formatResponse(response)
    } catch (error) {
      console.log(error)
      return null
    }
  }

  public static async loginWithEmailPassword(
    email: string,
    password: string
  ): Promise<AuthResponse> {
    try {
      const response = await chainListAxios.post(AUTH.login, {
        email,
        password,
      })
      const data = AuthService.formatResponse(response)
      this.setAuthCookie(response)

      return data
    } catch (error) {
      throw new APIError(error)
    }
  }

  public static async loginWithGoogle(
    token: string,
    type?: string
  ): Promise<AuthResponse> {
    const response = await chainListAxios.post(AUTH.googleLogin, {
      token,
      type,
    })
    this.setAuthCookie(response)
    return response.data
  }

  public static async loginWithApple(code: string): Promise<AuthResponse> {
    const response = await chainListAxios.post(AUTH.appleLogin, {
      code,
    })
    this.setAuthCookie(response)
    return response.data
  }

  public static async forgotPassword(email: string): Promise<void> {
    try {
      await chainListAxios.post(AUTH.forgotPassword, { email })
    } catch (error) {
      throw new APIError(error)
    }
  }

  public static async verifyResetPasswordToken(
    token: string
  ): Promise<boolean> {
    const response = await chainListAxios.get(
      AUTH.verifyResetPasswordToken(token)
    )

    const data = Boolean(response.data)

    return data
  }

  public static async updatePassword(
    token: string,
    password: string
  ): Promise<boolean> {
    const response = await chainListAxios.put(AUTH.resetPassword, {
      token,
      password,
    })
    return response.data
  }

  public static async logout(): Promise<AuthResponse> {
    this.destroyAuthCookie()
    const response = await chainListAxios.post(AUTH.logout)
    return response.data
  }

  public static async isUsernameAvailable(username: string): Promise<boolean> {
    const response = await chainListAxios.get(
      AUTH.isUsernameAvailable(username)
    )
    const data = Boolean(response.data.available)
    return data
  }

  public static async setUsername(newUsername: string): Promise<AuthResponse> {
    const response = await chainListAxios.put(AUTH.setUsername, {
      newUsername,
    })
    this.setAuthCookie(response)
    return AuthService.formatResponse(response)
  }

  public static async getAlgoliaPublicToken(): Promise<{
    searchAPIToken: string
  }> {
    try {
      const response = await chainListAxios.get(AUTH.publicSearchToken)
      return response?.data
    } catch (error) {
      return error
    }
  }

  public static async getCommunitySearchToken(): Promise<{
    communitySearchAPIToken: string
  }> {
    try {
      const response = await chainListAxios.get(AUTH.communitySearchToken)
      return response?.data
    } catch (error) {
      return error
    }
  }

  private static formatResponse(response: AxiosResponse) {
    const data = {
      user: response.data?.user,
      searchAPIToken: response.data?.searchAPIToken,
      jwtToken: response.data?.jwtToken,
      ...(response.data?.runActivityExists && {
        runActivityExists: response.data?.runActivityExists,
      }),
    }
    return data
  }

  public static setAuthCookie(response: AxiosResponse): void {
    this.removeOldCookie()
    const { domain, path } = this.getCookieDomain()
    if (response.data?.jwtToken) {
      Cookies.set(AUTH_COOKIE_NAME, response.data?.jwtToken, {
        expires: AUTH_COOKIE_EXPIRATION,
        ...(process.env.NODE_ENV !== "development" && { domain }),
        path,
      })
    }
  }

  private static getCookieDomain(): GenericObject {
    return { domain: AUTH_COOKIE_DOMAIN, path: "/" }
  }
  private static removeOldCookie() {
    if (Cookies.get("bonsai_auth")) {
      Cookies.remove("bonsai_auth", { path: "/" })
    }
  }

  private static destroyAuthCookie(): void {
    this.removeOldCookie()
    const { domain, path } = this.getCookieDomain()
    Cookies.remove(AUTH_COOKIE_NAME, {
      ...(process.env.NODE_ENV !== "development" && { domain }),
      path,
    })
  }

  public static getAuthToken(req: IncomingMessage): string | null {
    const cookies = cookie.parse(req?.headers?.cookie || "")

    if (cookies && cookies[AUTH_COOKIE_NAME]) {
      return cookies[AUTH_COOKIE_NAME]
    }
    return null
  }

  public static getAuthTokenClient(): string | null {
    const cookies = Cookies.get(AUTH_COOKIE_NAME || "")
    if (cookies) return cookies
    return null
  }

  public static async verifyEmail(token: string): Promise<AuthResponse> {
    try {
      const response = await chainListAxios.post(AUTH.verifyEmailToken(token))
      const data = AuthService.formatResponse(response)

      this.setAuthCookie(response)

      return data
    } catch (error) {
      throw new APIError(error)
    }
  }

  public static async resendEmailToken(email: string): Promise<boolean> {
    try {
      const response = await chainListAxios.post(
        AUTH.resendVerifyEmailToken(),
        { email }
      )
      return response.data
    } catch (error) {
      throw new APIError(error)
    }
  }
}
