import React from "react"
import Tippy from "@tippyjs/react"
import { TIPPY_CLASSES } from "~src/common/constants"
import classNames from "classnames"

interface TooltipProps {
  children?: React.ReactElement
  tooltipTitle: string
  classes?: string
  touch?: boolean
}
const tippyClasses = classNames(TIPPY_CLASSES)

export function Tooltip({
  children,
  tooltipTitle,
  classes,
  touch = false,
}: TooltipProps): JSX.Element {
  return (
    <Tippy
      className={classes ? classes : tippyClasses}
      content={tooltipTitle}
      delay={300}
      touch={touch}
    >
      {children}
    </Tippy>
  )
}
