import React from "react"
import { AnimatePresence, motion } from "framer-motion"
import cs from "classnames"
import { ExpansionCaret } from "~src/components/ExpansionCaret"
import { useMobileDetect } from "~src/components/useMobileDetect"

export default function ViewItemArrow({
  label = "load past items",
  arrowType = "prev",
  onClick,
}: {
  arrowType?: "prev" | "next"
  label?: string
  onClick?: () => void
}): JSX.Element {
  const [expanded] = React.useState<boolean>(false)
  const [hover, setHover] = React.useState<boolean>(false)
  const handleMouseEnter = () => {
    setHover(true)
  }
  const isMobile = useMobileDetect()
  const handleMouseLeave = () => {
    setHover(false)
  }

  return (
    <motion.span
      animate={{ width: hover && arrowType === "prev" ? "7.5rem" : "2rem" }}
      className={cs(
        "cursor-pointer",
        "bg-white",
        "border",

        "min-w-max",
        "text-gray-600",
        " h-8",
        "min-h-max",
        "px-2",
        "rounded-full",
        " flex",
        "justify-center",
        "items-center",
        "text-primaryBlue",
        "gap-2",
        "w-8",
        { " hover:bg-secondaryGray": arrowType === "next" },

        "absolute",
        "transition-all",
        "duration-500",
        "z-10"
      )}
      exit={{ width: "2rem" }}
      initial={false}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ExpansionCaret
        className={cs(
          "w-full",
          "h-full",
          { "rotate-270": arrowType === "prev" },
          { "rotate-0 ": arrowType === "next" },

          "relative",
          "text-primaryBlue",
          "font-bold"
        )}
        expanded={expanded}
        unScheduledItems
      />
      <AnimatePresence>
        {(hover || arrowType === "next" || isMobile) && (
          <motion.p
            animate={{ visibility: "visible", opacity: 1 }}
            className={cs("font-bold", "text-primaryBlue", "text-xs px-1")}
            exit={{ visibility: "hidden", opacity: 0 }}
            initial={{ visibility: "hidden", opacity: 0 }}
            transition={{ delay: 0.2 }}
          >
            {label}
          </motion.p>
        )}
      </AnimatePresence>
    </motion.span>
  )
}
