import debug from "debug"
import { Context } from "overmind"
import * as Cookies from "js-cookie"
import { APIError, errorReporter } from "~src/common/lib"
import { AuthResponse, AuthService } from "./effects"
import { UserService } from "~src/services"
import { AUTH_COOKIE_NAME, MODAL_TYPES } from "~src/common/constants"

const d = debug("ChainList/store/auth/actions")

export async function register(
  {
    actions: {
      auth: { resetAuth },
    },
    state: { auth },
  }: Context,
  {
    username,
    email,
    password,
  }: { username: string; email: string; password: string }
): Promise<boolean | void> {
  resetAuth()
  try {
    const resp = await AuthService.register({ username, email, password })
    return resp
  } catch (error) {
    auth.error = error
  }
}

export async function loginWithEmailPassword(
  {
    actions: {
      auth: { setAuth },
    },
    state: { auth },
  }: Context,
  { email, password }: { email: string; password: string }
): Promise<AuthResponse> {
  try {
    const resp = await AuthService.loginWithEmailPassword(email, password)
    setAuth(resp)
    return resp
  } catch (error) {
    auth.error = error
    throw new APIError(error)
  }
}

export async function loginWithGoogle(
  {
    actions: {
      auth: { resetAuth, setAuth },
    },
    state: { auth },
  }: Context,
  { token, type }: { token: string; type?: string }
): Promise<AuthResponse | null> {
  resetAuth()
  try {
    const resp = await AuthService.loginWithGoogle(token, type)
    setAuth(resp)
    return resp
  } catch (error) {
    auth.error = error
    return null
  }
}

export async function loginWithApple(
  {
    actions: {
      auth: { resetAuth, setAuth },
    },
    state: { auth },
  }: Context,
  { code }: { code: string }
): Promise<AuthResponse | null> {
  resetAuth()
  try {
    const resp = await AuthService.loginWithApple(code)
    setAuth(resp)
    return resp
  } catch (error) {
    auth.error = error
    return null
  }
}

export async function loginFromLocalCredentials(
  {
    actions: {
      auth: { setAuth },
    },
    state: { auth },
  }: Context,
  value: void
): Promise<void> {
  // TODO: make this logic more generic so it can be used by other
  // auth methods like login?
  try {
    delete auth.error
    const token = Cookies.get(AUTH_COOKIE_NAME)
    if (!token) {
      auth.state = "unauthenticated"
      return
    }

    auth.state = "authenticating"
    const creds = await AuthService.getCurrentUser()
    if (!creds || !creds?.user) {
      console.error("no credentials returned from local credential login")
      auth.state = "unauthenticated"
      return
    }
    setAuth(creds)
    auth.state = "authenticated"
  } catch (error) {
    d("login from local credentials failed with:", error)
    auth.state = "unauthenticated"
    auth.error = new Error(error)
  }
}

export async function forgotPassword(
  { state: { auth } }: Context,
  email: string
): Promise<void> {
  delete auth.error
  await AuthService.forgotPassword(email)
}

export async function verifyResetPasswordToken(
  { state: { auth } }: Context,
  token: string
): Promise<boolean> {
  return AuthService.verifyResetPasswordToken(token)
}

export async function updatePassword(
  { state: { auth } }: Context,
  { token, password }: { token: string; password: string }
): Promise<boolean> {
  delete auth.error
  const resp = await AuthService.updatePassword(token, password)
  return resp
}

export async function logout(
  { state: { auth } }: Context,
  value: void
): Promise<void> {
  const res = await AuthService.logout()
  auth.state = "unauthenticated"
  delete auth.user
  delete auth.error
  delete auth.requestUsername
  auth.searchAPIToken = res?.searchAPIToken
  localStorage.clear()
}

export async function resetAuth(
  { state: { auth } }: Context,
  value: void
): Promise<void> {
  auth.state = "authenticating"
  delete auth.user
  delete auth.admin
  delete auth.error
  delete auth.searchAPIToken
  // await AuthService.setAuthToken(token)
}

export function resetError({ state: { auth } }: Context, value: void): void {
  delete auth.error
}

export function setAuth(
  { state: { auth } }: Context,
  { user, requestUsername, searchAPIToken, runActivityExists }: AuthResponse
): void {
  delete auth.error
  auth.user = { ...user }
  auth.searchAPIToken = searchAPIToken
  auth.state = user ? "authenticated" : "unauthenticated"
  auth.requestUsername = requestUsername
  delete auth.tempAuthResponse
  if (user)
    errorReporter.addContext({ user_id: user.id, user_email: user.email })
}

export function setIsRunZeroPrompted(
  { state: { auth } }: Context,
  status: boolean
): void {
  if (status) auth.user.productTours.isRunZeroPrompted = status
}

export async function setSearchToken({
  state: { auth },
}: Context): Promise<void> {
  const { searchAPIToken } = await AuthService.getAlgoliaPublicToken()
  auth.searchAPIToken = searchAPIToken
}

export async function setCommunitySearchToken({
  state: { auth },
}: Context): Promise<void> {
  const {
    communitySearchAPIToken,
  } = await AuthService.getCommunitySearchToken()
  auth.communitySearchAPIToken = communitySearchAPIToken
}

export async function setUsername(
  {
    actions: {
      auth: { setAuth },
    },
  }: Context,
  username: string
): Promise<void> {
  const resp = await AuthService.setUsername(username)
  setAuth(resp)
}

export async function updateProductTour(
  { state: { auth } }: Context,
  productTourData: { tour: string; skipped?: boolean }
): Promise<boolean> {
  const { tour, skipped } = productTourData
  const { status } = await UserService.updateProductTour(tour, skipped)

  if (status) {
    auth.user.productTours = {
      ...auth.user.productTours,
      [tour]: skipped
        ? { skipped: true, skippedOn: new Date(), viewed: false }
        : { viewed: true, viewedAt: new Date(), skipped: false },
    }
  }

  return status
}

export function updateUserLastActivitySeenAt(
  { state: { auth } }: Context,
  date: Date
): void {
  auth.user.lastActivitySeenAt = date
}

export function setAuthModalVisibility(
  { state: { auth } }: Context,
  {
    showModal,
    modalType,
    action,
  }: {
    showModal: boolean
    modalType: string
    action?: { key: string; description?: string }
  }
): void {
  auth.action = action
  if (modalType === MODAL_TYPES.login) {
    auth.showSignupModal = false
    auth.showLoginModal = showModal
    return
  }
  auth.showLoginModal = false
  auth.showSignupModal = showModal
}

export function setTempAuthResponse(
  { state: { auth } }: Context,
  response: AuthResponse
): void {
  auth.tempAuthResponse = response
}

export function clearModalAction({ state: { auth } }: Context): void {
  auth.action = null
}

export function updateInfoDialog(
  { state: { auth } }: Context,
  data: InfoDialog
): void {
  auth.user.infoDialogs = {
    ...auth.user.infoDialogs,
    ...data,
  }
}
