import React, { useEffect } from "react"
import { FEEDBACK_MAX_LENGTH } from "~src/common/constants"
import { Alert, Button, Modal } from "~src/components"
import { useFeedback } from "~src/hooks"
import { useStore } from "~src/store"

export interface FeedbackModalProps {
  onClose: () => void
}

export function FeedbackModal({ onClose }: FeedbackModalProps): JSX.Element {
  const {
    actions: {
      system: { showToastAlert },
    },
    state: {
      auth: {
        user: { username },
      },
    },
  } = useStore()
  const [feedback, setFeedback] = React.useState<string>("")
  const { error, isLoading, isSuccess, mutate ,} = useFeedback({
    onSuccess: () => {
      setFeedback("")
      showToastAlert({
        autoHide: true,
        text:
          "Thank you. Your feedback was sent to our Slack channel. We try to respond to all feedback",
        type: "success",
      })
    },
  })

  useEffect(()=>{
    const timer=setTimeout(() => {
      if(isSuccess){
        onClose()
      }
    }, 2000);
    return ()=>{
      clearTimeout(timer)
    }
  },[isSuccess])
  function handleSendFeedback(e?: React.FormEvent<HTMLFormElement>): void {
    e?.preventDefault()
    if (!feedback) {
      return
    }

    mutate({ text: feedback, username })
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLTextAreaElement>): void {
    const key = e.key.toLowerCase()
    if (e.metaKey && key === "enter") handleSendFeedback()
  }

  return (
    <Modal
      config={{
        showCloseButton: false,
      }}
      onClose={onClose}
      title="Send us feedback"
    >
      <p className="mb-6 text-gray-600 text-sm">
        This posts directly into ChainList&apos;s team Slack.
      </p>
      {isSuccess && (
        <Alert className="my-4 font-semibold" type="success">
          Your feedback was sent successfully. We try to respond quickly
        </Alert>
      )}
      {error && <Alert>{error.toString()}</Alert>}
      <form onSubmit={handleSendFeedback}>
        <div className="my-2">
          <textarea
            autoFocus
            className="input w-full"
            disabled={isLoading}
            maxLength={FEEDBACK_MAX_LENGTH}
            onChange={(e) => setFeedback(e.currentTarget.value)}
            onKeyDown={handleKeyDown}
            rows={5}
            value={feedback}
          ></textarea>
        </div>
        <p className="mt-8">
          <Button disabled={isLoading || !feedback} fullWidth type="submit">
            Send feedback
          </Button>
        </p>
      </form>
    </Modal>
  )
}
