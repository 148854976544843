import React from "react"

import pointingArrow from "~public/icons/pointing-arrow.svg"
import warning from "~public/icons/warning.svg"

export function UnsavedChecklistWarning(): JSX.Element {
  return (
    <>
      <img
        alt="Pointing Arrow"
        className="hidden lg:inline-flex ml-36 xl:ml-48 mb-3"
        src={pointingArrow}
      />

      <div className="border border-secondaryRedLight rounded-md mb-5 pl-6 py-4 pr-1">
        <div className="flex items-center mb-6">
          <img alt="warning" src={warning} />
          <h5 className="text-base font-bold text-gray1 ml-2">
            Your checklist is unsaved
          </h5>
        </div>

        <span className="text-xs font-normal text-gray1">
          Subscribe to this checklist to save your progress and receive
          reminders in your mailbox.
        </span>
      </div>
    </>
  )
}
