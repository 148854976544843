import check from "~src/../public/icons/check.svg"
import styles from "./ChecklistVerifiedTick.module.css"
import React from "react"

export function ChecklistVerifiedTick() {
  return (
    <div
      className={`h-5 w-5 rounded-full ml-2  inline-flex justify-center items-center flex-shrink-0 ${styles.verified}`}
      title="Verified"
    >
      <img src={check} />
    </div>
  )
}
