import React from "react"
import { useClickAway } from "react-use"

import { Button } from "~src/components"
import { FileMenu } from "./FileMenu"
import { UploadMenu } from "./UploadMenu"
import { ChecklistService } from "~src/services"
import { Unsplash } from "./UnSplashMenu"

type Tab = {
  id: number
  key: number
  text: string
}

const tabs: Tab[] = [
  {
    id: 1,
    key: 0,
    text: "Upload",
  },
  {
    id: 2,
    key: 1,
    text: "Link",
  },
  {
    id: 3,
    key: 2,
    text: "Unsplash",
  },
]

interface TabProps {
  tab: { id: number; key: number; text: string }
}

interface TemplateCoverProps {
  chklSlug: string
  coverUrl: string | undefined
  isOwner: boolean
}

export function TemplateCover({
  chklSlug,
  coverUrl,
  isOwner,
}: TemplateCoverProps): JSX.Element {
  const [activeTab, setActiveTab] = React.useState<number>(0)
  const [expanded, setExpanded] = React.useState<boolean>(false)
  const [imageUrl, setImageUrl] = React.useState<string>(coverUrl)
  const [error, setError] = React.useState<string>("")
  React.useEffect(() => {
    setImageUrl(coverUrl)
  }, [coverUrl])

  const divRef = React.useRef<HTMLDivElement>(null)

  useClickAway(divRef, () => {
    if (expanded) {
      setError("")
      setExpanded(false)
    }
  })

  const handleActiveTab = (tabNumber: number) => {
    setActiveTab(tabNumber)
  }

  const handleSetExpanded = () => {
    setExpanded((prev) => !prev)
  }

  const getImageDimension = (
    file?: File,
    url?: string
  ): Promise<{ height: number; width: number }> => {
    return new Promise((resolve, reject) => {
      try {
        const image = new Image()
        let height = 0
        let width = 0

        const objectUrl = file ? window.URL.createObjectURL(file) : undefined

        image.onload = function () {
          height = image.height
          width = image.width

          window.URL.revokeObjectURL(objectUrl)

          resolve({ height, width })
        }
        image.src = url || objectUrl
      } catch (error) {
        reject("There was problem getting dimensions")
      }
    })
  }

  const handleFileSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setError("")
    const file = Array.from(e.target.files)

    const dimensions = await getImageDimension(file[0])

    if (dimensions.height < 400 || dimensions.width < 768) {
      return setError("The minimum image dimenions must be 768x400")
    }

    try {
      const resposne = await ChecklistService.uploadCover(chklSlug, file[0])
      setImageUrl(resposne)
      setExpanded(false)
    } catch (error) {
      setError(error)
    }
  }

  const handleFileUrl = async (url: string) => {
    setError("")

    try {
      new URL(url)
    } catch (error) {
      return setError("The link is not valid")
    }

    const isValidImageType =
      url.includes("jpg") || url.includes("png") || url.includes("jpeg")

    if (!isValidImageType) return setError("Link format is not supported")

    const dimensions = await getImageDimension(null, url)

    if (dimensions.height < 400 || dimensions.width < 768) {
      return setError("The minimum image dimenions must be 768x400")
    }

    try {
      const response = await ChecklistService.setCoverFileUrl(chklSlug, url)
      if (response) setImageUrl(url)
      setExpanded(false)
    } catch (error) {
      setError(error)
    }

    setImageUrl(url)
  }

  const handleRemoveCover = async () => {
    try {
      const response = await ChecklistService.removeCoverUrl(chklSlug)
      if (response) setImageUrl(null)
      setExpanded(false)
    } catch (error) {
      setError(error)
    }
  }

  const tabActiveClasses = "border-b-2 border-primaryBlue py-1 cursor-default"
  const tabInactiveClasses = "cursor-pointer text-gray2"

  function Tab({ tab }: TabProps): JSX.Element {
    return (
      <div
        className={` ${
          activeTab === tab.key ? tabActiveClasses : tabInactiveClasses
        } `}
        onClick={() => {
          setError("")
          handleActiveTab(tab.key)
        }}
      >
        <span className="text-sm font-semibold pb-4">{tab.text}</span>
      </div>
    )
  }

  return (
    <div className="relative group">
      {imageUrl ? (
        <div className="h-32 w-full rounded-t-md bg-gray4">
          {imageUrl && (
            <img
              alt="Template cover"
              className="h-32 w-full object-cover rounded-t-md"
              src={imageUrl || ""}
            />
          )}
        </div>
      ) : (
        !imageUrl &&
        isOwner && (
          <div className="h-32 w-full rounded-t-md bg-gray4">
            {imageUrl && (
              <img
                alt="Template cover"
                className="h-32 w-full object-cover rounded-t-md"
                src={imageUrl || ""}
              />
            )}
          </div>
        )
      )}
      <div ref={divRef}>
        {!imageUrl && isOwner && (
          <Button
            className={`absolute right-4 top-4 transition-all duration-300 ease-in ${
              !expanded ? "md:opacity-0 md:group-hover:opacity-100" : ""
            }`}
            onClick={handleSetExpanded}
            variant="secondary"
          >
            <span className="font-normal text-xs">Add template cover</span>
          </Button>
        )}

        {imageUrl && isOwner && (
          <div
            className={`flex items-center absolute right-4 top-4 transition-all 
          duration-300 ease-in  ${
            !expanded ? "md:opacity-0 md:group-hover:opacity-100" : ""
          } gap-4 `}
          >
            <Button onClick={handleSetExpanded} variant="secondary">
              <span className="font-normal text-xs">Change template cover</span>
            </Button>

            <Button onClick={handleRemoveCover} variant="secondary">
              <span className="font-normal text-xs">Remove cover</span>
            </Button>
          </div>
        )}

        {expanded && (
          <div
            className={`bg-white  max-w-md w-72 rounded absolute top-12 z-10 -left-20 ${
              !imageUrl
                ? "md:-right-72"
                : "md:-right-templateCoverDropDownRight "
            } shadow-md`}
          >
            <div className="h-10 bg-gray5 border-b border-gray4 flex items-center gap-9 px-7">
              {tabs.map((tab) => (
                <Tab key={tab.id} tab={tab} />
              ))}
            </div>
            {activeTab === 0 && (
              <UploadMenu
                error={error}
                handleFileSelected={handleFileSelected}
              />
            )}
            {activeTab === 1 && (
              <FileMenu error={error} handleFileUrl={handleFileUrl} />
            )}
            {activeTab === 2 && (
              <Unsplash
                error={error}
                handleFileUrl={handleFileUrl}
                setError={setError}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}
