import React from "react"
import { Link, Loading, Logo, PageHeading } from "~src/components"
import { useGoogleLogin } from "~src/hooks"

import { routes } from "~src/routes"
import { useState } from "~src/store"
import { BaseLayout, BaseLayoutProps } from "."

export type AuthLayoutProps = {
  pageHeading?: string
  children?: React.ReactNode
}

export function AuthLayout({
  children,
  ...props
}: AuthLayoutProps): JSX.Element {
  const { loading } = useGoogleLogin()

  const {
    auth: { showSignupModal, showLoginModal },
  } = useState()

  return (
    <BaseLayout>
      {loading ? (
        <Loading />
      ) : (
        <div className="my-8 mx-auto max-w-sm px-6 md:px-0">
          <div className="mb-10">
            {!showSignupModal && !showLoginModal && (
              <Link href={routes.generic.community()} unstyled>
                <Logo className="mx-auto" width={120} />
              </Link>
            )}
          </div>
          {props.pageHeading && <PageHeading>{props.pageHeading}</PageHeading>}
          {children}
        </div>
      )}
    </BaseLayout>
  )
}
