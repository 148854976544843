import React from "react"
import ReactMarkdown from "react-markdown"
import { EditorHelper } from "~src/common/lib"
import styles from "./ChecklistItemBody.module.css"
import { MarkdownLink } from "~src/components"
import { formatLinkedListName } from "~src/common/lib"
import remarkGfm from "remark-gfm"

export interface ChecklistItemBodyProps {
  item: ChecklistItem | ChecklistRunItem
  linkedListMap?: LinkedListMap
}

export function ChecklistItemBody({
  item,
  linkedListMap,
}: ChecklistItemBodyProps): JSX.Element {
  const linkedListBracketsRemoved = formatLinkedListName(
    item as ChecklistItem,
    linkedListMap
  )
  const source = EditorHelper.htmlToMarkdown(linkedListBracketsRemoved)

  return (
    <label
      className={styles.body}
      // htmlFor={`checklist-item-checkbox-${item.slug}`}
    >
      <ReactMarkdown
        className="break-word w-full"
        components={{ a: MarkdownLink }}
        remarkPlugins={[remarkGfm]}
      >
        {source}
      </ReactMarkdown>
    </label>
  )
}
