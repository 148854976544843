import Router from "next/router"
import React, { useEffect } from "react"

export function useWarnIfUnsavedChanges(
  unsavedChanges: boolean,
  setShouldFormReset?: React.Dispatch<React.SetStateAction<boolean>>
): void {
  const message =
    "You have some unsaved changes. Are you sure you want to leave?"

  useEffect(() => {
    const routeChangeStart = (url: string): void => {
      if (Router.asPath !== url && unsavedChanges && !window.confirm(message)) {
        Router.events.emit("routeChangeError")
        setShouldFormReset(false)
        throw new Error("Abort route change. Please ignore this error.")
      } else {
        setShouldFormReset(true)
      }
    }

    const beforeunload = (e: BeforeUnloadEvent): string | void => {
      if (unsavedChanges) {
        e.preventDefault()
        e.returnValue = message
        return message
      }
    }

    window.addEventListener("beforeunload", beforeunload)
    Router.events?.on("routeChangeStart", routeChangeStart)

    return (): void => {
      window.removeEventListener("beforeunload", beforeunload)
      Router.events.off("routeChangeStart", routeChangeStart)
    }
  }, [unsavedChanges, setShouldFormReset])
}
