import { MAX_INDENT_DEPTH } from "~src/common/constants"
import { ChecklistItemsHelper } from "@bonsaichecklist/bonsai-utils"
import { IncomingMessage } from "http"
import { AUTH_COOKIE_NAME } from "~src/common/constants"
import * as cookie from "cookie"
import { AuthService } from "~src/store/auth/effects"

/**
 * Return all items at a given level and sort them
 * by their order.
 */
export function itemsWithParent(
  items: ChecklistItem[],
  parent?: string
): ChecklistItem[] {
  if (!items?.length) return []
  return items
    .filter((i) => (parent ? i.parent === parent : !i.parent))
    .sort(ChecklistItemsHelper.sortChecklistItemsByOrder)
}

/**
 * Simple wrapper util to get all items without parents, aka "root" items.
 */
export function rootItems(items: ChecklistItem[]): ChecklistItem[] {
  return itemsWithParent(items)
}

/**
 * Simple util to return a boolean whether there are any items in an
 * ChecklistItemMap
 */
export function hasItems(itemMap: ChecklistItemMap): boolean {
  return Boolean(Object.keys(itemMap).length)
}

/**
 * Return a flattened but ordered list of checklist items. Items are
 * orderd linearly by their "order" then sub-items are placed after their
 * parent so you get a flat array of ordered items like:
 *
 * 1. Item A
 * 2.   - Subitem A.1
 * 3.   - Subitem A.2
 * 4. Item B
 * 5. Item C
 *
 * This currently only handles one layer deep.
 */
export function flatOrderedList(items: ChecklistItem[]): ChecklistItem[] {
  const roots = rootItems(items)

  const flatList: ChecklistItem[] = []

  function addLevel(itemsAtLevel: ChecklistItem[]): void {
    itemsAtLevel.forEach((item) => {
      // Add each root item first
      flatList.push(item)

      // Then add each on of their sub-items, in order
      const subItems = itemsWithParent(items, item.slug)
      addLevel(subItems)
    })
  }

  // Recursively add items into flat list
  addLevel(roots)

  return flatList
}

/**
 * Simple util that returns whether or not a checklist item
 * is the first item in a checklist.
 */
export function isFirstItem(item: ChecklistItem): boolean {
  return !item.parent && item.order < 2
}

/**
 * Based on the array order of a list of ChecklistItems,
 * reset the "order" field to be sequential.
 */
export function reOrderItems(items: ChecklistItem[]): ChecklistItem[] {
  items.forEach((i, index) => {
    i.order = index + 1
  })
  return items
}

/**
 * Replace the "parent" value for all items given.
 */
export function reParentItems(
  items: ChecklistItem[],
  parent: string
): ChecklistItem[] {
  return items.map((i) => {
    i.parent = parent
    return i
  })
}

/**
 * Recursively flatten all items before they reach the max depth of
 * indentation.
 */
export function flattenIndentationAtMaxDepth(itemMap: ChecklistItemMap): void {
  const items = Object.values(itemMap)
  const roots = rootItems(items)

  function recurse(level: number, itemsAtLevel: ChecklistItem[]): void {
    itemsAtLevel.forEach((item) => {
      if (level >= MAX_INDENT_DEPTH - 1) {
        const children = collectChildrenOfParent(items, item.slug)
        reParentItems(children, item.slug)
        reOrderItems(children)
        return
      }

      const children = itemsWithParent(items, item.slug)
      recurse(level + 1, children)
    })
  }

  recurse(1, roots)
}

export function collectChildrenOfParent(
  items: ChecklistItem[] = [],
  parent: string
): ChecklistItem[] {
  let allItems: ChecklistItem[] = []

  function recurse(padre: string): void {
    const children = itemsWithParent(items, padre)
    if (!children?.length) return
    allItems = [...allItems, ...children]
    children.forEach((i) => recurse(i.slug))
  }

  recurse(parent)

  return allItems
}

/**
 * Insert a ChecklistItem at a given index in a list of ChecklistItems.
 */
export function insertAtIndex(
  items: ChecklistItem[],
  item: ChecklistItem,
  index: number
): ChecklistItem[] {
  items.splice(index, 0, item)
  return items
}

/**
 * Remove a ChecklistItem from a list of ChecklistItems by it's slug.
 */
export function excludeItem(
  items: ChecklistItem[],
  slug: string
): ChecklistItem[] {
  return items.filter((i) => i.slug !== slug)
}

/**
 * Find all items that match a given order at a certain
 * level, as defined by the "parent" (or lack of parent,
 * if root items)
 */
export function findItemByOrder(
  items: ChecklistItem[],
  order: number
): ChecklistItem {
  return items.find((i) => i.order === order)
}

/*
 * helper function that extract user from headers
 */
export async function getLoggedUserFromHeaders(req: IncomingMessage):Promise<any> {
  try {
    const cookies = cookie.parse(req?.headers?.cookie || "")
    if (cookies && cookies[AUTH_COOKIE_NAME]) {
      const headers = {
        Authorization: AuthService.getAuthToken(req),
      }
      const auth = await AuthService.getCurrentUser(headers)
      return auth
    }
  } catch (error) {
    // console.error(error)
    return null
  }
}

/**
 * Function that takes itemMap and returns a sort function to sort scheduled and unscheduled item slugs
 * based on their order from itemMap
 * @param itemMap
 * @returns
 */
export function sortItemSlugsByOrder(
  itemMap: ChecklistItemMap
): (a: string, b: string) => number {
  return (a: string, b: string) =>
    itemMap[a].order >= itemMap[b].order ? 1 : -1
}
