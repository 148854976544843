import debug from "debug"
import { Context } from "overmind"
import { ChecklistItemsHelper } from "@bonsaichecklist/bonsai-utils"
import { ChecklistHelper } from "~src/common/lib"
const d = debug("ChainList/store/checklist/actions")

export function setChecklist({ state }: Context, checklist: Checklist): void {

  state.checklist.current = checklist
  state.checklist.itemMap = ChecklistItemsHelper.makeItemMap<ChecklistItem>(
    checklist?.items || []
  )

  const schedulesArray: string[] = []
  const scheduleMap = checklist?.schedules?.reduce((all, item) => {
    all[item.itemSlug] = item
    schedulesArray.push(item.itemSlug)
    return all
  }, {} as ChecklistScheduleMap)

  state.checklist.checklistScheduleMap = scheduleMap
  state.checklist.schedules = checklist?.items
    ?.filter((item) => schedulesArray.includes(item?.slug))
    ?.sort(ChecklistHelper.sortSchedulesByNextRun.bind(this, scheduleMap))
    .map((i) => i.slug)

  state.checklist.current.unscheduleItems = checklist?.unscheduleItems
}

export function setContent(
  { state: { checklist } }: Context,
  { html, slug }: { html: string; slug: string }
): void {
  const item = checklist.itemMap[slug]
  if (!item) return

  item.body = html
  d("saving body:", item.body)
}

export function setUnsavedChanges(
  { state: { checklist } }: Context,
  status: boolean
): void {
  checklist.unsavedChanges = status
}

export function setCopyChecklistHashMap(
  { state: { checklist } }: Context,
  hashMap: CopyChecklistHashMap
): void {
  checklist.copyChecklistHashMap = hashMap
}
