import { isServerRendered } from "~src/common/lib"

export class RuntimeHelper {
  /**
   * Detect if the current browser supports touch interactions.
   * Borrowed from: https://stackoverflow.com/a/13470899/529829
   */
  public static supportsTouch(): boolean {
    if (isServerRendered()) return false
    return Boolean("ontouchstart" in window || navigator?.maxTouchPoints)
  }
}
