import { initial } from "lodash"

export const STATUS_CODES = {
  NOT_FOUND: 404,
  EXCEPTION: 500,
}

export const UNTITLED_CHECKLIST_NAME = "Untitled Checklist"

export const AUTH_COOKIE_NAME = "auth_token"
export const AUTH_COOKIE_EXPIRATION = 90
export const AUTH_COOKIE_DOMAIN = ".chainlist.com"
export const INITIAL_RUN_KEY = "initialRunData"
export const RUN_TYPE_INITIAL = "initial"
export const SUBBED_AS_LOCAL_KEY = "subbedAsGuest"
export const SINGUP_TOOLTIP = "signupTooltip"

// export const PASSWOR_REGEX =
//   "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"

export const PASSWOR_REGEX = /^[a-z0-9-+_!@#$%^&*.,?~]*$/i

// Maxium number of layers that a checklist can be indented
// starting with the first (root) layer at 1:
// - 1 (root)
//   - 2
//     - 3
//       - 4
export const MAX_INDENT_DEPTH = 2

export const MOVED_PERMANENTLY_REDIRECT = 301
export const MOVED_TEMPORARILY_REDIRECT = 302

export const ALLOWED_CHECKLIST_ITEM_TAGS = ["strong", "em", "code", "a", "del"]
export const ALLOWED_EDITOR_STYLES = [
  "BOLD",
  "ITALIC",
  "CODE",
  "LINK",
  "STRIKETHROUGH",
]

export const ATTACHMENT_TILE_SIZE = "12rem"

export const USERNAME_VALIDATION_REGEX = /^[a-zA-Z0-9]+(?:[ _-][a-zA-Z0-9]+)*$/
export const MIN_USERNAME_LENGTH = 4

export const CHECKLIST_DESCRIPTION_MAX_LENGTH = 140

export const CHECKLIST_VISIBILITY_PUBLIC = "public"
export const CHECKLIST_VISIBILITY_PRIVATE = "private"
export const CHECKLIST_VISIBILITY_UNLISTED = "unlisted"

export const INITIATE_SEARCH_ON_CHARS = 2

export const FEEDBACK_MAX_LENGTH = 1000

// Algolia search index
export const ALGOLIA_INDEX = process.env.ALGOLIA_INDEX || "dev_ChainList" // "dev_Bonsai"
export const ALGOLIA_APP_ID = process.env.ALGOLIA_APP_ID || "UKKE5SS6VW"
export const ALGOLIA_SEARCH_ONLY_API_KEY =
  process.env.ALGOLIA_SEARCH_ONLY_API_KEY || "3e42d62be1d58e7429038f45e414b8ab"

export const PAGINATION_LIMIT = 10
export const COMMUNITY_PAGINATION_LIMIT = 12

export const SCHEDULES_PAGINATION_LIMIT = 5
export const RUNS_PAGINATION_LIMIT = 5
export const ACTIVITY_LOGS_PAGINATION_LIMIT = 5

export const UPCOMING_RUN_DATE_FORMAT = "M/DD/YY h:mmA"

export const NEW_CHECKLIST_SLUG = "new_checklist"

export const TOUR_CHECKLIST_SLUG = "qp9x9eEUpu"
export const TOUR_PROD_CHECKLIST_SLUG = "chainlist-basics-LiHJkdKWnu"
export const TOUR_PROD_CHECKLIST_OWNER = "rstephens"
export const PRODUCT_TOUR_TYPES: GenericObject = {
  basic: "basicTour",
  advance: "advanceTour",
  full: "fullTour",
}

export const PRODUCT_TOURS: GenericObject = {
  basicTour: {
    viewed: false,
    skipped: false,
  },
  advanceTour: {
    viewed: false,
    skipped: false,
  },
}

export const PRISMIC_PAGES: GenericObject = {
  basicTour: {
    prismicDocument: "basictour",
    page: "basicTour",
  },
  advanceTour: {
    prismicDocument: "advancetour",
    page: "advanceTour",
  },
}

export const INTRO_OPTIONS = {
  hideNext: false,
  hidePrev: true,
  exitOnEsc: false,
  exitOnOverlayClick: false,
}

export const ACTIVITY_TYPES: {
  CREATE_SCHEDULE: ActivityType
  EDIT_SCHEDULE: ActivityType
  DELETE_SCHEDULE: ActivityType
  CREATE_CHECKLIST: ActivityType
  EDIT_CHECKLIST: ActivityType
  FAVOURITE_CHECKLIST: ActivityType
  CREATE_MANUAL_RUN: ActivityType
  CREATE_SCHEDULED_RUN: ActivityType
  RUN_STARTED: ActivityType
  RUN_COMPLETED: ActivityType
  FOLLOW_CHECKLIST: ActivityType
  UN_FOLLOW_CHECKLIST: ActivityType
  FOLLOWED_FROM: ActivityType
  UN_FOLLOWED_FROM: ActivityType
  CREATE_COPY: ActivityType
  COPIED_FROM: ActivityType
  TRASH_CHECKLIST: ActivityType
  RESTORE_CHECKLIST: ActivityType
  COMMENT_ADDED: ActivityType
  SET_CHECKLIST_VERIFIED: ActivityType
  COPIED_CHECKLIST_UPDATED: ActivityType
} = {
  CREATE_SCHEDULE: "create-schedule",
  EDIT_SCHEDULE: "edit-schedule",
  DELETE_SCHEDULE: "delete-schedule",
  CREATE_CHECKLIST: "create-checklist",
  EDIT_CHECKLIST: "edit-checklist",
  FAVOURITE_CHECKLIST: "favourite-checklist",
  CREATE_MANUAL_RUN: "create-manual-run",
  CREATE_SCHEDULED_RUN: "create-scheduled-run",
  RUN_STARTED: "run-started",
  RUN_COMPLETED: "run-completed",
  FOLLOW_CHECKLIST: "follow-checklist",
  UN_FOLLOW_CHECKLIST: "un-follow-checklist",
  FOLLOWED_FROM: "followed-from",
  UN_FOLLOWED_FROM: "un-followed-from",
  CREATE_COPY: "create-copy",
  COPIED_FROM: "copied-from",
  TRASH_CHECKLIST: "trash-checklist",
  RESTORE_CHECKLIST: "restore-checklist",
  COMMENT_ADDED: "comment-added",
  SET_CHECKLIST_VERIFIED: "set-checklist-verified",
  COPIED_CHECKLIST_UPDATED: "copied-checklist-updated",
}

export const FEED_SUMMARY: {
  [key in ActivityType]?: string
} = {
  "create-schedule": "A new schedule was created for",
  "edit-schedule": "A Schedule was modified",
  "delete-schedule": "A schedule was deleted",
  "create-checklist": "was created",
  "edit-checklist": "was edited",
  "favourite-checklist": "was starred",
  "create-manual-run": "Checklist was created",
  "create-scheduled-run": "A new Scheduled Checklist was created",
  "run-started": "Checklist was started",
  "run-completed": "Checklist was completed",
  "follow-checklist": "was subscribed",
  "un-follow-checklist": "was unsubscribed",
  "followed-from": "subscribed from",
  "un-followed-from": "unsubscribed from",
  "create-copy": "was copied",
  "copied-from": "copied",
  "trash-checklist": "was trashed",
  "restore-checklist": "was restored",
  "set-checklist-verified": "was verified",
  "copied-checklist-updated": "was updated for",
}

export const NOTIFICATION_TYPES = { ...ACTIVITY_TYPES }

// this temp solution until we implement ENG-487
export const TEST_USERS = {
  "rstephens1@gmail.com": true,
  "mf.tahir@outlook.com": true,
  "jeroen.meines@gmail.com": true,
  "ranazeshan30@gmail.com": true,
}

export const APP_URL =
  process.env.NEXT_PUBLIC_APP_URL || "https://www.chainlist.com"

export const TIPPY_CLASSES = "text-xs font-semibold text-white rounded"

export const MODAL_TYPES = {
  login: "login",
  signup: "signup",
}

export const AUTH_MODAL_ACTIONS = {
  copy:
    "Please login to your ChainList account to create a copy of this template.",
  subscribe:
    "Please login to your ChainList account to subscribe to this template.",
  star: "Please login to your ChainList account to star this template.",
}

export const IS_DIGIT = new RegExp(/^\d+$/)

export const ACTIVE_CHECKLIST_EMPTY_STATE = {
  heading: "No active checklists",
  description:
    "Click the ‘New Checklist’ button on any template to create a checklist that you can use.",
}

export const SCHEDULE_CHECKLIST_EMPTY_STATE = {
  heading: "No scheduled checklists",
  description:
    "Click the ‘New Scheduled Checklist’ button on any template to schedule a checklist that will be emailed to you in the future.",
}

export const COMPLETED_CHECKLIST_EMPTY_STATE = {
  heading: "No completed checklists",
}
