import { Context } from "overmind"
import {
  getLinkedListName,
  ChecklistItemsHelper,
} from "@bonsaichecklist/bonsai-utils"
import dayjs from "dayjs"

export function moveDroppedItem(
  {
    state: {
      checklist: { itemMap, current, checklistScheduleMap, schedules },
    },
    actions: {
      checklist: { focusItemWithDelay, setSchedules },
      system: { showToastAlert },
    },
  }: Context,
  {
    item,
    dropAreaItem,
    dropIndex,
    dropPosition,
    parent,
    dropAreaType,
    schedule,
  }: {
    item: ChecklistItem
    dropAreaItem: ChecklistItem
    dropIndex: number
    dropPosition: "after" | "before"
    parent?: ChecklistItem
    dropAreaType?: string
    schedule?: boolean
  }
): void {
  // get all existing children items
  const itemsOfParent = ChecklistItemsHelper.getSubItemsOfParent(
    itemMap,
    parent?.slug
  ).filter((i) => {
    if (!schedule) return i.slug !== item.slug && i.itemType === "unScheduled"

    return i.slug !== item.slug && i.itemType === "scheduled"
  })

  const subItems = ChecklistItemsHelper.getSubItemsOfParent(itemMap, item?.slug)

  const movingToNewParent = parent?.slug && item.parent !== parent?.slug
  const removingParent = item.parent && !parent?.slug
  const sameParent = item.parent && item.parent === dropAreaItem?.parent

  if (schedule) {
    itemMap[item.slug].itemType = "scheduled"
  } else {
    itemMap[item.slug].itemType = "unScheduled"
  }

  if (subItems?.length) {
    subItems.forEach((subItem) => {
      itemMap[subItem?.slug].itemType = schedule ? "scheduled" : "unScheduled"
      if (movingToNewParent) {
        itemMap[subItem.slug].parent = parent?.slug
      }
    })
  }

  // Do not allow if parent item is linked list
  if (movingToNewParent && getLinkedListName(item.body)) {
    showToastAlert({
      type: "warning",
      text: "Embedded checklist can not be used as a sublist.",
      autoHide: true,
    })
    return
  }

  // Do not allow if parent item is linked list
  if (movingToNewParent && getLinkedListName(parent?.body)) return

  // if item is being dropped to a new parent
  if (movingToNewParent) {
    // update item's parent.
    // TODO: find better than direct mutation
    itemMap[item.slug].parent = parent?.slug

    if (parent) {
      itemMap[item.slug].itemType = parent.itemType

      setSchedules(schedules?.filter((slug) => slug !== item?.slug))

      current.unscheduleItems = current?.unscheduleItems?.filter(
        (slug) => slug !== item.slug
      )

      const itemsToSchedule =
        checklistScheduleMap[item?.parent || item?.slug]?.itemsToSchedule

      if (itemsToSchedule?.length) {
        checklistScheduleMap[
          item?.parent || item?.slug
        ].itemsToSchedule = itemsToSchedule.filter(
          (slug) => slug !== item?.slug
        )
      }

      if (!schedule) {
        if (!item.parent && checklistScheduleMap[item?.slug]) {
          checklistScheduleMap[item?.slug].delete = true
        }
      }

      if (schedule) {
        checklistScheduleMap[parent?.slug || item?.slug].itemsToSchedule.push(
          item?.slug
        )

        if (subItems?.length) {
          subItems.forEach((subItem) => {
            checklistScheduleMap[
              parent?.slug || item?.slug
            ].itemsToSchedule.push(subItem.slug)
          })
        }

        if (checklistScheduleMap[item?.slug]) {
          checklistScheduleMap[item?.slug].delete = true
        }
      }
    }
  }

  if (removingParent) {
    itemMap[item.slug].parent = undefined

    const itemsToSchedule =
      checklistScheduleMap[item?.parent]?.itemsToSchedule || []

    if (itemsToSchedule.length) {
      checklistScheduleMap[
        item?.parent
      ].itemsToSchedule = itemsToSchedule.filter((slug) => slug !== item?.slug)
    }
  }

  // Here we create the index of to insert item at.
  //let insertAtIndex = dropAreaItem.order || itemsOfParent.length;
  let insertAtIndex = dropIndex

  // if item being dragged has less position then dropped Area Item position
  // decrease the dropIndex by 1
  if (item.order < dropAreaItem?.order && sameParent) {
    insertAtIndex -= 1
  }

  // If item being dragged is being dropped at the start of the list, rest index to -1
  if (dropPosition === "before") {
    insertAtIndex -= 1
  }

  // insert being moved item to

  const updateItems = [
    ...itemsOfParent.slice(0, insertAtIndex + 1),
    item,
    ...(movingToNewParent && item?.children ? item.children : []),
    ...itemsOfParent.slice(insertAtIndex + 1),
  ]

  if (!schedule && !parent) {
    current.unscheduleItems = updateItems.map((item) => item.slug)

    if (checklistScheduleMap[item.slug]) {
      const filteredSchedules = schedules?.filter((slug) => slug !== item.slug)

      setSchedules(filteredSchedules)

      checklistScheduleMap[item.slug].delete = true
    }
  }

  if (schedule && !parent) {
    setSchedules(updateItems.map((item) => item.slug))

    if (!checklistScheduleMap[item.slug]) {
      checklistScheduleMap[item.slug] = {
        itemSlug: item.slug,
        recurrencePattern: "single-occurrence",
        startedAt: dayjs()
          .set("hour", 9)
          .set("minute", 0)
          .add(1, "day")
          .toDate(),
        recurring: false,
        itemsToSchedule: [item.slug, ...subItems.map((item) => item.slug)],
      }
    }

    checklistScheduleMap[item?.slug].delete = false

    current.unscheduleItems = current.unscheduleItems?.filter(
      (slug) => slug !== item.slug
    )
  }

  // reorder the items of the list from which we are dragging item.
  if (movingToNewParent) {
    const itemsOfPreviousParent = ChecklistItemsHelper.getSubItemsOfParent(
      itemMap,
      item.parent
    )
    ChecklistItemsHelper.reOrderItemMapItems(itemMap, itemsOfPreviousParent)
  }
  focusItemWithDelay(item.slug)

  // reOrderNewItems
  ChecklistItemsHelper.reOrderItemMapItems(itemMap, updateItems)
}
