import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react"
import { Checkbox } from "../Checkbox"
import { ChecklistItemsHelper } from "@bonsaichecklist/bonsai-utils"
import { AttachmentTiles } from "../AttachmentTiles"
import { ChecklistStatus } from "../ChecklistStatus"
import { isFunction, sum } from "lodash"
import { ChecklistItemBody } from "../ChecklistItemBody"
import { useMobileDetect } from "../useMobileDetect"
import { ScheduleService } from "~src/services"
import { useState, useActions } from "~src/store"
import { useRouter } from "next/router"
import { SUBBED_AS_LOCAL_KEY } from "~src/common/constants"
import { ChecklistHelper } from "~src/common/lib"
import { ChecklistDescriptionDisplay } from "../ChecklistDescription"

function UnScheduleItem(
  {
    item,
    onChange,
    bold = false,
    sub,
    expanded,
    unScheduledData,
  }: {
    item: ChecklistItem
    onChange?: () => void
    bold?: boolean
    sub?: boolean
    expanded?: boolean
    unScheduledData: any
  },
  ref: React.Ref<unknown>
): JSX.Element {
  const {
    auth: { user },
    checklist: { itemMap, showUnsavedAlert, current },
  } = useState()

  const {
    checklist: { setUnsavedAlert },
  } = useActions()

  const router = useRouter()
  const itemRefs = useRef([])

  const [indeterminate, setIndeterminate] = React.useState<boolean>(false)
  const [showAttachments, setShowAttachments] = React.useState<boolean>(false)
  const [isChecked, setIsChecked] = React.useState(false)
  const [progress, setProgress] = React.useState(0)

  const subItems = ChecklistItemsHelper.getSubItemsOfParent(itemMap, item.slug)
  const hasAttachments = Boolean(item.attachments?.length)
  const lineThrough = isChecked === true && !sub && "line-through"
  const slug = router?.query?.slug as string
  const helper = new ChecklistHelper(current)
  const isSubcribed = helper.isSubscribed(user)
  const isOwner = helper.isOwner(user)
  const occurrenceId = 0
  const isChecklistUpdated =
    new Date(item?.copiedFrom?.updatedAt) > new Date(item?.copiedAt)

  useEffect(() => {
    const data = { ...item }
    const localRun = JSON.parse(localStorage.getItem("localRun"))
    const run = (localRun?.[slug] || {})?.[0]?.slugs || {}

    if (unScheduledData?.[data.slug] || run?.[data.slug]) {
      setIsChecked(true)
      if (!subItems?.length) {
        setProgress(100)
      }
    }
  }, [slug, item, unScheduledData])

  useEffect(() => {
    if (isFunction(onChange)) {
      onChange()
    }
  }, [isChecked])

  useImperativeHandle(
    ref,
    () => {
      return {
        isChecked,
        setIsChecked,
        handleCheck: handleCheck.bind(this, item),
        handleLocalCheck: handleLocalCheck.bind(this, item),
      }
    },
    [isChecked]
  )

  async function handleCheck(
    item: ChecklistItem,
    checked: boolean
  ): Promise<void> {
    const localSubs = JSON.parse(localStorage.getItem(SUBBED_AS_LOCAL_KEY))

    const hasSubbedItem = localSubs?.find(
      (item: LocalSubbedTemplate) => item.slug === slug
    )

    const username = router?.query?.username as string
    const itemSlug = item?.slug
    const data = {
      slug,
      username,
      itemSlug: [itemSlug],
      itemValue: checked,
    }

    await ScheduleService.unscheduleItemCheck(
      slug,
      user ? user?.username : hasSubbedItem ? hasSubbedItem?.email : "",
      data
    )
      .then(([response]: any) => {
        if (
          response?.item?.slug === itemSlug &&
          response?.item?.checked === true
        ) {
          setProgress(100)
        } else {
          setProgress(0)
        }
      })
      .catch((error) => console.log(error))
  }

  async function handleChange(
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    const localSubs = JSON.parse(localStorage.getItem(SUBBED_AS_LOCAL_KEY))

    const hasSubbedItem = localSubs?.find(
      (item: LocalSubbedTemplate) => item.slug === slug
    )

    const checked = e.target.checked
    setIsChecked(checked)

    if (
      !showUnsavedAlert &&
      !hasSubbedItem &&
      checked &&
      !isSubcribed &&
      !isOwner
    ) {
      setUnsavedAlert(true)
    }

    const notLocalCheckFlow = hasSubbedItem || isSubcribed || isOwner

    notLocalCheckFlow
      ? await handleCheck(item, checked)
      : handleLocalCheck(item, checked)

    if (!item.parent) {
      itemRefs.current.forEach(async (itemRef) => {
        if (itemRef.isChecked !== checked) {
          itemRef.setIsChecked(checked)
          notLocalCheckFlow
            ? await itemRef.handleCheck(checked)
            : itemRef.handleLocalCheck(checked)
        }
      })
    }
  }

  const handleLocalCheck = (item: ChecklistItem, checked: boolean) => {
    const localRun = JSON.parse(localStorage.getItem("localRun"))
    const slug = router?.query?.slug as string

    const subItems = ChecklistItemsHelper.getSubItemsOfParent(
      itemMap,
      item.slug
    )

    if (!subItems.length && checked) {
      setProgress(100)
    }

    if (localRun && localRun[slug]) {
      const run = localRun[slug] || {}

      if (!run[occurrenceId] && checked) {
        run[occurrenceId] = { slugs: { [item.slug]: item.slug } }
        localStorage.setItem("localRun", JSON.stringify(localRun))
        return
      }

      if (
        run[occurrenceId]?.slugs &&
        !run[occurrenceId]?.slugs[item?.slug] &&
        checked
      ) {
        run[occurrenceId].slugs[item.slug] = item.slug
        localStorage.setItem("localRun", JSON.stringify(localRun))
      } else if (!checked) {
        delete run[occurrenceId]?.slugs[item.slug]

        if (!Object.keys(localRun[slug][occurrenceId]?.slugs || {}).length) {
          delete localRun[slug][occurrenceId]
        }

        if (!Object.keys(localRun[slug] || {}).length) {
          setUnsavedAlert(false)
        }

        localStorage.setItem("localRun", JSON.stringify(localRun))
        setProgress(0)
      }

      return
    }

    if (checked) {
      const map: {
        [key: string]: {
          [key: string]: {
            slugs: { [key: string]: string }
          }
        }
      } = {}

      map[slug] = {
        [occurrenceId]: { slugs: { [item.slug]: item.slug } },
      }
      localStorage.setItem("localRun", JSON.stringify(map))
    }
  }

  function onSubChange() {
    calculateCheck()
  }

  function calculateCheck() {
    const subItemCheckCount = sum(
      itemRefs.current.map((item) => {
        return item?.isChecked ? 1 : 0
      })
    )
    const subItemCount = subItems.length

    if (subItemCheckCount === 0) {
      //parent check false
      setIndeterminate(false)
      setIsChecked(false)
    }
    if (subItemCheckCount > 0 && subItemCount === subItemCheckCount) {
      //full checked
      setIndeterminate(false)
      setIsChecked(true)
    }
    if (subItemCheckCount > 0 && subItemCount > subItemCheckCount) {
      //Partial checked
      setIndeterminate(true)
      setIsChecked(false)
    }

    if (subItemCount) {
      const percentage = ((subItemCheckCount / subItemCount) * 100).toString()
      setProgress(parseInt(percentage))
    }
  }
  const isMobile = useMobileDetect()

  return (
    <>
      <div className="flex flex-row justify-between p-2">
        <div className="flex flex-row">
          <div className="flex flex-row justify-start gap-2">
            <div className="mr-2">
              <Checkbox
                checked={isChecked}
                indeterminate={indeterminate}
                onChange={handleChange}
              />
            </div>
            <div>
              <label
                className={`${sub ? "text-sm" : "text-base"} text-customGray ${
                  bold ? "font-bold" : "font-normal"
                } ${lineThrough} `}
                htmlFor="checkbox"
              >
                <ChecklistItemBody item={item} />
              </label>
              {!item?.parent && (
                <span className="text-sm font-normal text-customGray">
                  <ChecklistDescriptionDisplay
                    description={item?.description}
                  />
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-row-reverse">
          <div>
            <ChecklistStatus
              hasAttachments={hasAttachments}
              item={item}
              setShowAttachments={setShowAttachments}
              showAttachments={showAttachments}
              showProgress={!item.parent}
              status={progress}
              isUpdated={isChecklistUpdated}
            />
          </div>
        </div>
      </div>
      {hasAttachments && showAttachments && (
        <div className="flex flex-row items-center">
          <div className="w-12 mr-2" />
          <AttachmentTiles
            attachments={item.attachments}
            itemSlug={item.slug}
            size="10rem"
            viewOnly
          />
        </div>
      )}
      {expanded &&
        subItems.map((subItem, i) => (
          <div className="pl-8" key={i}>
            <UnScheduleItemWithRef
              item={subItem}
              onChange={onSubChange}
              ref={(el) => (itemRefs.current[i] = el)}
              sub
              unScheduledData={unScheduledData}
            />
          </div>
        ))}
    </>
  )
}

const UnScheduleItemWithRef = forwardRef(UnScheduleItem)
export default UnScheduleItemWithRef
