import React from "react"
import { errorReporter } from "~src/common/lib"
import { ServerErrorMessage } from "~src/components"

export interface GenericErrorBoundaryProps {
  Component?: ({ error }: { error: Error }) => JSX.Element
}

interface ComponentState {
  error?: Error
}

export class GenericErrorBoundary extends React.Component<
  GenericErrorBoundaryProps,
  ComponentState
> {
  constructor(props: GenericErrorBoundaryProps) {
    super(props)
    this.state = { error: undefined }
  }

  static getDerivedStateFromError(error: Error): { error: Error } {
    // Update state so the next render will show the Component UI.
    return { error }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    errorReporter.notify(error)
  }

  render(): React.ReactNode {
    const { error } = this.state
    const { children, Component } = this.props

    if (error) {
      if (Component) return <Component error={error} />
      return <ServerErrorMessage />
    }

    return children
  }
}
