import React from "react"
import { Link, PageHeading } from "~src/components"
import { routes } from "~src/routes"

interface NotFoundMessageProps {
  title?: string
}

export function NotFoundMessage({
  title = "Not Found",
}: NotFoundMessageProps): JSX.Element {
  return (
    <div>
      <div className="mb-6">
        <PageHeading>{title}</PageHeading>
      </div>
      <div className="mb-6 leading-normal">
        The page you were looking for could not be found, please try going back
        or heading to the{" "}
        <Link href={routes.generic.dashboard()}>homepage</Link>.
      </div>
      <div className="mb-6 leading-normal">
        <strong>Think it is our fault?</strong> Let us know by emailing{" "}
        <Link href="mailto:support@chainlist.com">support@chainlist.com</Link>
      </div>
    </div>
  )
}
