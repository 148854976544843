import React from "react"
import { Nav } from "~src/components"

import { BaseLayout, BaseLayoutProps } from "."
import { useGoogleLogin } from "~src/hooks"
import { useGoogleOneTapLogin } from "@react-oauth/google"
import { useState } from "~src/store"

export type MainLayoutProps = BaseLayoutProps & {
  paddingBottom?: boolean
}

function GoogleLogin(): JSX.Element {
  const { viaGoogle } = useGoogleLogin()

  useGoogleOneTapLogin({
    onSuccess: (credentialResponse) => {
      viaGoogle(credentialResponse?.credential)
    },
    onError: () => {
      console.log("Login Failed")
    },
  })
  return null
}

export function MainLayout({
  children,
  isFeedbackVisible = true,
  paddingBottom
}: MainLayoutProps): JSX.Element {
  const { auth } = useState()

  return (
    <BaseLayout isFeedbackVisible={isFeedbackVisible}>
      {auth.state !== "authenticated" && <GoogleLogin />}
      <Nav />
      <div
        className={`${
          paddingBottom ? "pb-16" : ""
        } bg-customChainlistBg overflow-x-hidden px-2`}
      >
        {children}
      </div>
    </BaseLayout>
  )
}
