import React from "react"

export function ToggleSwitch({
  checked,
  onClick,
  isChecklistVerified,
}: {
  checked: boolean
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  isChecklistVerified?: boolean
}): JSX.Element {
  return (
    <label className={`flex items-center cursor-pointer`} htmlFor="toogleA">
      <div className="relative">
        <input
          checked={checked}
          className="sr-only toggle-switch"
          disabled={!isChecklistVerified}
          type="checkbox"
        />
        <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
        <div
          className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"
          onClick={onClick}
        ></div>
      </div>
    </label>
  )
}
