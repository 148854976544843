import React from "react"
import { Button } from "../Button"

export interface ConfirmDialogProps {
  message: string
  onClose: () => void
  onCopy?: () => Promise<void>
}

export function ConfirmDialog({
  message,
  onClose,
  onCopy,
}: ConfirmDialogProps): JSX.Element {
  React.useEffect(() => {
    const closeModal = (e: any) => {
      if (e.key === "Escape" || e.key === "Enter" || e.key === "Return") {
        onClose()
      }
    }

    window.addEventListener("keydown", closeModal)

    return () => window.removeEventListener("keydown", closeModal)
  }, [])
  return (
    <>
      <div className="flex items-center justify-center overflow-auto fixed inset-0 z-50 sm:m-8 m-0">
        <div className="relative items-center justify-center ml-0">
          {/*content*/}
          <div
            className="border-0 rounded-lg shadow-lg p-8 relative flex flex-col max-w-lg bg-white outline-none focus:outline-none"
            style={{ height: "auto", maxHeight: 640 }}
          >
            {/*header*/}
            <div className="flex items-start justify-end">
              <h3 className="flex text-lg font-bold">Copy Checklist?</h3>
              <button
                className="ml-auto px-2 border-0 text-lg hover:bg-gray-300 rounded"
                onClick={onClose}
              >
                ×
              </button>
            </div>

            <p className="flex item-center mt-4 mb-8">{message}</p>

            <div className="bg-gray-50 sm:flex sm:flex-row-reverse">
              <Button className="w-full sm:w-auto" onClick={onCopy}>
                Yes, Copy
              </Button>
              <Button
                className="w-full sm:w-auto mt-4 sm:mt-0 sm:mr-4"
                onClick={onClose}
                variant="secondary"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}
