import { RichText } from "prismic-reactjs"

export const getPrismicData = (prismicResponse: Record<string, any> | null) => {
  return {
    communityAllTabHeading: RichText.asText(
      prismicResponse?.community_page_all_template_title
    ),
    communityAllTabDescription: RichText.asText(
      prismicResponse?.community_page_all_template_subtitle
    ),
    communityExploreTabHeading: RichText.asText(
      prismicResponse?.community_page_explore_title
    ),
    communityExploreTabDescription: RichText.asText(
      prismicResponse?.community_page_explore_subtitle
    ),
  }
}
