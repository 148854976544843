import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react"
import { isFunction, isWeakMap, sum } from "lodash"
import { useRouter } from "next/router"
import { ChecklistItemsHelper } from "@bonsaichecklist/bonsai-utils"

import { ScheduleService } from "~src/services"
import { Checkbox } from "../Checkbox"
import { ChecklistStatus } from "../ChecklistStatus"
import { AttachmentTiles } from "../AttachmentTiles"
import { ChecklistItemBody } from "../ChecklistItemBody"
import { useActions, useState } from "~src/store"
import { SUBBED_AS_LOCAL_KEY } from "~src/common/constants"
import { ChecklistHelper } from "~src/common/lib"
import { ChecklistDescriptionDisplay } from "../ChecklistDescription"
import { Link } from "../Link"
import { routes } from "~src/routes"

function TimelineItem(
  {
    sub = false,
    item,
    isRecurring,
    pattern,
    friendlyDescription,
    itemMap,
    bold = false,
    onChange,
    occurrenceId,
    scheduleId,
    runs,
    hideCompletedItem,
    checklistTitle,
    showChecklist,
    checklistSlug,
    disableCheckbox = false
  }: {
    item: ChecklistItem
    isRecurring?: boolean
    pattern?: string
    friendlyDescription?: string
    itemMap?: ChecklistItemMap
    bold?: boolean
    onChange?: () => void
    occurrenceId?: any
    scheduleId?: any
    sub?: boolean
    runs?: any
    hideCompletedItem?: boolean
    checklistTitle?: string
    showChecklist?: boolean
    checklistSlug?: string
    disableCheckbox?: boolean
  },
  ref: React.Ref<unknown>
): JSX.Element {
  const {
    auth: { user },
    checklist: { showUnsavedAlert, current },
  } = useState()

  const [indeterminate, setIndeterminate] = React.useState<boolean>(false)
  const [showAttachments, setShowAttachments] = React.useState<boolean>(false)
  const [isChecked, setIsChecked] = React.useState(null)
  const [progress, setProgress] = React.useState<number>(0)
  const [completedOn, setCompletedOn] = React.useState<Date>()

  const router = useRouter()
  const itemRefs = useRef([])

  const subItems = ChecklistItemsHelper.getSubItemsOfParent(itemMap, item.slug)
  const hasAttachments = Boolean(item.attachments?.length)
  const hasSubItems = Boolean(subItems?.length)
  const lineThrough = isChecked === true && !sub && "line-through"
  const slug = router?.query?.slug as string
  const helper = new ChecklistHelper(current)
  const isSubcribed = helper.isSubscribed(user)
  const isOwner = helper.isOwner(user)
  const isChecklistUpdated =
    new Date(item?.copiedFrom?.updatedAt) > new Date(item?.copiedAt)

  const {
    checklist: { setUnsavedAlert },
  } = useActions()

  useEffect(() => {
    const value = runs[occurrenceId]?.items[item?.slug]
    setIsChecked(value || null)
    if (!item.parent && subItems.length > 0) {
      calculateCheck()
    }

    setCompletedOn(runs[occurrenceId]?.timing[item?.slug] || undefined)
    setProgress(getStatusPercentage(runs))
  }, [runs])

  useEffect(() => {
    if (isFunction(onChange)) {
      onChange()
    }
  }, [isChecked])

  useImperativeHandle(
    ref,
    () => {
      return {
        isChecked,
        setIsChecked,
        handleCheck: handleCheck.bind(this, item),
        handleLocalCheck: handleLocalCheck.bind(this, item),
      }
    },
    [isChecked]
  )

  function getStatusPercentage(runs: any): number {
    if (!runs[occurrenceId]) {
      return 0
    }

    if (subItems.length) {
      const subItemsMap = ChecklistItemsHelper.makeItemMap(subItems)

      const totalCheckedItems = Object.keys(
        runs[occurrenceId]?.items || {}
      ).filter(
        (item) => runs[occurrenceId]?.items[item] && subItemsMap[item as string]
      )?.length

      const percentage = (totalCheckedItems / subItems.length) * 100

      return parseInt(percentage.toString())
    }

    if (runs[occurrenceId]?.items?.[item.slug]) {
      return 100
    } else {
      return 0
    }
  }

  async function handleCheck(
    item: ChecklistItem,
    checked: boolean
  ): Promise<void> {
    const localSubs = JSON.parse(localStorage.getItem(SUBBED_AS_LOCAL_KEY))

    const hasSubbedItem = localSubs?.find(
      (item: LocalSubbedTemplate) => item.slug === slug
    )

    const username = router?.query?.username as string
    const itemSlug = item?.slug
    const data = {
      slug,
      username,
      itemSlug: itemSlug,
      itemValue: checked,
      scheduleId,
      occurrenceId,
    }
    await ScheduleService.upComingChecklistCheck(
      slug,
      user ? user?.username : hasSubbedItem ? hasSubbedItem?.email : "",
      data
    )
      .then(({ runs }: any) => {
        setCompletedOn(runs[occurrenceId]?.timing[item.slug])
        setProgress(getStatusPercentage(runs))
      })
      .catch((error) => console.log(error))
  }

  async function handleChange(
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    const localSubs = JSON.parse(localStorage.getItem(SUBBED_AS_LOCAL_KEY))

    const hasSubbedItem = localSubs?.find(
      (item: LocalSubbedTemplate) => item.slug === slug
    )

    const checked = e.target.checked
    setIsChecked(checked)

    if (
      !showUnsavedAlert &&
      !hasSubbedItem &&
      checked &&
      !isSubcribed &&
      !isOwner
    ) {
      setUnsavedAlert(true)
    }

    const notLocalCheckFlow = hasSubbedItem || isSubcribed || isOwner

    notLocalCheckFlow
      ? await handleCheck(item, checked)
      : handleLocalCheck(item, checked)

    if (!item.parent) {
      itemRefs.current.forEach((itemRef) => {
        if (itemRef.isChecked !== checked) {
          itemRef.setIsChecked(checked)
          notLocalCheckFlow
            ? itemRef.handleCheck(checked)
            : itemRef.handleLocalCheck(checked)
        }
      })
    }
  }

  const handleLocalCheck = (item: ChecklistItem, checked: boolean) => {
    const localRun = JSON.parse(localStorage.getItem("localRun"))
    const slug = router?.query?.slug as string

    const subItems = ChecklistItemsHelper.getSubItemsOfParent(
      itemMap,
      item.slug
    )

    if (!subItems.length) {
      setProgress(100)
    }

    if (localRun && localRun[slug]) {
      const run = localRun[slug] || {}

      if (!run[occurrenceId] && checked) {
        run[occurrenceId] = { slugs: { [item.slug]: item.slug }, scheduleId }
        localStorage.setItem("localRun", JSON.stringify(localRun))
        return
      }

      if (
        run[occurrenceId]?.slugs &&
        !run[occurrenceId]?.slugs[item?.slug] &&
        checked
      ) {
        run[occurrenceId].slugs[item.slug] = item.slug
        localStorage.setItem("localRun", JSON.stringify(localRun))
      } else if (!checked) {
        delete run[occurrenceId]?.slugs[item.slug]

        if (!Object.keys(localRun[slug][occurrenceId]?.slugs || {}).length) {
          delete localRun[slug][occurrenceId]
        }

        if (!Object.keys(localRun[slug] || {}).length) {
          setUnsavedAlert(false)
        }

        localStorage.setItem("localRun", JSON.stringify(localRun))
        setProgress(0)
      }

      return
    }

    if (checked) {
      const map: {
        [key: string]: {
          [key: string]: {
            slugs: { [key: string]: string }
            scheduleId: string
          }
        }
      } = {}

      map[slug] = {
        [occurrenceId]: { slugs: { [item.slug]: item.slug }, scheduleId },
      }
      localStorage.setItem("localRun", JSON.stringify(map))
    }
  }

  function onSubChange() {
    calculateCheck()
  }

  function calculateCheck() {
    const subItemCheckCount = sum(
      itemRefs.current.map((item) => {
        return item?.isChecked ? 1 : 0
      })
    )

    const subItemCount = subItems.length

    const localRun = JSON.parse(localStorage.getItem("localRun"))

    if (subItemCheckCount === 0) {
      //parent check false
      setIndeterminate(false)
      setIsChecked(false)

      if (localRun && localRun[slug] && localRun[slug][occurrenceId]?.slugs) {
        delete localRun[slug][occurrenceId]?.slugs[item?.slug]

        localStorage.setItem("localRun", JSON.stringify(localRun))
      }
    }

    if (subItemCheckCount > 0 && subItemCount === subItemCheckCount) {
      //full checked
      setIndeterminate(false)
      setIsChecked(true)

      if (localRun && localRun[slug] && localRun[slug][occurrenceId]?.slugs) {
        localRun[slug][occurrenceId].slugs[item.slug] = item.slug

        localStorage.setItem("localRun", JSON.stringify(localRun))
      }
    }

    if (subItemCheckCount > 0 && subItemCount > subItemCheckCount) {
      //Partial checked
      setIndeterminate(true)
      setIsChecked(false)

      if (localRun && localRun[slug] && localRun[slug][occurrenceId]?.slugs) {
        delete localRun[slug][occurrenceId]?.slugs[item?.slug]

        localStorage.setItem("localRun", JSON.stringify(localRun))
      }
    }

    if (subItemCount) {
      const percentage = ((subItemCheckCount / subItemCount) * 100).toString()
      setProgress(parseInt(percentage))
    } else if (
      runs[occurrenceId]?.items?.[item.slug] ||
      (localRun &&
        localRun[slug] &&
        localRun[slug][occurrenceId]?.slugs[item?.slug])
    ) {
      setProgress(100)
    } else {
      setProgress(0)
    }
  }

  const checklistCardCss = `bg-white border border-gray-200 md:w-full py-3 pl-3 ${
    showChecklist ? "rounded-t-lg" : "mb-10 -mt-5 rounded-lg"
  }  ${hideCompletedItem && progress === 100 ? "hidden" : "block"}`

  return (
    <>
      <div className={!sub ? checklistCardCss : ""}>
        <div className="flex flex-row justify-between p-2">
          <div className="flex flex-row">
            <div className="flex flex-row justify-start gap-2">
              <div className="mr-2">
                <Checkbox
                  isDisabled={disableCheckbox}
                  checked={isChecked}
                  indeterminate={indeterminate}
                  onChange={handleChange}
                />
              </div>
              <div>
                <div
                  className={`${sub ? "text-sm" : "text-15px"} text-customGray ${
                    bold ? "font-bold" : "font-normal"
                  } ${lineThrough} flex-wrap`}
                >
                  <ChecklistItemBody item={item} />
                </div>
                {!item?.parent && (
                  <span className="text-sm font-normal text-customGray">
                    <ChecklistDescriptionDisplay description={item.description} />
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-row-reverse">
            <div>
              <ChecklistStatus
                completedOn={completedOn}
                friendlyDescription={friendlyDescription}
                hasAttachments={hasAttachments}
                hasSubItems={hasSubItems}
                isRecurring={isRecurring}
                item={item}
                pattern={pattern}
                setShowAttachments={setShowAttachments}
                showAttachments={showAttachments}
                showProgress={!item.parent}
                status={progress}
                checklistTitle={checklistTitle}
                isUpdated={isChecklistUpdated}
              />
            </div>
          </div>
        </div>
        {subItems.map((subItem, i) => (
          <div className="pl-8" key={i}>
            <TimelineItemWithRef
              item={subItem}
              itemMap={itemMap}
              occurrenceId={occurrenceId}
              onChange={onSubChange}
              ref={(el) => (itemRefs.current[i] = el)}
              runs={runs}
              scheduleId={scheduleId}
              sub
              disableCheckbox={disableCheckbox}
            />
          </div>
        ))}
        {hasAttachments && showAttachments && (
          <div className="flex flex-row items-center">
            <div className="w-12 mr-2" />
            <AttachmentTiles
              attachments={item.attachments}
              itemSlug={item.slug}
              size="10rem"
              viewOnly
            />
          </div>
        )}
      </div>
      {showChecklist && (
        <div className="bg-white bg-opacity-30 mb-10 text-[9px] pl-3 py-2 text-[#828282] border border-[#EEEEEE] rounded-b-lg">
          CHECKLIST:{" "}
          <Link
            href={routes.checklists.show(checklistSlug)}
            className="text-[12px]"
          >
            {checklistTitle}
          </Link>
        </div>
      )}
    </>
  )
}

const TimelineItemWithRef = forwardRef(TimelineItem)
export default TimelineItemWithRef
