import { useRouter } from "next/router"
import React from "react"
import { SearchBox } from "react-instantsearch-dom"

import { Button, Tooltip, useMobileDetect } from "~src/components"
import { TABS } from "~src/components/Community/community.constants"
import { routes } from "~src/routes"

import { communityHeadersProps } from "./community-header.types"
import { CommunitySearchHeaders } from "../community-search-headers"

export function CommunityHeader({
  categories,
  setSearch,
  searchSubmitted,
  layout,
  handleLayoutToggle,
}: communityHeadersProps): JSX.Element {
  const isMobile = useMobileDetect()
  const router = useRouter()

  const [searchTxt, setSearchTxt] = React.useState<string>("")

  const commonTabStyles =
    "border shadow-none hover:shadow-none font-bold  mr-4 py-3 px-4 rounded-full whitespace-nowrap"

  const activeTabStyles = `${commonTabStyles} bg-blue-500 text-white  border-blue-500 cursor-default`
  const inActiveTabStyles = `${commonTabStyles} !bg-white text-black  border-gray-300 hover:!bg-blue-100 cursor-pointer`

  const currentCategory: string = router?.query?.category as string

  const searchReset = () => {
    setSearchTxt("")
    setSearch("")
  }

  return (
    <div className="w-full pt-4 px-4 xl:px-0">
      <div
        className="max-w-7xl px-1 md:flex md:flex-row-reverse items-center justify-between mx-auto
       flex-wrap"
      >
        <div
          className={`relative flex max-w-7xl justify-${
            isMobile ? "center" : "end"
          }`}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              setSearch(searchTxt)
            }
          }}
        >
          <SearchBox
            {...(isMobile ? { className: "w-full" } : {})}
            onChange={(e) => setSearchTxt(e.currentTarget.value)}
            onReset={searchReset}
            searchAsYouType={false}
            showLoadingIndicator
            translations={{
              placeholder: "Search Community",
            }}
          />
        </div>

        <div className={`flex justify-between`}>
          <div className={`flex scrollbar-hide overflow-x-scroll py-8`}>
            {searchSubmitted ? (
              <p
                className="cursor-pointer text-blue-600"
                onClick={() => searchReset()}
              >
                ← back to Community
              </p>
            ) : (
              <>
                <Tooltip tooltipTitle="Explore curated and verified lists">
                  <span>
                    <Button
                      className={
                        router.asPath.includes(TABS.explore.key.toLowerCase())
                          ? activeTabStyles
                          : inActiveTabStyles
                      }
                      onClick={() =>
                        router.push(
                          `/community/${TABS.explore.key?.toLowerCase()}`
                        )
                      }
                      tooltip="Explore curated and verified lists"
                    >
                      {TABS.explore.title}
                    </Button>
                  </span>
                </Tooltip>

                {categories?.map((item: TemplateCategory) => (
                  <Tooltip key={item.id} tooltipTitle={item.name}>
                    <span>
                      <Button
                        className={
                          router.asPath.includes(item.name.toLowerCase())
                            ? activeTabStyles
                            : inActiveTabStyles
                        }
                        onClick={() =>
                          router.push(
                            routes.generic.community(item.slug?.toLowerCase())
                          )
                        }
                      >
                        {item.name}
                      </Button>
                    </span>
                  </Tooltip>
                ))}

                <Tooltip tooltipTitle="View all publicly-shared lists">
                  <span>
                    <Button
                      className={
                        router.asPath.includes(TABS.all.key.toLowerCase())
                          ? activeTabStyles
                          : inActiveTabStyles
                      }
                      //style={{minWidth:'120px', display:'inline-block'}}
                      onClick={() =>
                        router.push(routes.generic.community(TABS.all.key))
                      }
                    >
                      {TABS.all.title}
                    </Button>
                  </span>
                </Tooltip>
              </>
            )}
          </div>
        </div>
      </div>
      <CommunitySearchHeaders
        category={categories}
        handleLayoutToggle={handleLayoutToggle}
        layout={layout}
        searchSubmitted={searchSubmitted}
        selectedTab={TABS[currentCategory]}
      />
    </div>
  )
}
