import { CommunityTabsProps, TabMap } from "./community.types"

export const TABS: TabMap<CommunityTabsProps> = {
  explore: {
    key: "explore",
    title: "Explore",
    heading: "",
    description: "",
  },
  all: {
    key: "all",
    title: "All Templates",
    heading: "",
    description: "",
  },
}
