import debug from "debug"
import { Context } from "overmind"
import { v4 as uuid } from "uuid"
import { stringType } from "aws-sdk/clients/iam"

const d = debug("ChainList/store/system/actions")

export function showToastAlert(
  { state }: Context,
  toastAlert: ToastAlert
): void {
  const alert = { id: uuid(), ...toastAlert }
  state.system.toastAlerts = [alert, ...state.system.toastAlerts]
}

export function removeToastAlert(
  { state }: Context,
  toastAlertId: stringType
): void {
  state.system.toastAlerts = state.system.toastAlerts.filter(
    (t) => t.id !== toastAlertId
  )
}

export function removeAllToastAlert({ state }: Context): void {
  state.system.toastAlerts = []
}
