import React from "react"
import * as yup from "yup"
import { Button, Form, Input } from "~src/components"

const schema = yup.object().shape({
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirmation password is required"),
  password: yup.string().required("Password is required"),
})

export interface ResetPasswordFormProps {
  onSubmit: (values: any) => void | Promise<void>
  isSubmitting: boolean
  passwordError: boolean
}

export function ResetPasswordForm({
  onSubmit,
  isSubmitting,
  passwordError,
}: ResetPasswordFormProps): JSX.Element {
  return (
    <Form onSubmit={onSubmit} schema={schema} values={{}}>
      {({ values, errors, handleChange, handleSubmit }: any): JSX.Element => (
        <form onSubmit={handleSubmit}>
          <div className="my-2">
            {errors?.password && (
              <div className="mb-2 text-red-600">{errors.password}</div>
            )}
            <Input
              autoCapitalize="none"
              autoCorrect="off"
              id="password"
              onChange={handleChange}
              placeholder="New Password..."
              type="password"
              value={values.password}
            />
          </div>
          <div className="my-2">
            {errors?.confirmPassword && (
              <div className="mb-2 text-red-600">{errors.confirmPassword}</div>
            )}
            {passwordError && (
              <div className="mb-2 text-red-600">
                Minimum password length is any combination of 8 characters of
                alphanumeric and/or special characters.
              </div>
            )}
            <Input
              autoCapitalize="none"
              autoCorrect="false"
              id="confirmPassword"
              onChange={handleChange}
              placeholder="Confirm New Password..."
              type="password"
              value={values.confirmPassword}
            />
          </div>
          <div className="mt-6">
            <Button
              loading={isSubmitting}
              onClick={handleSubmit}
              type="submit"
            >
              Update Password
            </Button>
          </div>
        </form>
      )}
    </Form>
  )
}
