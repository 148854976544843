export class URLHelper {
  public static prefixURL(url: string): string {
    // Handle prefixed mailtos, even if they're the wrong
    // format (eg with "//").
    const mailto = url.startsWith("mailto:")
    if (mailto)
      return url.startsWith("mailto://")
        ? url.replace("mailto://", "mailto:")
        : url

    // If links are prefixed properly, return as-is.
    const https = url.startsWith("https://")
    const http = url.startsWith("http://")
    if (https || http) return url

    // If URL has an "@" symbol we consider it an email,
    // so prefix with mailto, otherwise default to HTTPS.
    // TODO: this is very nieve, make more robust
    const isEmail = url.includes("@")
    const prefix = isEmail ? "mailto:" : "https://"
    return prefix + url
  }
}
