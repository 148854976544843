type State = {
  firstButtonlabel: string
  nextButtonlabel: string
  doneButtonlabel?: string
  showProductTour?: boolean
  startFromChecklistPage: boolean
  showFullProductTour: boolean
  multiPage?: boolean
  fullProductTour?: []
  handleOnExitIntro?: (stepIndex: number) => void
  handleBeforeChange?: (nextStepIndex: number) => void | false
  handleCompleteIntro?: () => void
  setStartFromChecklistPage?: () => void
}

export const state: State = {
  showProductTour: false,
  startFromChecklistPage: false,
  showFullProductTour: false,
  fullProductTour: [],
  firstButtonlabel: "Let's get started",
  nextButtonlabel: "Next",
}
