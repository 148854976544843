import { useGoogleLogin as useGoogleLoginHook } from "@react-oauth/google"
import React from "react"
import loaderImage from "~public/icons/chainlist-loading.svg"
import { Button, ButtonProps, SocialTextImage } from "~src/components"
import { useGoogleLogin } from "~src/hooks"
import GoogleLogo from "~public/icons/google.svg"
export function GoogleLoginButton(props: ButtonProps): JSX.Element {
  const { viaGoogle } = useGoogleLogin()
  const [loading, setLoading] = React.useState<boolean>(false)
  const login = useGoogleLoginHook({
    onSuccess: async (tokenResponse) => {
      viaGoogle(tokenResponse?.access_token, "accessToken").finally(() => {
        setLoading(false)
      })
    },
    onNonOAuthError: () => {
      setLoading(false)
    },
  })
  const btnText = loading ? "Please wait..." : "Continue with Google"
  const btnImage = loading ? loaderImage : GoogleLogo

  return (
    <Button
      loading={loading}
      onClick={() => {
        setLoading(true)
        login()
      }}
      variant="secondary"
      {...props}
    >
      <SocialTextImage text={btnText} imageSpin={loading} image={btnImage} />
    </Button>
  )
}
