import classnames from "classnames"
import React from "react"
import DefaultAvatarUrl from "./defaultUser.svg"
export interface AvatarProps {
  className?: string
  avatarUrl?: string
  size?: number
  title?: string
}

export function Avatar({
  className,
  avatarUrl,
  size = 20,
  title = "Avatar",
  ...props
}: AvatarProps): JSX.Element {
  const classes = classnames("rounded-full max-w-none", className)

  return (
    <img
      alt={avatarUrl ? "Avatar" : "Default-User"}
      className={classes}
      height={size}
      src={avatarUrl || DefaultAvatarUrl}
      width={size}
      {...props}
    />
  )
}
