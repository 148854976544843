import React, { useEffect } from "react"
import Router from "next/router"
import { useStore } from "~src/store"

export function useToastHide(): void {
  const {
    actions: {
      system: { removeAllToastAlert },
    },
  } = useStore()

  useEffect(() => {
    const routeChangeStart = (url: string): void => {
      if (Router.asPath !== url) {
        removeAllToastAlert()
      }
    }

    Router.events?.on("routeChangeStart", routeChangeStart)

    return (): void => {
      Router.events.off("routeChangeStart", routeChangeStart)
    }
  }, [])
}
