import cs from "classnames"
import GoogleLogo from "~public/icons/google.svg"

type SocialTextImageProps = {
  image?: string
  text?: string
  imageSpin?: boolean
}
export const SocialTextImage = ({
  image = GoogleLogo,
  text = "Continue With Google",
  imageSpin,
}: SocialTextImageProps): JSX.Element => {
  return (
    <div className={cs("flex", "items-center")}>
      <img
        alt="loader"
        className={cs("mr-4", { "animate-spin": imageSpin }, "h-6", "w-6")}
        src={image}
      />
      {text}
    </div>
  )
}
