import React from "react"
import { LoginForm } from "../login-form"
import { AuthLayout } from "~src/layouts"

export default function LoginModalContent(): JSX.Element {
  return (
    <AuthLayout>
      <LoginForm />
    </AuthLayout>
  )
}
