import classNames from "classnames"
import React, { useEffect, useRef } from "react"

interface Props {
  className?: string
  checked?: boolean
  id?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>
  indeterminate?: boolean
  isDisabled?: boolean
}

export function Checkbox({
  className,
  id,
  checked,
  onChange,
  indeterminate,
  isDisabled
}: Props): JSX.Element {
  const checkRef = useRef<HTMLInputElement>()

  useEffect(() => {
    checkRef.current.indeterminate = indeterminate
  }, [checkRef, indeterminate])

  const classes = classNames(
    "rounded border-gray-300 foucs:ring-0 focus:ring-offset-transparent focus:ring-transparent",
    className
  )
  return (
    <input
      disabled={isDisabled}
      checked={checked}
      className={classes}
      id={id}
      onChange={onChange}
      ref={checkRef}
      type="checkbox"
    />
  )
}
