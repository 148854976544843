type State = {
  toastAlerts?: ToastAlert[]
  isSearchResultOpened?: boolean
  featuredChecklists?: number
  isSideBySideOpened?: boolean

  showSignupTooltip?: boolean
}

export const state: State = {
  toastAlerts: [],
  isSearchResultOpened: false,
  featuredChecklists: 0,
  isSideBySideOpened: false,
  showSignupTooltip: false,
}
