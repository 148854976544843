export function getTreeFromFlatData(flatData: GenericObject[]): any {
  if (!flatData?.length) {
    return []
  }

  const childrenToParents = {} as GenericObject
  flatData.forEach((child) => {
    const parentKey = child.parent || "root"

    if (parentKey in childrenToParents) {
      childrenToParents[parentKey].push(child)
    } else {
      childrenToParents[parentKey] = [child]
    }
  })

  const trav = (parent: GenericObject) => {
    const parentKey = parent.slug
    if (parentKey in childrenToParents) {
      return {
        ...parent,
        children: childrenToParents[parentKey].map((child: GenericObject) =>
          trav(child)
        ),
      }
    }

    return { ...parent }
  }

  return childrenToParents.root.map((child: GenericObject) => trav(child))
}
