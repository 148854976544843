import Prismic from "@prismicio/client"
import { useState, useStore } from "~src/store"

export interface productTourDataType {
  intro: string
  order: number
  position?: string
  title?: string
  element?: string
}

export interface userGuideHintsDataType {
  element: string
  hint: string
  hintPosition?: string
  order: number
}

export interface IntroJsDataType {
  steps: productTourDataType
  hints: userGuideHintsDataType
}

export class PrismicService {
  public static async getPageData(
    auth: GenericObject,
    localData: GenericObject
  ): Promise<GenericObject> {
    const { prismicDocument, page } = localData

    const prismicClient = Prismic.client(
      process.env.NEXT_PUBLIC_PRISMIC_PRODUCT_TOUR_URL,
      {
        accessToken: process.env.NEXT_PUBLIC_PRISMIC_API_TOKEN,
      }
    )
    const prismicPageTour = await prismicClient.query(
      Prismic.Predicates.at("document.type", prismicDocument)
    )

    if (prismicPageTour?.results?.length) {
      const { data = [] } = prismicPageTour.results[0]
      const { body = [] } = data as { body: any[] }

      // Map prismic data with local intro js steps and sort it
      const productTourData = this.mapPrismicBody(body, page)
      const buttonLabels = this.getButtonsLabels(data)

      return {
        steps: productTourData,
        buttonLabels,
      }
    }

    return []
  }

  private static mapPrismicBody(body: GenericObject[], page: string) {
    let data = []
    data =
      body?.length &&
      body
        .map((item: GenericObject) => {
          const {
            primary: {
              step_number: order,
              unique_id: selector,
              title: [{ text: title = "" } = ""],
              paragraph: [{ text: intro }],
              position = "auto",
            },
          } = item

          return {
            title,
            intro,
            order,
            position,
            element: `.${selector}`,
          }
        })
        .sort((a: productTourDataType, b: productTourDataType) => {
          return a.order - b.order
        })

    return data
  }

  private static getButtonsLabels(data: any) {
    const {
      firstbuttonlabel: [{ text: firstButtonlabel }] = {},
      nextbuttonlabel: [{ text: nextButtonlabel }] = {},
      donebuttonlabel: [{ text: doneButtonlabel }] = {},
    } = data

    return {
      buttonLabels: {
        firstButtonlabel,
        nextButtonlabel,
        doneButtonlabel,
      },
    }
  }

  /* private static mapPrismicHints(hints: GenericObject[], page: string) {
    let data = []
    data =
      hints?.length &&
      hints
        .map((item: GenericObject) => {
          const {
            primary: {
              step_number: order,
              unique_id: uniqueId,
              paragraph: [{ text: hint }],
            },
          } = item

          // Get local steps object data
          const hintsData = this.getLocalHintsObject(page)

          return {
            hint,
            ...hintsData[uniqueId],
            order,
          }
        })
        .sort((a: userGuideHintsDataType, b: userGuideHintsDataType) => {
          return a.order - b.order
        })

    return data
  } */

  /*private static getLocalStepsObject(page: string): GenericObject {
    let userGuideStepsKey = `${page}PageSteps`
    if (page.indexOf("Page") !== -1) {
      userGuideStepsKey = `${page}Steps`
    }

    return userGuideDataMapping[userGuideStepsKey]
  }

    private static getLocalHintsObject(page: string): GenericObject {
    let userGuideHintsKey = `${page}PageHints`
    if (page.indexOf("Page") !== -1) {
      userGuideHintsKey = `${page}Hints`
    }

    return userGuideDataMapping[userGuideHintsKey]
  } */
}
