import React from "react"
import { connectHighlight } from "react-instantsearch-dom"

interface itemProp {
  body: {
    matchLevel?: string
    value: string
  }
}

interface HitProp {
  hit: any
  attribute?: string
}

export function Snippet({ hit, attribute }: HitProp): JSX.Element {
  const { _snippetResult: { items } = [] } = hit || {}

  return (
    <span>
      {items?.length &&
        items
          ?.filter(
            (item: itemProp, index: number) => item?.body?.matchLevel === "full"
          )
          .splice(0, 1)
          .map((item: itemProp, index: number) => {
            return (
              <React.Fragment key={index}>
                <em
                  dangerouslySetInnerHTML={{ __html: item?.body?.value }}
                ></em>
                ...
              </React.Fragment>
            )
          })}
    </span>
  )
}

export const CustomSnippet: any = connectHighlight(Snippet)
