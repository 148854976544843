import { Context } from "overmind"
import { ChecklistService } from "~src/services"
import dayjs from "dayjs"

export async function starChecklist(
  { state: { auth } }: Context,
  checklist: Checklist
): Promise<boolean> {
  const starredChecklist = await ChecklistService.starChecklist(checklist.slug)

  if (starredChecklist) {
    const isChecklistIdExist = auth?.user?.starredChecklists?.some(
      (userStarredChecklist) =>
        userStarredChecklist.starredChecklist === checklist.id
    )

    if (!isChecklistIdExist)
      auth.user.starredChecklists.push({
        starredChecklist: checklist.objectID || checklist.id,
        starredOn: dayjs().format("DD-MM-YYYYTHH:mmZZ"),
      })
  }
  return Boolean(starredChecklist)
}

export async function unStarChecklist(
  { state: { auth } }: Context,
  checklist: Checklist
): Promise<boolean> {
  const UnStarredChecklist = await ChecklistService.unStarChecklist(
    checklist.slug
  )
  if (UnStarredChecklist) {
    auth.user.starredChecklists = auth.user?.starredChecklists?.filter(
      (userStarredChecklist) =>
        userStarredChecklist.starredChecklist !==
        (checklist.objectID || checklist.id)
    )
  }

  return Boolean(UnStarredChecklist)
}
