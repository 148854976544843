import React from "react"

import {
  ChecklistDescriptionDisplay,
  ChecklistVerifiedTick,
  Sidebar,
} from "~src/components"
import { TimelineMetaData } from "~src/components/TimelineMetaData"

interface Props {
  checklistVerified: boolean
  checklist: Checklist
  showSidebar: boolean
  mobileMenuButtons: () => React.ReactElement
  setHideCompletedItems: (value: boolean) => void
  sideBarRef?: React.MutableRefObject<HTMLDivElement>
}

export function TimelineHeader({
  checklist,
  checklistVerified,
  showSidebar,
  mobileMenuButtons,
  setHideCompletedItems,
  sideBarRef,
}: Props): JSX.Element {
  return (
    <header className="flex flex-col-reverse lg:flex-row">
      <div
        className={`${
          showSidebar && "px-2"
        } flex-grow mt-4 mb-4 lg:mt-0 lg:mr-8`}
      >
        <div className="flex items-center">
          <h1 className="text-2xl font-bold flex items-center">
            {checklist?.title}
          </h1>
          {checklistVerified && <ChecklistVerifiedTick />}
        </div>
        {checklist?.description && (
          <div className="text-sm text-gray-700 mt-2">
            <ChecklistDescriptionDisplay description={checklist.description} />
          </div>
        )}
      </div>
      <div className=" mt-4">
        <div className="absolute left-0 w-full">
          <Sidebar showSidebar={showSidebar} sideBarRef={sideBarRef}>
            <TimelineMetaData
              checklist={checklist}
              setHideCompletedItems={setHideCompletedItems}
            />
          </Sidebar>
        </div>
      </div>
      {mobileMenuButtons()}
    </header>
  )
}
