import { useRouter } from "next/router"
import React from "react"
import { MODAL_TYPES } from "~src/common/constants"
import { routes } from "~src/routes"
import { useStore } from "~src/store"
import { useShowAuthModals } from "./useShowAuthModals"

export function useAppleLogin(): {
  loading: boolean
  viaApple: (code: string) => Promise<void>
} {
  const [loading, setLoading] = React.useState<boolean>(false)
  const router = useRouter()
  const {
    actions: {
      auth: { loginWithApple },
    },
    state: {
      auth: { showLoginModal, showSignupModal },
    },
  } = useStore()

  const { handleModal } = useShowAuthModals()

  async function viaApple(code: string): Promise<void> {
    try {
      const response = await loginWithApple({ code })
      setLoading(false)
      closeAuthModals()
      if (response.requestUsername) {
        router.push(routes.users.setUsername())
      } else if (router.asPath === "/" && response.runActivityExists) {
        router.push(routes.generic.dashboardView())
      } else if (router.asPath === "/" && !response.runActivityExists) {
        router.push(routes.generic.community())
      }
    } catch (err) {
      setLoading(false)
      alert(err.toString())
    }
  }
  const closeAuthModals = () => {
    if (showLoginModal) {
      handleModal(MODAL_TYPES.login)
    }

    if (showSignupModal) {
      handleModal(MODAL_TYPES.signup)
    }
  }
  return { loading, viaApple }
}
