import QRCode from "qrcode"
import { useQuery } from "react-query"

const QR_CODE_WIDTH = 400

async function generateQrCode(url: string): Promise<string> {
  return QRCode.toDataURL(url, { width: QR_CODE_WIDTH })
}

export function useQrCode(url: string) {
  return useQuery<string, Error>(["qrcode", url], () => generateQrCode(url))
}
