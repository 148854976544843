import React from "react"
import { Modal } from "src/components"
import { MODAL_TYPES } from "~src/common/constants"
import { useShowAuthModals } from "~src/hooks/useShowAuthModals"
import { useStore } from "~src/store"
import LoginModalContent from "../login-modal-content/login-modal-content"

export function LoginModal(): JSX.Element {
  const { handleModal } = useShowAuthModals()

  const {
    state: {
      auth: { action },
    },
  } = useStore()

  return (
    <Modal
      CustomTitleComponent={
        action?.description && (
          <CustomTitleComponent description={action.description} />
        )
      }
      classNames={{
        titleClassNames: "font-bold text-xl text-black ",
      }}
      config={{
        width: "md",
        showCloseButton: false,
      }}
      onClose={() => handleModal(MODAL_TYPES.login)}
      title="Welcome back to ChainList 👋"
    >
      <LoginModalContent />
    </Modal>
  )
}

function CustomTitleComponent({
  description,
}: {
  description: string
}): JSX.Element {
  return (
    <div className="bg-gray-100 py-4 px-12 rounded">
      <p className="text-center font-bold text-sm text-black">{description}</p>
    </div>
  )
}
