import { useRouter } from "next/router"
import React from "react"
import { Button } from "~src/components"
import { routes } from "~src/routes"
import GridIcon from "~public/icons/grid.svg"
import ListIcon from "~public/icons/list.svg"
import sparklesIcon from "~public/icons/sparkles.svg"
import GridGrayIcon from "~public/icons/GridGray.svg"
import ListBlueIcon from "~public/icons/ListBlue.svg"
import { PageHeading } from "../../PageHeading"

import { CommunitySearchHeadersProps } from "./community-search-headers.types"

function SearchView({
  handleLayoutToggle,
  layout,
}: {
  handleLayoutToggle: (value: string) => void
  layout: string
}): JSX.Element {
  return (
    <div className="max-w-7xl mx-auto flex items-center justify-end gap-3 px-4 xl:px-1 -mt-19  ">
      <img
        alt="Grid View"
        className="cursor-pointer"
        onClick={() => handleLayoutToggle("grid")}
        src={layout === "grid" ? GridIcon : GridGrayIcon}
      />
      <img
        alt="List View"
        className="cursor-pointer"
        onClick={() => handleLayoutToggle("list")}
        src={layout === "list" ? ListBlueIcon : ListIcon}
      />
    </div>
  )
}

export function CommunitySearchHeaders({
  selectedTab,
  searchSubmitted,
  category,
  layout,
  handleLayoutToggle,
}: CommunitySearchHeadersProps): JSX.Element {
  const router = useRouter()
  const currentCategory: string = router?.query?.category as string
  const selectedCategory = category.filter(function (item: any) {
    if (item.slug === currentCategory) return item
  })

  return (
    <div className="px-2 max-w-7xl mx-auto">
      {searchSubmitted ? (
        <div className="md:flex justify-between items-center">
          <p className="text-bold text-gray-700 mt-2">
            Search results for <b>{searchSubmitted}</b> in Community
          </p>
          <Button
            className="mt-3 md:mt-0 bg-secondaryGray text-primaryBlue border-none shadow-none  justify-center"
            onClick={() =>
              router.push(routes.generic.aiChecklist(searchSubmitted))
            }
            variant="secondary"
          >
            <img alt="sparkles" className="mr-2" src={sparklesIcon} />
            Generate a checklist using AI
          </Button>
        </div>
      ) : selectedTab ? (
        <>
          <PageHeading className="text-3xl">
            {selectedTab?.heading || selectedTab?.name}
          </PageHeading>
          <div className=" flex flex-row justify-between w-full items-end">
            <p className="text-sm text-gray-700 mt-2 flex-grow">
              {selectedTab?.description ||
                `Explore ${
                  selectedTab?.name || router?.query?.category
                } based templates`}
            </p>{" "}
            <SearchView
              handleLayoutToggle={handleLayoutToggle}
              layout={layout}
            />
          </div>
        </>
      ) : (
        <>
          <PageHeading className="text-3xl">
            {selectedCategory[0].heading}
          </PageHeading>
          <div className=" flex flex-row justify-between w-full items-end">
            <p className="text-sm text-gray-700 mt-2  flex-grow">
              {selectedCategory[0].description}
            </p>
            <SearchView
              handleLayoutToggle={handleLayoutToggle}
              layout={layout}
            />
          </div>
        </>
      )}
    </div>
  )
}
