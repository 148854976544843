import debug from "debug"
import {
  findItemByOrder,
  itemsWithParent,
  excludeItem,
  reOrderItems,
  insertAtIndex,
  hasItems,
  sortItemSlugsByOrder,
} from "./utils"
import { Context } from "overmind"

const d = debug("ChainList/store/checklist/actions/move")

export function placeBefore(
  {
    state: {
      checklist: { itemMap, current, schedules },
    },
  }: Context,
  {
    currentItemSlug,
    targetItemSlug,
  }: { currentItemSlug: string; targetItemSlug: string }
): void {
  if (!hasItems(itemMap)) return

  const item = itemMap[currentItemSlug]
  const target = itemMap[targetItemSlug]

  if (!item || !target) return

  const items = itemsWithParent(Object.values(itemMap), item.parent)

  if (!items?.length) return

  let targetLevel = itemsWithParent(items, target.parent)

  // changeItemOrder(items, item.slug, target.order - 1)
  // Remove the item from this level, if present.
  targetLevel = excludeItem(targetLevel, item.slug)

  // Re-order the items
  targetLevel = reOrderItems(targetLevel)

  // Insert item at position (index or before/after item)
  const newIndex = targetLevel.indexOf(target)
  targetLevel = insertAtIndex(targetLevel, item, newIndex)

  // Re-order one more time
  targetLevel = reOrderItems(targetLevel)

  // Re-sort scheduls and unscheduled Items
  current.unscheduleItems.sort(sortItemSlugsByOrder(itemMap))
  schedules.sort(sortItemSlugsByOrder(itemMap))
}

export function placeAfter(
  {
    state: {
      checklist: { itemMap, current, schedules },
    },
  }: Context,
  {
    currentItemSlug,
    targetItemSlug,
  }: { currentItemSlug: string; targetItemSlug: string }
): void {
  if (!hasItems(itemMap)) return

  const item = itemMap[currentItemSlug]
  const target = itemMap[targetItemSlug]

  if (!item || !target) return

  // Find the level where we are inserting the item (could be a
  // different level than the item was currently at, like in the case
  // of un-indenting).
  let targetLevel = itemsWithParent(Object.values(itemMap), target.parent)

  // Remove item from current position so we can reposition.
  item.parent = target.parent

  if (!targetLevel?.length) return

  // Remove the item from this level, if present.
  targetLevel = excludeItem(targetLevel, item.slug)

  // Re-order the items
  targetLevel = reOrderItems(targetLevel)

  // Insert item at position (index or before/after item)
  const newIndex = targetLevel.indexOf(target) + 1
  targetLevel = insertAtIndex(targetLevel, item, newIndex)

  // Re-order one more time
  targetLevel = reOrderItems(targetLevel)

  // Re-sort scheduls and unscheduled Items
  current.unscheduleItems.sort(sortItemSlugsByOrder(itemMap))
  schedules.sort(sortItemSlugsByOrder(itemMap))
}

export function moveUp(
  {
    actions: {
      checklist: { placeBefore },
    },
    state: {
      checklist: { itemMap },
    },
  }: Context,
  slug: string
): void {
  d("move up", slug)

  const item = itemMap[slug]

  // If no item or item is the first item, don't do anything.
  if (!item || item.order === 1) return

  const parentSlug = item.parent
  const items = itemsWithParent(Object.values(itemMap), parentSlug)
  const prev = findItemByOrder(items, item.order - 1)

  if (!prev) return

  d("moving item before:", prev.slug)

  placeBefore({ currentItemSlug: item.slug, targetItemSlug: prev.slug })
}

export function moveDown(
  {
    actions: {
      checklist: { placeAfter },
    },
    state: {
      checklist: { itemMap },
    },
  }: Context,
  slug: string
): void {
  d("move down", slug)

  const item = itemMap[slug]

  // If no item or item is the first item, don't do anything.
  if (!item) return

  const parentSlug = item.parent
  const items = itemsWithParent(Object.values(itemMap), parentSlug)

  if (!items?.length || item.order === items.length) return

  const next = findItemByOrder(items, item.order + 1)

  if (!next) return

  d("moving item after:", next.slug)

  placeAfter({ currentItemSlug: item.slug, targetItemSlug: next.slug })
}
