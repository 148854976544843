import React from "react"
import { MODAL_TYPES } from "~src/common/constants"
import { Button, Container } from "~src/components"
import { useShowAuthModals } from "~src/hooks/useShowAuthModals"
import { MainLayout } from "~src/layouts"
import { routes } from "~src/routes"

export interface UnauthorizedPromptProps
  extends React.HTMLProps<HTMLDivElement> {
  className?: string
  redirectUrl?: string
  heading?: string
  title?: string
  children?: React.ReactNode
}

export function UnauthorizedPrompt({
  redirectUrl,
  heading,
  title,
}: UnauthorizedPromptProps): JSX.Element {
  const { handleModal } = useShowAuthModals()

  return (
    <MainLayout>
      <Container width="md">
        <div className="border border-gray-300 shadow-md px-10 py-8 rounded-md my-12">
          <h1 className="page-heading mb-6">{heading}</h1>
          <p>{title}</p>
          <div className="mt-8 flex-none sm:flex sm:flex-row items-center">
            <div className="md:mr-4 flex-1">
              <Button
                fullWidth
                onClick={() => handleModal(MODAL_TYPES.login)}
                variant="secondary"
              >
                Log In
              </Button>
            </div>
            <div className="mt-6 sm:mt-0 sm:ml-4 flex-1">
              <Button
                fullWidth
                onClick={() => handleModal(MODAL_TYPES.signup)}
              >
                Sign Up
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </MainLayout>
  )
}
