import { createOvermind, Overmind } from "overmind"
import { Provider } from "overmind-react"
import React from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import { RunSlugScrollContextProvider } from "~src/context/RunSlugScroll"
import { config } from "~src/store"

const defaultStore = createOvermind(config)
const queryClient = new QueryClient()

export interface WrapperProps {
  children: React.ReactNode
  store?: Overmind<typeof config>
}

export function Wrapper({
  children,
  store = defaultStore,
}: WrapperProps): JSX.Element {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider value={store}>
        <RunSlugScrollContextProvider>
          {children}  
        </RunSlugScrollContextProvider>
        </Provider>
    </QueryClientProvider>
  )
}
