import React from "react"
import {
  ForgotPasswordRequestSuccess,
  ForgotPasswordForm,
} from "~src/components"
import { useActions } from "~src/store"

export function ForgotPassword(): JSX.Element {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [submittedEmail, setSubmittedEmail] = React.useState<string>("")
  const {
    auth: { forgotPassword },
  } = useActions()

  async function handleSubmit(values: any): Promise<void> {
    setLoading(true)
    try {
      await forgotPassword(values.email)
      setLoading(false)
      setSubmittedEmail(values.email)
    } catch (err) {
      setLoading(false)

      // FIXME: Don't show error if user not found.
      // Hack until #800 is resolved.
      if (err.toString().toLowerCase().includes("user not found")) {
        setSubmittedEmail(values.email)
        return
      }

      console.error("Error while requesting Reset password", err)
      alert("An error occurred resetting your password: " + err.toString())
    }
  }

  return (
    <>
      {submittedEmail ? (
        <ForgotPasswordRequestSuccess email={submittedEmail} />
      ) : (
        <ForgotPasswordForm isSubmitting={loading} onSubmit={handleSubmit} />
      )}
    </>
  )
}
