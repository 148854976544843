import React from "react"
import { MODAL_TYPES } from "~src/common/constants"
import { useStore } from "~src/store"

interface hookProps {
  handleModal: (
    modalType: string,
    action?: { key: string; description?: string }
  ) => void
}

export function useShowAuthModals(): hookProps {
  const {
    state: {
      auth: { showLoginModal, showSignupModal, tempAuthResponse },
    },
    actions: {
      auth: { setAuthModalVisibility, setAuth },
    },
  } = useStore()

  React.useEffect(() => {
    const closeModal = (e: any) => {
      if (e.key === "Escape") {
        if (showLoginModal) {
          setAuthModalVisibility({
            showModal: !showLoginModal,
            modalType: MODAL_TYPES.login,
          })
        }

        if (showSignupModal) {
          setAuthModalVisibility({
            showModal: !showSignupModal,
            modalType: MODAL_TYPES.signup,
          })
        }
      }
    }

    window.addEventListener("keydown", closeModal)

    return () => window.removeEventListener("keydown", closeModal)
  }, [])

  const handleModal = (
    modalType: string,
    action: { key: string; description: string }
  ) => {
    if (modalType === MODAL_TYPES.login) {
      setAuthModalVisibility({
        showModal: !showLoginModal,
        modalType,
        action,
      })
    }

    if (modalType === MODAL_TYPES.signup) {
      setAuthModalVisibility({
        showModal: !showSignupModal,
        modalType,
        action,
      })
    }

    if (tempAuthResponse) {
      setAuth(tempAuthResponse)
      const event = new Event("userRegistered")
      window.dispatchEvent(event)
    }
  }

  return {
    handleModal,
  }
}
