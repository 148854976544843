import React from "react"
import { UnScheduleItems } from "./UnScheduleItems"
import { ScheduleItems } from "./ScheduleItems"
import { AddAiGeneratedItemsProps } from "./AddAiGeneratedItems"
interface Props extends AddAiGeneratedItemsProps{}

export function ListItem({handleGenerateItems,loading}:Props): JSX.Element {
  return (
    <>
      <UnScheduleItems 
      handleGenerateItems={handleGenerateItems}
      loading={loading}
      />
      <div className="mt-12">
        <ScheduleItems />
      </div>
    </>
  )
}
