import { Context } from "overmind"
import debug from "debug"

const d = debug("ChainList/store/checklist/actions/selection")

export function textSelected(
  { state }: Context,
  { id, text }: { id: string; text: string }
): void {
  d("text selected:", id, text)
}
