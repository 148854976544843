import { useRouter } from "next/router"
import React from "react"
import InfiniteScroll from "react-infinite-scroll-component"

import { COMMUNITY_PAGINATION_LIMIT } from "~src/common/constants"
import { ChecklistService, IChecklistParams } from "~src/services"
import { Loading } from "~src/components"

import { communityChecklistViewProps } from "./community-checklist-view.types"

import { GridView } from "../grid-view"
import { ListView } from "../list-view"
import { ChecklistNotFound } from "../../ChecklistNotFound"

export function CommunityChecklistView({
  checklistData,
  layout,
  categories,
  isSearchResultOpened,
  search,
}: communityChecklistViewProps): JSX.Element {
  const router = useRouter()

  const [checklists, setChecklists] = React.useState<Checklist[]>(checklistData)
  const [page, setPage] = React.useState<number>(1)
  const [loading, setLoading] = React.useState<boolean>(false)

  const currentCategory = categories?.find(
    (c) => c.slug === router?.query?.category
  )

  React.useEffect(() => {
    setChecklists(checklistData)
    setPage(1)
  }, [checklistData])

  const getChecklists = async () => {
    setLoading(true)
    const currentPage = page + 1
    const params: IChecklistParams = {
      page: currentPage,
      limit: COMMUNITY_PAGINATION_LIMIT,
    }

    if (router.asPath.includes("explore")) {
      params.verifiedOnly = true
    }

    if (currentCategory) {
      params.categoryId = currentCategory.id
    }

    const moreChecklists = await ChecklistService.all(params)
    setChecklists([...checklists, ...moreChecklists])
    setPage(currentPage)
    setLoading(false)
  }

  return (
    <div className="max-w-7xl mx-auto px-1 pt-7">
      {!checklists?.length ? (
        <ChecklistNotFound search={search} />
      ) : (
        <InfiniteScroll
          dataLength={checklists.length}
          hasMore
          loader={loading && <Loading />}
          next={getChecklists}
          scrollThreshold={isSearchResultOpened ? 3 : 0.9}
          style={{ overflow: "hidden" }}
        >
          {Boolean(checklists?.length) &&
            (layout === "grid" ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-9 px-4 xl:px-0">
                {checklists.map((item) => (
                  <GridView data={item} key={item.id} />
                ))}
              </div>
            ) : (
              <div className="px-4 xl:px-0">
                {checklists.map((item, index) => (
                  <ListView
                    data={item}
                    isFirstChild={index === 0}
                    isLastChild={index === checklists.length - 1}
                    key={item.id}
                  />
                ))}
              </div>
            ))}
        </InfiniteScroll>
      )}
    </div>
  )
}
