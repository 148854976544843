import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
  EditorState,
  Modifier,
  RichUtils,
  SelectionState,
} from "draft-js"
import draftToHtml from "draftjs-to-html"
import striptags from "striptags"
import TurndownService from "turndown"
import { ALLOWED_EDITOR_STYLES } from "~src/common/constants"
import { cleanBody, isServerRendered } from "~src/common/lib"
import { Link } from "~src/components/Editor/Link"

const turndownService = new TurndownService()

turndownService.addRule("strikethrough", {
  filter: ["del", "s"],
  replacement: (content) => "~~" + content + "~~",
})

export class EditorHelper {
  public static emptyContent = convertFromRaw({
    entityMap: {},
    blocks: [
      {
        text: "",
        key: "editor",
        type: "unstyled",
        entityRanges: [],
        inlineStyleRanges: [],
        depth: 0,
      },
    ],
  })
  public static bold(editorState: EditorState): EditorState {
    return this.applyInlineStyle(editorState, "BOLD")
  }

  public static italic(editorState: EditorState): EditorState {
    return this.applyInlineStyle(editorState, "ITALIC")
  }

  public static strikethrough(editorState: EditorState): EditorState {
    return this.applyInlineStyle(editorState, "STRIKETHROUGH")
  }

  public static code(editorState: EditorState): EditorState {
    return this.applyInlineStyle(editorState, "CODE")
  }

  public static clearFormatting(editorState: EditorState): EditorState {
    const contentWithoutStyles = ALLOWED_EDITOR_STYLES.reduce(
      (newContentState, style) =>
        Modifier.removeInlineStyle(
          newContentState,
          editorState.getSelection(),
          style
        ),
      editorState.getCurrentContent()
    )
    return EditorState.push(
      editorState,
      contentWithoutStyles,
      "change-inline-style"
    )
  }

  public static link(editorState: EditorState, url: string): EditorState {
    const contentState = editorState.getCurrentContent()
    const contentStateWithEntity = contentState.createEntity(
      "LINK",
      "MUTABLE",
      {
        url,
      }
    )
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    const contentStateWithLink = Modifier.applyEntity(
      contentStateWithEntity,
      editorState.getSelection(),
      entityKey
    )
    // const newEditorState = EditorState.push(
    //   editorState,
    //   contentStateWithLink,
    //   "change-block-type"
    // )
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithLink,
    })

    return newEditorState
  }

  public static unlink(editorState: EditorState): EditorState {
    return RichUtils.toggleLink(editorState, editorState.getSelection(), null)
  }

  public static clearSelection(editorState: EditorState): EditorState {
    return EditorState.forceSelection(
      editorState,
      SelectionState.createEmpty(
        editorState?.getCurrentContent().getLastBlock().getKey()
      )
    )
  }

  public static handleKeyCommand(
    editorState: EditorState,
    command: string
  ): EditorState {
    return RichUtils.handleKeyCommand(editorState, command)
  }

  public static currentLinkURL(editorState: EditorState): string {
    const contentState = editorState.getCurrentContent()
    const selection = editorState.getSelection()
    const startKey = selection.getStartKey()
    const startOffset = selection.getStartOffset()
    const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey)
    const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset)
    let url = ""
    if (linkKey) {
      const linkInstance = contentState.getEntity(linkKey)
      url = linkInstance.getData().url
    }
    return url
  }

  public static hasSelectedText(editorState: EditorState): boolean {
    return (
      editorState &&
      editorState.getSelection().getEndOffset() -
        editorState.getSelection().getStartOffset() >
        0
    )
  }

  public static cursorOverLink(editorState: EditorState): boolean {
    return editorState && RichUtils.currentBlockContainsLink(editorState)
  }

  public static htmlFromEditor(editorState: EditorState): string {
    const raw = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    ).trim()
    return cleanBody(raw)
  }

  /**
   * Create a new Draft editor instance from HTML
   */
  public static editorFromHTML(
    html: string,
    cursorAtEnd?: boolean
  ): EditorState {
    if (isServerRendered()) {
      return EditorState.createWithContent(EditorHelper.emptyContent)
    }

    const blocksFromHTML = convertFromHTML(html)
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    )
    const decorator = new CompositeDecorator([
      {
        strategy: findLinkEntities,
        component: Link,
      },
    ])
    const editorState = EditorState.createWithContent(contentState, decorator)
    return cursorAtEnd ? EditorHelper.moveCursorToEnd(editorState) : editorState
  }

  public static htmlToMarkdown(html: string): string {
    return turndownService.turndown(cleanBody(html))
  }

  public static getCurrentContentTextLength(editorState: EditorState): number {
    return this.getCurrentContentText(editorState).length
  }

  public static getCurrentContentText(editorState: EditorState): string {
    return striptags(EditorHelper.htmlFromEditor(editorState))
  }

  private static applyInlineStyle(
    editorState: EditorState,
    style: string
  ): EditorState {
    return RichUtils.toggleInlineStyle(editorState, style)
  }

  public static moveCursorToEnd(editorState: EditorState) {
    const content = editorState.getCurrentContent()
    const blockMap = content.getBlockMap()
    const key = blockMap.last().getKey()
    const length = blockMap.last().getLength()
    const selection = new SelectionState({
      anchorKey: key,
      anchorOffset: length,
      focusKey: key,
      focusOffset: length,
    })
    return EditorState.forceSelection(editorState, selection)
  }

  public static createEmptyState() {
    const decorator = new CompositeDecorator([
      {
        strategy: findLinkEntities,
        component: Link,
      },
    ])
    return EditorState.createWithContent(EditorHelper.emptyContent, decorator)
  }
}

function findLinkEntities(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
): void {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity()
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    )
  }, callback)
}
