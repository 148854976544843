import React from "react"
import { Alert, Link } from "~src/components"
import { routes } from "~src/routes"

export interface ForgotPasswordRequestSuccessProps {
  email: string
}

export function ForgotPasswordRequestSuccess({
  email,
}: ForgotPasswordRequestSuccessProps): JSX.Element {
  return (
    <>
      <p className="my-6">
        If a ChainList account exists for <strong>{email}</strong>, you will
        receive a password reset email shortly. Please click the link in the
        email to reset your password.
      </p>
      <p className="my-6">
        Once you get your email, please{" "}
        <Link href={routes.generic.community()}>login</Link>.
      </p>
    </>
  )
}
