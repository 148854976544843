import {
  ElementRef,
  MutableRefObject,
  createContext,
  useContext,
  useRef,
  useState,
} from "react"

type State = {
  itemFound: boolean
  scrollSlug: string | undefined
  ref: MutableRefObject<HTMLDivElement>
  updateItemFound: (value: boolean) => void
  updateScrollSlug: (value: string) => void
  run: ChecklistRun | null
  updateRun: (run: ChecklistRun) => void
}
const initialState: State = {
  itemFound: false,
  scrollSlug: undefined,
  ref: null,
  run: null,
  updateItemFound(value) {},
  updateScrollSlug(value) {},
  updateRun(run) {},
}

const RunSlugScrollContext = createContext(initialState)

export const RunSlugScrollContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const itemRef = useRef<ElementRef<"div">>(null)
  const [itemFound, setItemFound] = useState(false)
  const [scrollSlug, setScrollSlug] = useState<string | undefined>()
  const [run, setRun] = useState(null)
  const updateItemFound = (value: boolean) => setItemFound(value)
  const updateScrollSlug = (value: string) => setScrollSlug(value)
  const updateRun = (run: ChecklistRun) => setRun(run)
  return (
    <RunSlugScrollContext.Provider
      value={{
        itemFound,
        run,
        ref: itemRef,
        scrollSlug,
        updateItemFound,
        updateRun,
        updateScrollSlug,
      }}
    >
      {children}
    </RunSlugScrollContext.Provider>
  )
}

export const useRunSlugScroll = () => useContext(RunSlugScrollContext)
