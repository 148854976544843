import * as React from "react"
import { useStore } from "~src/store"
import Link from "next/link"
import eyeOff from "~public/icons/eyeoff.svg"
import { Avatar, Tooltip, CommentsForm } from "~src/components"
import { CommentOptions } from "./CommentOptions"
import dayjs from "dayjs"

import relativeTime from "dayjs/plugin/relativeTime"
import { ChecklistService } from "~src/services"
import { useRouter } from "next/router"
import { errorReporter, getQueryValue } from "~src/common/lib"

dayjs.extend(relativeTime)
interface commentCardProps {
  comment: UserComment
  isTemplateOwner?: boolean
  handleTrashedMap?: (value: string) => void
  textAreaRef?: React.MutableRefObject<HTMLTextAreaElement>
}

export function CommentCard({
  comment,
  isTemplateOwner,
  handleTrashedMap,
  textAreaRef,
}: commentCardProps): JSX.Element {
  const {
    state: {
      auth: { user },
    },
    actions: {
      checklist: { editComment },
    },
  } = useStore()

  const router = useRouter()
  const templateSlug = getQueryValue(router.query.slug)
  const activeComment = getQueryValue(router.query.id)

  const isCommentOwner = user?._id === comment.user._id

  const [edit, setEdit] = React.useState<boolean>(false)
  const [commentContent, setCommentContent] = React.useState<string>(
    comment.content
  )

  const [updatedAt, setUpdatedAt] = React.useState(comment.updatedAt)
  const [hidden, setHidden] = React.useState(comment.hide || false)

  const handleSetEdit = () => {
    setEdit(true)
    editComment(true)
  }

  const handleResetEdit = () => {
    setEdit(false)
    editComment(false)
  }

  const handleUpdateCommentState = (
    updatedContent: string,
    updatedAt: Date
  ) => {
    setCommentContent(updatedContent)
    setUpdatedAt(updatedAt)
  }

  const handleDeleteComment = async () => {
    try {
      const response = await ChecklistService.deleteComment(
        templateSlug,
        comment.id
      )

      if (response) {
        response.trashedAt = new Date()
        handleTrashedMap(response.id)
      }
    } catch (error) {
      errorReporter.notify(error)
    }
  }

  const handleCommentVisibility = async () => {
    try {
      const response = await ChecklistService.updateCommentVisibility(
        templateSlug,
        comment.id,
        !hidden
      )

      if (response) {
        setHidden(response.hide)
      }
    } catch (error) {
      errorReporter.notify(error)
    }
  }
  const commentRef = React.useRef(null)
  const isFocused = comment.id === activeComment
  const [activeBackgroundColor, setActiveBackgroundColor] = React.useState(
    "bg-grey-100"
  )
  React.useEffect(() => {
    if (isFocused) {
      commentRef.current?.focus()
      commentRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      })
      setActiveBackgroundColor("bg-red-200")
      const timeoutId = setTimeout(() => {
        setActiveBackgroundColor("bg-gray-100")
        clearTimeout(timeoutId)
      }, 1000)
    }
  }, [isFocused, router])

  if (edit) {
    return (
      <CommentsForm
        commentId={comment.id}
        commentTxt={commentContent}
        handleResetEdit={handleResetEdit}
        handleUpdateCommentState={handleUpdateCommentState}
        textAreaRef={textAreaRef}
      />
    )
  }

  return (
    <div className="relative ">
      <div
        className={`flex pb-6  ${hidden ? `opacity-30` : `opacity-100`}`}
        key={comment.id}
      >
        <div className="mt-3">
          <Link href={"/" + comment.user.username}>
            <Avatar
              avatarUrl={comment.user.avatar.smallMedium.url}
              className=" cursor-pointer"
              size={24}
            />
          </Link>
        </div>

        <div
          className={` ${activeBackgroundColor} transition-colors    p-4 rounded ml-3 w-full break-all`}
          ref={commentRef}
        >
          <div className="space-x-2 mb-3 flex justify-between">
            <div>
              <Link href={"/" + comment.user.username}>
                <span className="font-bold text-xs text-black mr-1 cursor-pointer">
                  {comment.user.username}
                </span>
              </Link>

              <span className="font-normal text-xs text-gray-500">
                · {dayjs(updatedAt).fromNow()}
              </span>
            </div>
          </div>
          <span className="font-normal text-sm  text-black">
            {commentContent}
          </span>
        </div>
      </div>
      <div className="flex items-center gap-4 absolute top-4 right-4">
        {hidden && (
          <Tooltip tooltipTitle="Comment hidden">
            <img alt="Eye Off" src={eyeOff} />
          </Tooltip>
        )}

        <CommentOptions
          handleCommentVisibility={handleCommentVisibility}
          handleDeleteComment={handleDeleteComment}
          handleSetEdit={handleSetEdit}
          hidden={hidden}
          isCommentOwner={isCommentOwner}
          isTemplateOwner={isTemplateOwner}
        />
      </div>
    </div>
  )
}
