import { Context } from "overmind"

export function isSearchResultOpened(
  { state }: Context,
  values: {
    isSearchResultOpened: boolean
    disableOuterScroll?: boolean
  }
): void {
  if (values.isSearchResultOpened && values.disableOuterScroll) {
    document.getElementById("main-wrapper").style.overflow = "hidden"
  } else {
    document.getElementById("main-wrapper").style.overflow = ""
  }

  state.system.isSearchResultOpened = values.isSearchResultOpened
}
