import debug from "debug"
import React from "react"
import { FormattingButtons } from "~src/components/Editor/FormattingButtons"
import { LinkForm } from "~src/components/Editor/LinkForm"
import { DraftInlineStyle } from "draft-js"

const d = debug("bonsai/components/Editor/Toolbar")

export interface ToolbarProps {
  // State
  itemSlug: string
  currentLinkURL: string
  hasSelectedText: boolean
  cursorOverLink: boolean
  shouldShowToolbar: boolean
  shouldShowLinkEditor: boolean
  currentInlineStyle: DraftInlineStyle
  focused: boolean
  variant?: "description" | "title"
  // Actions
  showToolbar: () => void
  hideToolbar: () => void
  showLinkEditor: () => void
  hideLinkEditor: () => void
  link: (url: string) => void
  unlink: () => void
  bold: () => void
  italic: () => void
  strikethrough: () => void
  code: () => void
  clearFormatting: () => void
}

export function Toolbar(props: ToolbarProps): JSX.Element {
  const showEditor = props.shouldShowToolbar || props.shouldShowLinkEditor
  if (!props.focused || !showEditor) return <></>

  const wrapperClasses = "absolute top-0 left-0 right-0 z-30"
  const containerClasses =
    "flex flex-row items-center rounded border border-gray-400 bg-white p-2 overflow-x-auto"

  return (
    <div
      className={wrapperClasses}
      style={{ marginTop: "-3.5rem" }}
    >
      <div className={containerClasses}>
        {props.shouldShowLinkEditor && <LinkForm {...props} />}
        {props.shouldShowToolbar && <FormattingButtons {...props} />}
      </div>
    </div>
  )
}
