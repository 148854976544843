import React from "react"
import info from "~public/icons/Info.svg"
import cancel from "~public/icons/cancel.svg"
import styles from "./NotSignedUpToolTip.module.css"
import { useRouter } from "next/router"
import { SINGUP_TOOLTIP } from "~src/common/constants"
import { useActions, useState, useStore } from "~src/store"

export function NotSignedUpToolTip(): JSX.Element {
  const router = useRouter()
  const {
    system: { showSignupTooltip },
  } = useState()

  const {
    system: { showTooltip },
  } = useActions()

  React.useEffect(() => {
    if (window) {
      const value = localStorage.getItem(SINGUP_TOOLTIP)
      showTooltip(value === "true")
    }
  }, [router])
  const close = () => {
    localStorage.setItem(SINGUP_TOOLTIP, "false")
    showTooltip(false)
  }

  if (
    router.pathname.indexOf(`/[username]/[slug]`) === -1 ||
    !showSignupTooltip
  ) {
    return null
  }

  return (
    <div
      className={`bg-white absolute
        top-12 right-0 min-w-max p-4 rounded-md ${styles.shadow}`}
    >
      <div className="flex flex-col gap-3">
        <div className="flex items-center">
          <img alt="info" src={info} />
          <h3 className="font-semibold flex-grow ml-2.5">
            You are not signed up yet
          </h3>
          <img
            alt="cancel"
            className="cursor-pointer"
            onClick={close}
            src={cancel}
          />
        </div>
        <p className="max-w-sm text-13px">
          With a ChainList account, you can save your subscribed checklists in
          your account, create your own checklists and add checklists to your
          favorites.
        </p>
      </div>

      <div className={styles.arrowUp}></div>
    </div>
  )
}
