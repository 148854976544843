import React from "react"
import { Button, Input } from "~src/components"

interface FileMenuProps {
  handleFileUrl: (url: string) => void
  error?: string
}

export function FileMenu({ handleFileUrl, error }: FileMenuProps): JSX.Element {
  const [link, setLink] = React.useState<string>("")

  return (
    <div className="flex flex-col items-center py-6 flex-shrink-0">
      <div className="w-11/12 mb-4">
        <Input
          className="h-12"
          innerPlaceholder="Paste an image link"
          onChange={(e: React.KeyboardEvent<HTMLInputElement>) =>
            setLink(e.currentTarget.value)
          }
          value={link}
        />
      </div>

      <Button disabled={!link.length} onClick={() => handleFileUrl(link)}>
        <span className="text-xs font-semibold">Submit</span>
      </Button>

      {error && (
        <p className="font-normal text-xs text-red-600 my-2 text-center max-w-xs ">
          {error}
        </p>
      )}

      <p className="text-xs font-normal text-gray2 py-2">
        Allowed formats: JPG, PNG
      </p>
    </div>
  )
}
