import Honeybadger from "@honeybadger-io/js"
import { APP_URL } from "~src/common/constants"
import { isServerRendered } from "./isServerRendered"

export interface ErrorReporter {
  notify(error: Error | string, context?: GenericObject): void
  addContext(context: GenericObject): void
}

/**
 * The ErrorManager allows us to report to multiple error reporting services
 * if we want to without changing the interface in application code.
 *
 * Do not directly use the specific error reporters; instead use this
 * ErrorManager class instance instead.
 */
export class ErrorManager implements ErrorReporter {
  constructor(private reporters: ErrorReporter[]) {
    this.reporters = reporters

    const context: GenericObject = {}

    // Add extra context browser specific to report.
    if (!isServerRendered()) {
      context.url = window?.location?.href
    }

    this.addContext({
      ...context,
      env: process.env.NODE_ENV,
      origin: APP_URL,
    })
  }

  public notify = (error: Error | string, context?: GenericObject): void => {
    this.reporters.map((r) => {
      if (process.env.NODE_ENV !== "test") console.error(error)
      r.notify(error, context)
    })
  }

  public addContext = (context: GenericObject): void => {
    this.reporters.map((r) => r.addContext(context))
  }
}

/**
 *
 * Node: https://docs.honeybadger.io/lib/node.html#configuration
 * Browser: https://docs.honeybadger.io/lib/javascript/reference/configuration.html
 */
export class HoneybadgerErrorReporter implements ErrorReporter {
  public notify(err: Error | string, context?: GenericObject): void {
    this.addContext(context)
    Honeybadger.notify(err)
  }

  public addContext(context: GenericObject): void {
    Honeybadger.setContext(context)
  }
}

export const errorReporter = new ErrorManager([new HoneybadgerErrorReporter()])
