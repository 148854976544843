import dayjs, { Dayjs } from "dayjs"
import { DateHelper } from "@bonsaichecklist/bonsai-utils"
import { RecurringRule } from "."
import _ from "lodash"

export interface CancelledOccurrence {
  cancelledAt: any
  cancelledOccurrence: any
}

export interface ScheduleConfig {
  frequency?: RecurringFrequency
  recurrencePattern?: RECURRENCE_PATTERNS
  interval?: number
  byWeekday?: Weekdays[]
  byMonthday?: number[]
  weekOfMonth?: number
  recurring?: boolean
  startedAt?: Date
  cancelledRuns?: CancelledOccurrence[]
  until?: Date
  weekday?: string[] | string
}

export type RECURRENCE_PATTERNS =
  | "single-occurrence"
  | "minutely"
  | "daily"
  | "weekly"
  | "bi-weekly"
  | "weekday"
  | "weekend"
  | "nth-day-of-month"
  | "weekday-of-month"
  | "quarterly-weekday-of-month"
  | "semiyearly-weekday-of-month"
  | "yearly"
  | "custom"

export interface UpcomingRun extends Schedule {
  runAt: Date
}

export type FriendlyWeekday =
  | "Monday"
  | "Tuesday"
  | "Wednesday"
  | "Thursday"
  | "Friday"
  | "Saturday"
  | "Sunday"

export class ScheduleHelper {
  constructor(public schedule: Schedule) {
    this.schedule = schedule
  }

  static getScheduleConfig({
    startedAt,
    until,
    pattern,
  }: {
    startedAt: Date
    until?: Date
    pattern: RECURRENCE_PATTERNS
  }): ScheduleConfig {
    // Configured the schedule run with the basic details.
    const schedule: ScheduleConfig = {
      startedAt,
      recurring: pattern !== "single-occurrence",
    }
    if (until) schedule.until = until
    // Get the weekday in the format the API expects.
    const weekday = DateHelper.weekday(startedAt)
      .toUpperCase()
      .slice(0, 2) as Weekdays
    schedule.weekday = weekday
    switch (pattern) {
      case "minutely":
        schedule.interval = 1
        schedule.frequency = "minutely"
        break
      case "daily":
        schedule.interval = 1
        schedule.frequency = "weekly"
        schedule.byWeekday = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"]
        break
      case "weekly":
        schedule.interval = 1
        schedule.frequency = "weekly"
        schedule.byWeekday = [weekday]
        break
      case "bi-weekly":
        schedule.interval = 2
        schedule.frequency = "weekly"
        schedule.byWeekday = [weekday]
        break
      case "weekday":
        schedule.interval = 1
        schedule.frequency = "weekly"
        schedule.byWeekday = ["MO", "TU", "WE", "TH", "FR"]
        break
      case "weekend":
        schedule.interval = 1
        schedule.frequency = "weekly"
        schedule.byWeekday = ["SA", "SU"]
        break
      case "nth-day-of-month":
        // e.g. every 2nd Thursday of the month
        schedule.interval = 1
        schedule.weekOfMonth = DateHelper.getXthDayOfMonth(startedAt)
        schedule.byWeekday = [weekday]
        schedule.frequency = "monthly"
        break
      case "weekday-of-month":
        // e.g. the 12th of every month
        schedule.interval = 1
        schedule.frequency = "monthly"
        schedule.byWeekday = [weekday]
        break
      case "quarterly-weekday-of-month":
        // e.g. the 12th of every 3 month
        schedule.interval = 3
        schedule.frequency = "monthly"
        schedule.byWeekday = [weekday]
        break
      case "semiyearly-weekday-of-month":
        // e.g. the 12th of every 6 month
        schedule.interval = 6
        schedule.frequency = "monthly"
        schedule.byMonthday = [DateHelper.dayOfMonth(startedAt)]
        break
      case "yearly":
        schedule.interval = 1
        schedule.frequency = "yearly"
        break
      default:
        break
    }
    return schedule
  }

  WEEKDAY_MAPPING: { [key in Weekdays]: FriendlyWeekday } = {
    MO: "Monday",
    TU: "Tuesday",
    WE: "Wednesday",
    TH: "Thursday",
    FR: "Friday",
    SA: "Saturday",
    SU: "Sunday",
  }

  get next(): UpcomingRun | void {
    const {
      cancelAll,
      recurrencePattern,
      customOptions,
      recurring,
      interval,
      startedAt,
      until,
      byMonthday,
      byWeekday,
      weekOfMonth,
      trashedAt,
    } = this.schedule
    if (cancelAll || trashedAt) return
    if (!recurring) {
      if (dayjs(startedAt).isBefore(new Date())) return
      return { ...this.schedule, runAt: startedAt }
    }

    return (
      // Get a rrule instance for the given schedule
      RecurringRule.createRRuleInstance({
        recurrencePattern,
        customOptions,
        interval,
        startedAt,
        until,
        byMonthday,
        byWeekday,
        weekOfMonth,
      })
        // Get all the upcoming occurrences for the next 6 month
        .all((_, i) => i < 10)
        .filter(this.filterCancelled)
        .map((date) => ({ ...this.schedule, runAt: date }))[0]
    )
  }

  upcoming(
    conf: {
      count?: number
      period?: dayjs.OpUnitType
    } = {}
  ): Date[] {
    let { startedAt, until, trashedAt } = this.schedule

    const {
      cancelAll,
      cancelledOccurrences,
      recurrencePattern,
      customOptions,
      recurring,
      interval,
      byMonthday,
      byWeekday,
      weekOfMonth,
    } = this.schedule

    conf = {
      count: 6,
      period: recurrencePattern === "yearly" ? "year" : "month",
      ...conf,
    }

    until = typeof until === "string" ? dayjs(until).toDate() : until
    startedAt =
      typeof startedAt === "string" ? dayjs(startedAt).toDate() : startedAt
    trashedAt =
      typeof trashedAt === "string" ? dayjs(trashedAt).toDate() : trashedAt

    if (!this.schedule || cancelAll || trashedAt) return []

    // console.log(trashedAt,"trashedAt")

    // Set "now" to be in the past as when we call this there may be
    // a race condition where the startAt could be a few MS before/at/after
    // when this is run, causing it to non-predictably be added or omitted from
    // the results. Instead, let's just consider "now" to be a little bit in the past
    // so that if the run is "now" it will always be included.
    // const now = dayjs(Date.now()).toDate()
    const now = dayjs(Date.now()).subtract(10, "second").toDate()

    // For single occurrences, return early.
    if (!recurring || !recurrencePattern) {
      if (dayjs(startedAt).isBefore(now)) return []
      return [new Date(startedAt)]
    }

    const formatDate = (date: Date | string) => dayjs(date).format("DD-MM-YYYY")

    const formatDateUnix = (date: Date | string) => {
      const formattedDate = dayjs(date).format("YYYY-MM-DD HH:mm")
      return dayjs(formattedDate).unix()
    }

    const cancelledOccurrencesMap: {
      [x: string]: CanceledRun
    } = cancelledOccurrences.reduce((prevOcc, currentOcc) => {
      return {
        ...prevOcc,
        [formatDate(currentOcc.cancelledOccurrence)]: formatDate(
          currentOcc.cancelledOccurrence
        ),
      }
    }, {})

    let schedulesCount = 10

    const upcomingSchedulesConfig = RecurringRule.createRRuleInstance({
      recurrencePattern,
      customOptions,
      interval,
      startedAt,
      until,
      byMonthday,
      byWeekday,
      weekOfMonth,
    })

    // Get past runs date in unix
    let pastRunDatesUnix: number[] = []
    let pastOccurrenceDatesUnix: number[] = []

    const handlePastRunDates =
      this.schedule?.pastRuns?.length && this.schedule.pastRuns.length > 0

    const handlePastOccurrences =
      this.schedule?.occurrences?.length && this.schedule.occurrences.length > 0

    if (handlePastRunDates) {
      pastRunDatesUnix = this.schedule.pastRuns?.map((pastRun) => {
        return formatDateUnix(pastRun.ranAt)
      })
    }

    if (handlePastOccurrences) {
      pastOccurrenceDatesUnix = this.schedule.occurrences
        ?.filter((occurrence) => occurrence.status !== "scheduled")
        ?.map((occurrence) => {
          return formatDateUnix(occurrence.occurrenceDateTime)
        })
    }

    pastRunDatesUnix = [...pastRunDatesUnix, ...pastOccurrenceDatesUnix]

    // Get all the upcoming occurrences for the next 10 month
    const schedules = upcomingSchedulesConfig.all((_, i) => {
      if (
        cancelledOccurrencesMap[formatDate(_)] ||
        pastRunDatesUnix?.includes(formatDateUnix(_))
      ) {
        ++schedulesCount
      }

      return i < schedulesCount
    })

    const filterCancelledSchedules = schedules.filter(
      (runAt) => !cancelledOccurrencesMap[formatDate(runAt)]
    )

    // Filter past runs in custom recurring
    const upcomingSchedules = this.filterPastSchedules(
      pastRunDatesUnix,
      filterCancelledSchedules
    )

    return upcomingSchedules
      .sort((a, b) => (dayjs(a).isAfter(b) ? 1 : -1))
      .filter((a) => dayjs(a) >= dayjs())
  }

  /**
   * Create a human friendly representation of the given schedule pattern
   * for display in the UI.
   */
  get friendlyDescription(): string {
    const { startedAt, recurrencePattern, until, customOptions } = this.schedule

    if (recurrencePattern === "custom") {
      const rule = RecurringRule.createRRuleInstance(this.schedule)
      return `${_.startCase(rule.toText())}`
    }

    const scheduleConfig = ScheduleHelper.getScheduleConfig({
      pattern: recurrencePattern as RECURRENCE_PATTERNS,
      startedAt,
      until,
    })
    const { byWeekday, byMonthday, weekOfMonth } = scheduleConfig
    const startDate = DateHelper.localeDate(startedAt)
    const dayOfWeek = byWeekday?.length
      ? this.WEEKDAY_MAPPING[byWeekday[0]]
      : null

    const ending =
      this.schedule?.until &&
      `until ${DateHelper.localeDate(this.schedule.until)}`

    switch (this.schedule.recurrencePattern) {
      case "daily":
        return `Every day ${ending || ""}`
      case "weekly":
        return `Every ${dayOfWeek} ${ending || ""}`
      case "weekday":
        return `Every weekday ${ending || ""}`
      case "weekend":
        return `Every weekend ${ending || ""}`
      case "bi-weekly":
        return `Every other ${dayOfWeek} ${ending || ""}`
      case "weekday-of-month":
        return `First ${dayOfWeek} of every month ${ending || ""}`
      case "nth-day-of-month":
        return `The ${weekOfMonth}${DateHelper.dateOrdinal(
          weekOfMonth
        )} ${dayOfWeek} of every month ${ending || ""}`
      case "yearly":
        return `Every year on ${dayjs(startedAt).format("MMMM Do")} ${
          ending || ""
        }`
      case "quarterly-weekday-of-month":
        return `Every 3 Months on first ${dayOfWeek} ${ending || ""}`
      case "semiyearly-weekday-of-month":
        return `Every 6 Months on  ${dayjs(startedAt).format("Do")} ${
          ending || ""
        }`
      default:
        return `On ${startDate}`
    }
  }

  /**
   * Determine what RECURRENCE_PATTERNS a given schedule matches with and return
   * it.
   */
  get pattern(): RECURRENCE_PATTERNS {
    if (!this.schedule) return "single-occurrence"
    const {
      recurring,
      interval,
      frequency,
      byWeekday,
      byMonthday,
      weekOfMonth,
    } = this.schedule
    if (!recurring) return "single-occurrence"

    if (interval === 2 && frequency === "weekly" && byWeekday?.length === 1)
      return "bi-weekly"

    if (
      (interval === 1 && frequency === "daily") ||
      (interval === 1 &&
        frequency === "weekly" &&
        JSON.stringify(byWeekday) ===
          JSON.stringify(["MO", "TU", "WE", "TH", "FR", "SA", "SU"]))
    )
      return "daily"
    if (
      interval === 1 &&
      frequency === "weekly" &&
      JSON.stringify(byWeekday) ===
        JSON.stringify(["MO", "TU", "WE", "TH", "FR"])
    )
      return "weekday"
    if (
      interval === 1 &&
      frequency === "weekly" &&
      JSON.stringify(byWeekday) === JSON.stringify(["SA", "SU"])
    )
      return "weekend"
    if (interval === 1 && frequency === "weekly" && byWeekday?.length)
      return "weekly"
    // e.g. the 12th of every month
    if (interval === 1 && frequency === "monthly" && byMonthday?.length === 1)
      return "weekday-of-month"
    // e.g. every 2nd Thursday of the month
    if (weekOfMonth > 0 && byWeekday?.length === 1 && frequency === "monthly")
      return "nth-day-of-month"
    if (interval === 1 && frequency === "yearly") return "yearly"
    // fallback
    return "single-occurrence"
  }

  private filterCancelled = (runAt: Date): boolean => {
    const { cancelledOccurrences } = this.schedule
    return cancelledOccurrences?.length
      ? !cancelledOccurrences
          .map((c) => dayjs(c.cancelledOccurrence).format("DD-MM-YYYY"))
          .includes(dayjs(runAt).format("DD-MM-YYYY"))
      : true
  }

  private filterPastSchedules(
    pastRunDatesUnix: number[],
    schedules: Date[]
  ): Date[] | [] {
    if (pastRunDatesUnix?.length > 0) {
      schedules = schedules.filter((runAt) => {
        const unixDate = dayjs(dayjs(runAt).format("YYYY-MM-DD HH:mm")).unix()
        return !pastRunDatesUnix.includes(unixDate)
      })
    }

    return schedules
  }

  static getFormatedNotifications(data: {
    monthly?: number
    yearly?: number
  }): string {
    let formatted = ""
    if (data?.monthly) {
      formatted += `${data.monthly} notification${
        data.monthly > 1 ? "s" : ""
      } per month`
    }

    if (data?.yearly) {
      formatted += `${formatted ? " and " : ""}${data.yearly} notification${
        data.yearly > 1 ? "s" : ""
      } per year`
    }

    return formatted ? formatted : "0 notification per month"
  }
}
