import * as qs from "qs"
import { USERS } from "~src/common/apiRoutes"
import { chainListAxios, errorReporter } from "~src/common/lib"
import { AuthResponse, AuthService } from "./AuthService"

export class UserService {
  public static async find(username: string): Promise<Profile | any> {
    const { status, data } = await chainListAxios.get(USERS.find(username), {
      validateStatus: (status) => status >= 200,
    })

    return data
  }

  public static async updateProductTour(
    tour: string,
    skipped?: boolean
  ): Promise<GenericObject> {
    const response = await chainListAxios.put(USERS.updateProductTour(), {
      tour,
      skipped,
    })

    return response?.data
  }

  public static async findStarredChecklists(
    params?: GenericObject,
    headers?: GenericObject
  ): Promise<[StarredChecklistRoot]> {
    const query = qs.stringify({
      username: params.username,
      page: params.page,
      limit: params.limit,
    })
    const response = await chainListAxios.get(
      USERS.starredChecklist(query),
      headers && { headers }
    )
    return response.data
  }

  public static async findSubscribedChecklists(
    params: GenericObject,
    headers?: GenericObject
  ): Promise<[Checklist]> {
    const query = qs.stringify({
      username: params.username,
      page: params.page,
      limit: params.limit,
    })
    const response = await chainListAxios.get(
      USERS.subscribedChecklist(query),
      headers && { headers }
    )
    return response.data
  }

  static async activities(
    page?: number,
    limit?: number
  ): Promise<{
    activities?: ActivityLog[]
    updatedLastActivitySeenAt?: Date | null
    hasNew?: boolean
  }> {
    try {
      const query = qs.stringify({
        page,
        limit,
      })
      const response = await chainListAxios.get(USERS.userActivityLog(query))

      return response.data
    } catch (error) {
      errorReporter.notify(error?.response?.data)
      return {
        activities: [],
        updatedLastActivitySeenAt: null,
        hasNew: false,
      }
    }
  }

  public static async updateInfoDialog(
    data: InfoDialog
  ): Promise<GenericObject> {
    const response = await chainListAxios.patch(USERS.updateInfoDialog(), data)

    return response?.data
  }

  /**
   * ad admin capability to add new template categories
   * **/

  public static async addNewCategory(data: {
    name: string
    heading: string
    description: string
  }): Promise<GenericObject> {
    const response = await chainListAxios.post(USERS.createNewCategory(), data)

    return response?.data
  }

  public static async deleteTheCategory(slug: string): Promise<GenericObject> {
    const response = await chainListAxios.delete(USERS.deleteCategory(slug))

    return response?.data
  }
  public static async editTheCategory(
    data: TemplateCategory
  ): Promise<GenericObject> {
    const response = await chainListAxios.put(
      USERS.updateCategory(data.slug),
      data
    )

    return response?.data
  }
  public static async uploadAvatar(formData: FormData): Promise<GenericObject> {
    const response = await chainListAxios.post(USERS.uploadAvatar(), formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })

    return response?.data
  }
  public static async updateProfile(data: Profile): Promise<AuthResponse> {
    const response = await chainListAxios.put(USERS.updateProfile(), data)
    AuthService.setAuthCookie(response)
    return response?.data
  }
  public static async getAvatar(username: string) {
    const response = await chainListAxios.get(USERS.getAvatar(username))
    return response?.data
  }
}
