import * as React from "react"

import { Avatar } from "../Avatar"
import { TextArea } from "../TextArea"
import { Button } from "../Button"
import { useState } from "~src/store"
import { ChecklistService } from "~src/services"
import { useRouter } from "next/router"
import { errorReporter, getQueryValue } from "~src/common/lib"

interface commentsFormProps {
  textAreaRef?: React.MutableRefObject<HTMLTextAreaElement>
  handleAddComment?: () => void
  commentTxt?: string
  handleResetEdit?: () => void
  handleUpdateCommentState?: (content: string, updatedAt: Date) => void
  commentId?: string
}

export function CommentsForm({
  textAreaRef,
  handleAddComment,
  commentTxt,
  handleResetEdit,
  handleUpdateCommentState,
  commentId,
}: commentsFormProps): JSX.Element {
  const {
    auth: { user },
  } = useState()

  const router = useRouter()
  const templateSlug = getQueryValue(router.query.slug)

  const [txt, setTxt] = React.useState<string>("")

  const handleOnChange = (e: React.FormEvent<HTMLTextAreaElement>): void => {
    setTxt(e.currentTarget?.value)
  }

  const handleUpdateCommentRequest = async () => {
    try {
      const response = await ChecklistService.updateComment(
        templateSlug,
        commentId,
        txt
      )

      handleUpdateCommentState(response.content, response.updatedAt)
      handleResetEdit()
    } catch (error) {
      errorReporter.notify(error)
    }
  }

  React.useEffect(() => {
    if (commentTxt) {
      textAreaRef.current.value = commentTxt
    }
  }, [commentTxt, textAreaRef])

  return (
    <div>
      <div className="flex">
        <div className="mt-3 mr-3">
          <Avatar avatarUrl={user?.avatar?.smallMedium?.url} size={24} />
        </div>
        <TextArea
          className="h-20 resize-none bg-white"
          onChange={handleOnChange}
          placeholder="Add a comment"
          ref={textAreaRef}
          size="sm"
        />
      </div>
      <div className="flex justify-end  mt-4">
        {commentTxt ? (
          <div className="flex items-center gap-5 mb-4">
            <Button
              className="text-xs font-semibold"
              onClick={handleResetEdit}
              variant="secondary"
            >
              Cancel
            </Button>
            <Button
              className="text-xs font-semibold"
              onClick={handleUpdateCommentRequest}
            >
              Save
            </Button>
          </div>
        ) : (
          <Button
            className="text-xs font-semibold"
            disabled={!txt.length || !textAreaRef?.current?.value.length}
            onClick={handleAddComment}
          >
            Comment
          </Button>
        )}
      </div>
    </div>
  )
}
