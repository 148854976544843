import { useRouter } from "next/router"
import Link from "next/link"
import React, { Children } from "react"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function ActiveLink({
  children,
  activeClassName,
  ...props
}: any): JSX.Element {
  const { asPath } = useRouter()
  const child = Children.only(children)
  const childClassName = child.props.className || ""

  // pages/index.js will be matched via props.href
  // pages/about.js will be matched via props.href
  // pages/[slug].js will be matched via props.as

  const className =
    asPath === props.href || asPath === props.as
      ? `${activeClassName}`.trim()
      : `${childClassName}`.trim()

  return (
    <Link {...props}>
      {React.cloneElement(child, {
        className: className || null,
      })}
    </Link>
  )
}
