import React from "react"
import UnScheduleItem from "./UnScheduleItem"

export function UnScheduleItems({
  items,
  expanded,
  unScheduledData,
}: {
  items: ChecklistItem[]
  expanded?: boolean
  unScheduledData: any
}): JSX.Element {
  return (
    <div className="flex flex-col mb-4">
      {items?.map(
        (item: ChecklistItem, i: React.Key): JSX.Element => {
          if (!item?.parent) {
            return (
              <div
                className="block bg-white border border-gray-200 rounded-lg p-3 mb-5 md:w-5xl"
                key={i}
              >
                <div>
                  <UnScheduleItem
                    bold
                    expanded={expanded}
                    item={item}
                    unScheduledData={unScheduledData}
                  />
                </div>
              </div>
            )
          }
          return null
        }
      )}
    </div>
  )
}
