import classnames from "classnames"
import { useRouter } from "next/router"
import React from "react"
import {
  connectHits,
  Highlight,
  Snippet,
  HitsProps as AlgoliaHitsProps,
  connectStateResults,
} from "react-instantsearch-dom"
import { useState } from "~src/store"
import { Link, Loading } from "~src/components"
import { CustomSnippet } from "./CustomSnippet"
import { routes } from "~src/routes"
import styles from "./SearchResults.module.css"

const Results = connectStateResults(
  ({
    searchResults,
    children,
    searching,
  }: {
    searchResults: any
    children: any
    searching: boolean
  }) => {
    if (searching) {
      return (
        <div className="text-gray-600 py-8 px-4 sm:p-6" role="alert">
          <Loading />
        </div>
      )
    }

    return searchResults && searchResults.nbHits !== 0 && !searching ? (
      children
    ) : (
      <div className="text-gray-600 py-8 px-4 sm:p-6" role="alert">
        No results found. 🤔
      </div>
    )
  }
)

interface ChecklistHit {
  objectID: string
  name: string
  slug: string
  description?: string
  owner: {
    objectID: string
    username: string
  }
  visibility: ChecklistVisibility
  subscriptions: number
  isSubscription: boolean
  lastUpdatedAt: string
}

interface HitsProps extends AlgoliaHitsProps<ChecklistHit> {
  hits: ChecklistHit[]
  onClickCallback?: (slug: string) => void
  onClose?: () => void
}

function Hits({ hits, onClose, onClickCallback }: HitsProps) {
  const {
    auth: { user },
    system: { isSideBySideOpened },
  } = useState()

  const container = classnames(styles.container, "overflow-y-auto", "h-32rem")

  let updatedHits: ChecklistHit[] = hits

  let nonOwnerHits
  let ownerHits

  if (user?.username) {
    nonOwnerHits = hits?.filter(
      (hit) =>
        hit.owner.username && hit.owner.username.trim() !== user?.username
    )

    ownerHits = hits?.filter(
      (hit, index) =>
        hit.owner.username && hit.owner.username.trim() === user?.username
    )

    updatedHits = [...ownerHits, ...nonOwnerHits]
  }

  if (isSideBySideOpened) {
    return (
      <div className={container}>
        <Results>
          {nonOwnerHits?.length && (
            <p className="px-6 py-4 font-semibold">
              Results found but not owned by user
            </p>
          )}
          {nonOwnerHits?.length ? (
            <HitResults
              hits={nonOwnerHits}
              onClickCallback={onClickCallback}
              onClose={onClose}
            />
          ) : (
            <div className="text-gray-600 py-8 px-4 sm:p-6" role="alert">
              No results found from other users. 🤔
            </div>
          )}

          {ownerHits?.length && (
            <p className="px-6 py-4 font-semibold">
              Results found but owned by user
            </p>
          )}
          {ownerHits?.length ? (
            <HitResults
              hits={ownerHits}
              onClickCallback={onClickCallback}
              onClose={onClose}
            />
          ) : (
            <div className="text-gray-600 py-8 px-4 sm:p-6" role="alert">
              No results found from user. 🤔
            </div>
          )}
        </Results>
      </div>
    )
  }

  return (
    <div className={container}>
      <Results>
        {updatedHits?.length ? (
          <HitResults
            hits={updatedHits}
            onClickCallback={onClickCallback}
            onClose={onClose}
          />
        ) : (
          <div className="text-gray-600 py-8 px-4 sm:p-6" role="alert">
            No results found. 🤔
          </div>
        )}
      </Results>
    </div>
  )
}

function HitResults({
  hits,
  onClickCallback,
  onClose,
}: {
  hits: ChecklistHit[]
  onClickCallback?: (slug: string) => void
  onClose?: () => void
}): any {
  const router = useRouter()

  function redirect(url: string) {
    router.push(url)
  }

  function handleClick(slug: string, username: string): void {
    if (onClickCallback) {
      onClickCallback(slug)
    } else {
      router.push(routes.checklists.show(slug, username))
    }

    onClose()
  }

  return (
    <div>
      {hits.map((hit, index) => {
        const {
          owner: { username },
          objectID,
          slug,
        } = hit

        const item = classnames(
          "px-6 py-4",
          "rounded-md sm:rounded-none",
          "hover:bg-gray-100"
        )

        return (
          <div className={item} key={objectID}>
            <Link onClick={() => handleClick(slug, username)}>
              <h3 className="text-lg mb-2">
                <Highlight attribute="title" hit={hit} />
              </h3>
            </Link>
            <div className="flex items-center text-xs text-gray-600 break-all">
              <Link onClick={() => handleClick(slug, username)} variant="muted">
                <span>
                  <CustomSnippet attribute="items" hit={hit} />
                </span>
              </Link>
            </div>
            <div className="flex items-center text-xs text-gray-600">
              <Link onClick={() => handleClick(slug, username)} variant="muted">
                <span>
                  <Snippet attribute={"description"} hit={hit} />
                </span>
              </Link>
            </div>
            <div className="flex items-center text-xs text-gray-600">
              <Link
                className="mr-6 mt-3"
                onClick={() => redirect(routes.users.profile(username))}
                variant="muted"
              >
                @<Highlight attribute="owner.username" hit={hit} />
              </Link>
            </div>
          </div>
        )
      })}
    </div>
  )
}

// FIXME: waiting for answer: https://github.com/algolia/react-instantsearch/issues/3007
// @ts-ignore
const CustomHits = connectHits<HitsProps>(Hits)

export interface SearchResultsProps {
  onClose: () => void
}

export function SearchResults({
  onClickCallback,
  onClose,
  isSideBySide = false,
}: {
  onClickCallback?: (slug: string) => void
  onClose: () => void
  isSideBySide?: boolean
}): JSX.Element {
  // FIXME: waiting for answer: https://github.com/algolia/react-instantsearch/issues/3007
  // @ts-ignore
  return <CustomHits onClickCallback={onClickCallback} onClose={onClose} />
}
