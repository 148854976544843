import React from "react"
import { Modal } from "~src/components"

export function KeyboardShortcutModal({
  onClose,
}: {
  onClose: () => void
}): JSX.Element {
  const items = [
    // { keys: ["tab"], desc: "Indent item" },
    // { keys: ["shift", "tab"], desc: "Unindent item" },
    { keys: ["enter"], desc: "Insert item below current item" },
    { keys: ["shift", "enter"], desc: "Insert item above current item" },
    { keys: ["↑"], desc: "Move focus up one item" },
    { keys: ["↓"], desc: "Move focus down one item" },
    { keys: ["shift", "↑"], desc: "Move item up one place" },
    { keys: ["shift", "↓"], desc: "Move item down one place" },
  ]
  return (
    <Modal onClose={onClose} title="Keyboard Shortcuts">
      <p className="mb-4">
        Below is a list of helpful keyboard shortcuts when editing checklists in
        ChainList.
      </p>
      <table>
        <tbody>
          {items.map((item, key) => (
            <tr key={key}>
              <td className="px-2 py-3 border-b border-gray-200">
                {item.keys.map((k, i) => (
                  <kbd className="bg-gray-200 p-1 border mr-2 rounded" key={i}>
                    {k}
                  </kbd>
                ))}
              </td>
              <td className="pl-2 border-b border-gray-200">{item.desc}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Modal>
  )
}
