import React from "react"
import { useDrop, DropTargetMonitor, DragObjectWithType } from "react-dnd"
import classnames from "classnames"
import {
  ChecklistItemsHelper,
  getLinkedListName,
} from "@bonsaichecklist/bonsai-utils"
import { useState } from "~src/store"

import styles from "./ItemDropArea.module.css"

export interface DragObjectItem extends ChecklistItem, DragObjectWithType {}

export type DropPosition = "after" | "before"
export type DropAreaType = "ordering" | "nesting"

export interface onDropParams {
  item: ChecklistItem
  dropAreaItem: ChecklistItem
  parent?: ChecklistItem
  dropIndex: number
  dropPosition: DropPosition
  dropAreaType: DropAreaType
  schedule?: boolean
}

export interface ItemDropAreaProps extends React.HTMLProps<HTMLDivElement> {
  dropAreaItem?: ChecklistItem
  parent?: ChecklistItem
  onDropEnd: (params: onDropParams) => void
  dropIndex: number
  dropPosition: DropPosition
  dropAreaType: DropAreaType
  schedule?: boolean
}

export function ItemDropArea({
  dropAreaItem,
  parent,
  onDropEnd,
  dropIndex,
  dropPosition,
  dropAreaType,
  schedule,
}: ItemDropAreaProps): JSX.Element {
  const [{ isDropping }, drop] = useDrop({
    accept: "item",
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      isDropping: monitor.isOver({ shallow: true }) && monitor.canDrop(),
      draggedItem: monitor.getItem(),
    }),
    drop: (item: DragObjectItem, monitor: DropTargetMonitor) =>
      onDropEnd({
        item,
        dropAreaItem,
        parent,
        dropIndex,
        dropPosition,
        dropAreaType,
        schedule,
      }),
    canDrop: (item: DragObjectItem, monitor: DropTargetMonitor) => {
      if (!dropAreaItem) return true
      return item.slug !== dropAreaItem?.slug
    },
  })

  const dropAreaClasses = "box-border h-2 z-20 flex flex-1"
  const droppingClasses =
    "box-border w-full h-1 rounded bg-blue-600 self-center"

  return (
    <div
      className={classnames(dropAreaClasses, {
        "opacity-75": isDropping,
        [styles.dropAreaCircle]: isDropping,
      })}
      ref={drop}
      style={{
        top: "-2rem",
        marginBottom: "5px",
      }}
    >
      <div
        className={classnames({
          [styles.dropAreaCircle]: isDropping,
          [styles.dropAreaEndCircle]: isDropping,
          [droppingClasses]: isDropping,
        })}
      />
    </div>
  )
}

ItemDropArea.defaultProps = {
  dropPosition: "after",
  dropAreaType: "ordering",
}
