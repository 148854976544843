import debug from "debug"
import { Context } from "overmind"
import { v4 as uuid } from "uuid"
import { stringType } from "aws-sdk/clients/iam"

const d = debug("ChainList/store/system/actions")

export function showTooltip({ state }: Context, value: boolean): void {
  state.system.showSignupTooltip = value
}
