import { isServerRendered } from "~src/common/lib"

export function get(key: string): string {
  if (isServerRendered()) return ""
  return window.localStorage?.getItem(key)
}

export function set(key: string, value?: string): void {
  if (isServerRendered()) return
  window.localStorage?.setItem(key, value)
}

export const storage = {
  get,
  set,
}
