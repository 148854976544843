import React from "react"
import { CategoryHeader } from "./CategoryHeader"
import { ItemCard } from "./ItemCard"
import { useStore } from "~src/store"
import { ItemDropArea, onDropParams } from "~src/components"
import { ChecklistItemsHelper } from "@bonsaichecklist/bonsai-utils"
import { AnimatePresence, motion } from "framer-motion"
import { getTreeFromFlatData } from "~src/common/lib"
import AddAiGeneratedItems, {
  AddAiGeneratedItemsProps,
} from "./AddAiGeneratedItems"

interface Props extends AddAiGeneratedItemsProps {}

export function UnScheduleItems({
  handleGenerateItems,
  loading,
}: Props): JSX.Element {
  const [expanded, setExpaned] = React.useState<boolean>(true)

  const {
    state: {
      checklist: { rootItems, current, itemMap },
    },
    actions: {
      checklist: { focusItem, insertItem, moveDroppedItem },
    },
  } = useStore()

  const treeData = getTreeFromFlatData(
    Object?.values(itemMap) || []
  ) as ChecklistItem[]
  const sortedTreeData = treeData.sort(
    ChecklistItemsHelper.sortChecklistItemsByOrder
  )

  const treeMap = ChecklistItemsHelper.makeItemMap(sortedTreeData)

  function handleToggleExpand() {
    setExpaned((prev) => !prev)
  }

  function handleAddItem(): void {
    setExpaned(true)

    const lastItem = rootItems[rootItems.length - 1]

    if (lastItem && !lastItem?.body) return focusItem(lastItem.slug)
    insertItem({ value: "", type: "unScheduled" })
  }

  function handleDropEnd(params: onDropParams): void {
    moveDroppedItem(params)
  }

  return (
    <>
      <CategoryHeader
        expanded={expanded}
        handleToggleExpand={handleToggleExpand}
        itemCount={current?.unscheduleItems?.length || 0}
        itemName="Unscheduled items"
      />
      <div className="mt-4">
        <AnimatePresence initial={false}>
          {expanded && (
            <motion.div
              animate="open"
              exit="collapsed"
              initial="collapsed"
              key="content"
              transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
              variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 },
              }}
            >
              {Boolean(current?.unscheduleItems?.length) &&
                current.unscheduleItems.map((itemSlug, index) => (
                  <ItemCard
                    index={index}
                    item={treeMap[itemSlug]}
                    key={itemSlug}
                    subItems={
                      treeMap[itemSlug]?.children?.sort(
                        ChecklistItemsHelper.sortChecklistItemsByOrder
                      ) || []
                    }
                  />
                ))}
            </motion.div>
          )}
        </AnimatePresence>

        {!expanded && (
          <ul className="list-disc ml-6">
            {current?.unscheduleItems?.map((item) => (
              <li className=" text-sm text-black font-normal my-2" key={item}>
                {treeMap[item]?.body}
                {"  "}
                {treeMap[item]?.children?.length > 0 && (
                  <span className=" text-xs text-gray2 font-normal">
                    {treeMap[item]?.children?.length} sublist item
                  </span>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="relative left-2 right-0" style={{ zIndex: 8 }}>
        <ItemDropArea
          dropIndex={1}
          dropPosition="after"
          onDropEnd={handleDropEnd}
          parent={null}
          schedule={false}
        />
      </div>
      <div className="flex gap-2">
        <div className={`cursor-pointer`} onClick={handleAddItem}>
          <span className=" text-xs font-normal text-primaryBlue">
            + Add item
          </span>
        </div>
        {Object.keys(itemMap).length ? (
          <AddAiGeneratedItems
            handleGenerateItems={handleGenerateItems}
            loading={loading}
          />
        ) : (
          ""
        )}
      </div>
    </>
  )
}
