import React from "react"
import logo from "./logo.svg"

export interface LogoProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  width?: number
}

export function Logo({ width, ...props }: LogoProps): JSX.Element {
  return <img alt="logo" src={logo} style={{ maxWidth: width }} {...props} />
}
