import debug from "debug"
import { Context } from "overmind"
import { ChecklistService, RunService } from "~src/store/checklist/effects"
import { ChecklistItemsHelper } from "@bonsaichecklist/bonsai-utils"
import { RUN_TYPE_INITIAL } from "~src/common/constants"
import { routes } from "~src/routes"
import router from "next/router"
const d = debug("ChainList/store/checklist/actions/save")

export function setSaving(
  { state: { checklist } }: Context,
  saving: boolean
): void {
  checklist.saving = saving
}

export function setTitle(
  {
    state: {
      checklist: { current },
    },
  }: Context,
  title: string
): void {
  current.title = title
}

export function setDescription(
  {
    state: {
      checklist: { current },
    },
  }: Context,
  description: string
): void {
  current.description = description
}

export function setItemDescription(
  {
    state: {
      checklist: { itemMap },
    },
  }: Context,
  values: {
    slug: string
    itemDescription: string
  }
): void {
  const { slug, itemDescription } = values

  itemMap[slug].description = itemDescription
}

export function setVisibility(
  {
    state: {
      checklist: { current },
    },
  }: Context,
  visibility: ChecklistVisibility
): void {
  current.visibility = visibility
}

export function reset({ state: { checklist } }: Context, value: void): void {
  delete checklist.current
  delete checklist.activeItemSlug
  delete checklist.itemMap
  delete checklist.focusedTitle
  delete checklist.unsavedChanges
  delete checklist.checklistScheduleMap
  checklist.schedules = []
}

export function setRedirecting(
  { state: { checklist } }: Context,
  redirecting: boolean
): void {
  checklist.redirecting = redirecting
}

export async function save(
  {
    actions: {
      checklist: { setSaving, reset, setUnsavedChanges },
    },
    state: {
      checklist: { current, itemMap, checklistScheduleMap },
    },
  }: Context,
  value: void
): Promise<Checklist> {
  try {
    setUnsavedChanges(false)
    setSaving(true)
    // flatten the subItems if their parent is being deleted and trim starting and ending whitespaces
    // Object.values(itemMap).forEach((item) => {
    //   if (!item.body && !item.linkedList) {
    //     ChecklistItemsHelper.flattenSubItems(itemMap, item)
    //   }
    // })

    d("saving items:", itemMap)

    const checklist = await ChecklistService.saveChecklist({
      ...current,
      items: Object.values(itemMap).filter((item) => {
        if (item.body) return true

        delete checklistScheduleMap[item.slug]
        return false
      }),
      schedules: Object.values(checklistScheduleMap).filter((item) => {
        if (item?.id) {
          return true
        } else if (!item?.delete) return true

        return false
      }),
      unscheduleItems: current?.unscheduleItems?.filter(
        (slug) => itemMap[slug]?.body
      ),
    })
    reset()
    return checklist
  } catch (error) {
    setSaving(false)
    throw error
  }
}

export function setVerified(
  { state: { checklist } }: Context,
  verified: boolean
): void {
  checklist.checklistVerified = verified
}

export async function changeVisibility(
  { state: { checklist } }: Context,
  slug: string
): Promise<void> {
  const visibilityChanged = await ChecklistService.setVisibility(slug)
  checklist.visibilityChanged = visibilityChanged
}

export async function resetChangeVisibility({
  state: { checklist },
}: Context): Promise<void> {
  checklist.visibilityChanged = null
}

export async function createChklRun(
  {
    actions: {
      auth: { setIsRunZeroPrompted },
    },
  }: Context,
  { chklSlug, itemSlug }: { chklSlug: string; itemSlug: any }
): Promise<void> {
  const checklistRun = await RunService.run(
    chklSlug,
    itemSlug,
    undefined,
    RUN_TYPE_INITIAL
  )
  if (checklistRun?.slug) {
    setIsRunZeroPrompted(true)
    router.push(routes.runs.show(checklistRun?.slug))
  }

  return
}

export function setUnsavedAlert(
  { state: { checklist } }: Context,
  value: boolean
): void {
  checklist.showUnsavedAlert = value
}
