import { routes } from "~src/routes"
import { ChecklistHelper } from "./ChecklistHelper"

export function formatLinkedListName(
  item: ChecklistItem,
  linkedListMap?: LinkedListMap
): string {
  if (!item?.linkedList || !linkedListMap)
    return item?.body?.replace(/[\[\]]/g, "")

  const linkedList = linkedListMap[item.linkedList]
  const title = linkedList?.title
  const helper = new ChecklistHelper(linkedList)
  const chklOwner = helper.ownerUsername

  return item.body.replace(
    `[[${title}]]`,
    `<a href="${routes.checklists.show(
      linkedList?.slug,
      chklOwner
    )}">${title}</a>` // TODO: Use Link from Nextjs
  )
}
