import React from "react"

import PinIcon from "~public/icons/pin.svg"
import { checklistTitle, getChecklistOwner } from "~src/common/lib"
import { routes } from "~src/routes"

import { ChecklistVerifiedTick } from "../../ChecklistVerifiedTick"
import { Link } from "../../Link"
import { MetaData } from "../meta-data"
import { Tooltip } from "../../Tooltip"
import { Props } from "./list-view.types"

export function ListView({
  data,
  isFirstChild,
  isLastChild,
}: Props): JSX.Element {
  const { slug, title, coverUrl, description, verified, isFeatured } = data

  const chklOwner = getChecklistOwner(data)

  return (
    <div
      className={`bg-white w-full lg:flex items-center 
        border-t border-r border-b border-l lg:border-b-0 rounded-md lg:rounded-none border border-gray4 relative ${
          isFirstChild && "lg:rounded-t-md"
        } ${isLastChild && "lg:rounded-b-md lg:border-b"} my-4 lg:my-0 `}
    >
      <Link
        className="text-base font-bold flex items-center flex-grow gap-4 px-5 pt-5 lg:p-3"
        href={routes.checklists.show(slug, chklOwner)}
      >
        <div
          className={`hidden lg:inline-flex w-20 h-14 flex-shrink-0 ${
            !coverUrl && " bg-gray4 rounded"
          } `}
        >
          {coverUrl && (
            <img
              alt="Template Cover"
              className="w-full h-full flex-shrink-0 object-cover rounded"
              src={coverUrl}
            />
          )}
        </div>

        <div className="space-y-1">
          <div className="flex items-center">
            {checklistTitle(title)}
            {verified && <ChecklistVerifiedTick />}
          </div>
          <h4 className="text-xs text-black font-normal">{description}</h4>
        </div>
      </Link>

      {isFeatured && (
        <Tooltip tooltipTitle="Featured by ChainList">
          <img
            alt="Pic Icon"
            className={`absolute top-0 ${
              isFirstChild
                ? "rounded-tl-md"
                : "rounded-tl-md lg:rounded-tl-none"
            }`}
            src={PinIcon}
          />
        </Tooltip>
      )}

      <MetaData
        checklist={data}
        classNames="lg:max-w-md lg:w-full mr-4"
        isListView
      />
    </div>
  )
}
