import React from "react"
import { Button, ButtonProps, Icon, IconProps } from "~src/components"

export interface IconButtonProps
  extends Omit<ButtonProps, "children" | "icon"> {
  icon: IconProps["name"]
  iconVariant?: IconProps["variant"]
  iconStyles?: string
  tooltip?: string
  children?: React.ReactNode
}

export function IconButton({
  icon,
  iconVariant,
  iconStyles,
  tooltip,
  children,
  ...props
}: IconButtonProps): JSX.Element {
  return (
    <Button icon {...props} tooltip={tooltip}>
      <Icon
        className={iconStyles}
        fixedWidth
        name={icon}
        variant={iconVariant}
      />
      {children}
    </Button>
  )
}
