import React from "react"
import router from "next/router"

import { routes } from "~src/routes"
import { Button } from "../Button"
import sparklesIcon from "~public/icons/sparkles.svg"
import listSearchIcon from "~public/icons/list-search.svg"

interface Props {
  search?: string
}
export function ChecklistNotFound({ search }: Props): JSX.Element {
  const handleGenerateAiChecklist = () => {
    router.push(routes.generic.aiChecklist(search))
  }

  return (
    <div className="max-w-7xl mx-auto px-1 pt-7">
      <div className="bg-white lg:w-8/12 md:w-6/12 sm:w-4/12 rounded-md p-16">
        <div className="flex flex-col gap-5 items-center flex-wrap ">
          <img
            alt="list-search"
            className="mr-2 h-14 w-14"
            src={listSearchIcon}
          />
          <strong className="text-md">No checklist found</strong>
          <p className="text-center text-neutralGray text-md">
            No one has built that list yet - but we can generate a starter list
            for you.
          </p>
          <Button
            className=" bg-secondaryGray text-primaryBlue border-none shadow-none"
            disabled={!search}
            onClick={handleGenerateAiChecklist}
            variant="secondary"
          >
            <img alt="sparkles" className="mr-2" src={sparklesIcon} />
            Generate a checklist using AI
          </Button>
        </div>
      </div>
    </div>
  )
}
