import Head from "next/head"
import React from "react"
import striptags from "striptags"
import { APP_URL } from "~src/common/constants"

export interface BaseLayoutProps {
  isFeedbackVisible?: boolean
  children?: React.ReactNode
}

export function BaseLayout({
  children,
  ...props
}: BaseLayoutProps): JSX.Element {
  return (
    <div className="h-full" id="main-wrapper" {...props}>
      <Head>
        <link
          rel="icon"
          type="image/png"
          href="/android-icon-144x144.png"
          sizes="144x144"
        />
        <link
          rel="icon"
          type="image/png"
          href="/android-icon-192x192.png"
          sizes="192x192"
        />
        <link
          rel="icon"
          type="image/png"
          href="/android-icon-36x36.png"
          sizes="36x36"
        />
        <link
          rel="icon"
          type="image/png"
          href="/android-icon-48x48.png"
          sizes="48x48"
        />
        <link
          rel="icon"
          type="image/png"
          href="/android-icon-72x72.png"
          sizes="72x72"
        />
        <link
          rel="icon"
          type="image/png"
          href="/android-icon-96x96.png"
          sizes="96x96"
        />

        <link
          rel="icon"
          type="image/png"
          href="/favicon-96x96.png"
          sizes="96x96"
        />
        <link
          rel="icon"
          type="image/png"
          href="/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/favicon-16x16.png"
          sizes="16x16"
        />
        <link
          rel="apple-touch-icon-precomposed"
          href="/apple-icon-precomposed.png"
        />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-icon-180x180.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/apple-icon-57x57.png"
        />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-square150x150logo"
          content="/ms-icon-150x150.png"
        />
        <meta
          name="msapplication-square310x310logo"
          content="/ms-icon-310x310.png"
        />
        <meta
          name="msapplication-square70x70logo"
          content="/ms-icon-70x70.png"
        />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-config" content="/browserconfig.xml" />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Source+Code+Pro&display=swap"
          rel="stylesheet"
        />
        <link href="https://fonts.googleapis.com" rel="preconnect" />
        <link href="https://fonts.gstatic.com" rel="preconnect" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
      </Head>
      {children}
    </div>
  )
}
