import React, { useMemo } from "react"

import { IS_DIGIT } from "~src/common/constants"
import { Button, Link } from "src/components"

interface props {
  value: string
  valueLength: number
  onChange: (value: string) => void
  verifyOtp: () => void
  errorMsg?: Error
  setErrorMsg?: (value: null) => void
  resendOtp?: () => Promise<void>
}

export function OtpInput({
  value,
  valueLength,
  onChange,
  verifyOtp,
  errorMsg,
  setErrorMsg,
  resendOtp,
}: props): JSX.Element {
  const [time, setTime] = React.useState<number>(120)

  React.useEffect(() => {
    const countDown = setInterval(() => {
      if (time <= 0) return
      setTime((prev) => prev - 1)
    }, 1000)

    return () => clearInterval(countDown)
  }, [time])

  const valueItems = useMemo(() => {
    const valueArray = value.split("")
    const items: Array<string> = []

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i]

      if (IS_DIGIT.test(char)) {
        items.push(char)
      } else {
        items.push("")
      }
    }

    return items
  }, [value, valueLength])

  const focusToNextInput = (target: HTMLElement) => {
    const nextElementSibling = target.nextElementSibling as HTMLInputElement | null

    if (nextElementSibling) {
      nextElementSibling.focus()
    }
  }
  const focusToPrevInput = (target: HTMLElement) => {
    const previousElementSibling = target.previousElementSibling as HTMLInputElement | null

    if (previousElementSibling) {
      previousElementSibling.focus()
    }
  }

  const inputOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    const target = e.target
    let targetValue = target.value.trim()
    const isTargetValueDigit = IS_DIGIT.test(targetValue)

    if (!isTargetValueDigit && targetValue !== "") {
      return
    }

    targetValue = isTargetValueDigit ? targetValue : " "

    const targetValueLength = targetValue.length

    if (targetValueLength === 1) {
      const newValue =
        value.substring(0, idx) + targetValue + value.substring(idx + 1)

      onChange(newValue)

      if (!isTargetValueDigit) {
        return
      }

      focusToNextInput(target)
    } else if (targetValueLength === valueLength) {
      onChange(targetValue)

      target.blur()
    }
  }

  const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e
    const target = e.target as HTMLInputElement

    if (key === "ArrowRight" || key === "ArrowDown") {
      e.preventDefault()
      return focusToNextInput(target)
    }

    if (key === "ArrowLeft" || key === "ArrowUp") {
      e.preventDefault()
      return focusToPrevInput(target)
    }

    if (errorMsg) {
      setErrorMsg(null)
    }

    const targetValue = target.value

    target.setSelectionRange(0, targetValue.length)

    if (e.key !== "Backspace" || targetValue !== "") {
      return
    }

    focusToPrevInput(target)
  }

  const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { target } = e

    target.setSelectionRange(0, target.value.length)
  }

  const handleVerify = () => {
    verifyOtp()
  }

  const handleResendOtp = async () => {
    try {
      await resendOtp()

      setTime(120)
    } catch (error) {
      setErrorMsg(error)
    }
  }

  return (
    <>
      <div className="w-full max-w-sm  gap-2 flex mt-4">
        {valueItems.map((digit, index) => (
          <input
            autoComplete="one-time-code"
            className={`w-full h-20 rounded border  ${
              errorMsg ? " border-red-600 animate-shake" : "border-gray-300"
            }  text-center text-4xl font-bold text-black`}
            inputMode="numeric"
            key={index}
            maxLength={6}
            onChange={(e) => inputOnChange(e, index)}
            onFocus={inputOnFocus}
            onKeyDown={(e) => inputOnKeyDown(e)}
            pattern="\d{1}"
            type="text"
            value={digit}
          />
        ))}
      </div>
      <p className=" text-xs font-normal text-red-600 mt-4">
        {errorMsg && errorMsg.message}
      </p>
      <div>
        <Link disabled={time > 0} onClick={handleResendOtp}>
          Re-send
        </Link>
        {time > 0 && <span>{` try again after ${time} seconds.`}</span>}
      </div>
      <div className="flex justify-end mt-6">
        <Button
          disabled={valueItems.some((item) => item === "") || Boolean(errorMsg)}
          onClick={handleVerify}
        >
          Verify account
        </Button>
      </div>
    </>
  )
}
