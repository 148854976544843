import React from "react"
import { Button } from "~src/components"
import { useActions, useStore } from "~src/store"
import { UserService } from "~src/services"
import { errorReporter } from "~src/common/lib"

import infoBulb from "~src/../public/icons/info-bulb.svg"
import check from "~src/../public/icons/check.svg"
import checkGreen from "~src/../public/icons/check-green.svg"

export interface InfoBoxProps {
  title?: string
  info?: string
  buttonLabel?: string
  icon?: string
  showButton?: boolean
  children?: React.ReactNode
}

export function InfoBox({
  title,
  info,
  buttonLabel = "Got it",
  children,
  icon = "info-bulb",
  showButton = true,
}: InfoBoxProps): JSX.Element {
  const {
    system: { showToastAlert },
  } = useActions()

  const {
    state: { auth },
    actions: {
      auth: { updateInfoDialog },
    },
  } = useStore()

  const [loading, setLoading] = React.useState<boolean>(false)
  const [hide, setHide] = React.useState<boolean>(
    showButton && (auth?.user?.infoDialogs?.scheduleInfoDialog || false)
  )

  const onClickGotIt = async (event: React.FormEvent) => {
    event.preventDefault()
    setLoading(true)
    try {
      await UserService.updateInfoDialog({
        scheduleInfoDialog: true,
      })
      setHide(true)
      updateInfoDialog({
        scheduleInfoDialog: true,
      })
    } catch (e) {
      errorReporter.notify(e)
      showToastAlert({
        text: "There is an error. please try again later",
        autoHide: true,
        type: "warning",
        iconVariant: "solid",
      })
    }
    setLoading(false)
  }

  if (hide || !auth.user) {
    return null
  }

  const getIcon = () => {
    switch (icon) {
      case "info-bulb":
        return infoBulb
      case "check":
        return check
      case "check-green":
        return checkGreen
      default:
        return infoBulb
    }
  }

  return (
    <div className="bg-blue-100 mb-6 md:w-cardWidth p-5 rounded-md mt-5 ">
      <div className="flex flex-row">
        <img src={getIcon()} alt={icon} className="text-red"></img>
        <h4 className="font-bold my-1 text-sm ml-2">{title}</h4>
      </div>
      <p className="text-sm mt-2 mb-2">{info}</p>

      {children && children}

      {showButton && !loading && (
        <Button
          className="h-max-content w-max-content text-sm"
          tooltip={"Receive scheduled runs and updates about list changes"}
          onClick={onClickGotIt}
          variant="secondary"
        >
          {buttonLabel}
        </Button>
      )}
    </div>
  )
}
