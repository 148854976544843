import rfdc from "rfdc"

import {
  ChecklistItemsHelper,
  generateSlug,
} from "@bonsaichecklist/bonsai-utils"

export const findChildren = (
  item: ChecklistItem,
  itemMap: ChecklistItemMap,
  refItemMap: ChecklistItemMap
) => {
  const children = ChecklistItemsHelper.getSubItemsOfParent(
    refItemMap,
    item.slug
  )

  // Assign new slug
  item.slug = generateSlug()
  itemMap[item.slug] = item

  if (children.length) {
    const newChildren = rfdc({ proto: false })(children)
    
    newChildren.map((child) => {
      child.parent = item.slug
      child.isCopied = true
      
      delete child?._id
      delete child?.id

      findChildren(child, itemMap, refItemMap)
    })
  }
}
