import React from "react"
import { ContentState } from "draft-js"

export function Link({
  children,
  contentState,
  entityKey,
}: {
  children: React.ReactNode
  contentState: ContentState
  entityKey: string
}): JSX.Element {
  const { url } = contentState.getEntity(entityKey).getData()
  const linkClasses = "text-blue-600 hover:text-blue-800"
  return (
    <a
      className={linkClasses}
      href={url}
      rel="noreferrer noopener"
      target="_blank"
    >
      {children}
    </a>
  )
}
