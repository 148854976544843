import React from "react"
import { ChecklistHelper } from "~src/common/lib"
import { ActiveLink } from "~src/components"

import { routes } from "~src/routes"

interface Props {
  showSidebar: boolean
  menuButtons: () => React.ReactElement
  isMobile: boolean
  checklist: Checklist
}
export function MenuItems({
  showSidebar,
  menuButtons,
  isMobile,
  checklist,
}: Props): JSX.Element {
  const helper = new ChecklistHelper(checklist)
  const chklOwner = helper.ownerUsername
  return (
    <div
      className={`${showSidebar && "px-2"}  grid grid-cols-4  mt-5  ${
        isMobile ? "overflow-x-scroll" : null
      }`}
    >
      <div className="flex col-start-1 col-end-3 gap-4 md:gap-8 sm:w-48 md:w-60 ">
        <ActiveLink
          activeClassName="border-b-4 border-blue-500 text-sm text-gray-600 font-semibold"
          href={routes.checklists.show(checklist?.slug, chklOwner)}
          unstyled
        >
          <a
            className="pb-4 flex text-sm font-semibold text-gray-600"
            href="javascript;"
            title="Checklists"
          >
            Checklists
          </a>
        </ActiveLink>
        {/* <ActiveLink
          activeClassName="border-b-4 border-blue-500 text-sm text-gray-600 font-semibold"
          href={routes.checklists.old(checklist.slug, chklOwner)}
          unstyled
        >
          <a
            className="pb-4 flex text-sm font-semibold text-gray-600"
            href="javascript;"
            title="Template"
          >
            Template
          </a>
        </ActiveLink> */}
        {/* <ActiveLink
          activeClassName="border-b-4 border-blue-500 text-sm text-gray-600 font-semibold"
          href={routes.checklists.schedule(checklist.slug, chklOwner)}
          unstyled
        >
          <a
            className="pb-4 flex text-sm font-semibold text-gray-600 intro-template-schedule"
            href="javascript;"
            title="Schedule"
          >
            Schedule
          </a>
        </ActiveLink> */}
        <ActiveLink
          activeClassName="border-b-4 border-blue-500 text-sm text-gray-600 font-semibold"
          href={routes.checklists.comments(checklist?.slug, chklOwner)}
          unstyled
        >
          <a
            className="pb-4 flex text-sm font-semibold text-gray-600 intro-template-comments"
            href="javascript;"
            title="Comments"
          >
            Comments
          </a>
        </ActiveLink>

        <ActiveLink
          activeClassName="border-b-4 border-blue-500 text-sm text-gray-600 font-semibold"
          href={routes.checklists.activities(checklist?.slug, chklOwner)}
          unstyled
        >
          <a
            className="pb-4 flex text-sm font-semibold text-gray-600 intro-template-activity"
            href="javascript;"
            title="Activity"
          >
            Activity
          </a>
        </ActiveLink>
      </div>
      {/** Menu buttons */}
      <div
        className={
          "flex col-end-7 col-span-2 space-x-3 h-9  intro-subscribe-star"
        }
      >
        {!isMobile && menuButtons()}
      </div>
    </div>
  )
}
