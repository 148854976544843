import React from "react"
import { AttachmentTile } from "~src/components"

export interface AttachmentTilesProps {
  attachments: Attachment[]
  itemSlug: string
  size?: string
  viewOnly?: boolean
}

export function AttachmentTiles({
  attachments,
  itemSlug,
  size,
  viewOnly,
}: AttachmentTilesProps): JSX.Element {
  if (!attachments?.length) return <></>
  return (
    <div className="flex flex-wrap">
      {attachments.map((attachment) => (
        <AttachmentTile
          attachment={attachment}
          itemSlug={itemSlug}
          key={attachment.slug}
          size={size}
          viewOnly={viewOnly}
        />
      ))}
    </div>
  )
}
