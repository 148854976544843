import * as qs from "qs"
import { SCHEDULES } from "~src/common/apiRoutes"
import { chainListAxios } from "~src/common/lib"

export class ScheduleService {
  public static async create(
    slug: string,
    schedule: Schedule
  ): Promise<Schedule> {
    const response = await chainListAxios.post(SCHEDULES.create(slug), schedule)

    return response.data
  }

  public static async cancelOccurrence(
    slug: string,
    scheduleId: string,
    cancelledOccurrence: Date
  ): Promise<Schedule> {
    const response = await chainListAxios.post(
      SCHEDULES.cancelOccurrence(slug, scheduleId),
      { cancelledOccurrence }
    )
    return response.data
  }

  public static async cancelSchedule(
    slug: string,
    scheduleId: string
  ): Promise<Schedule> {
    const response = await chainListAxios.post(
      SCHEDULES.cancelSchedule(slug, scheduleId)
    )
    return response.data
  }

  public static async findAll(
    chklSlug: string,
    headers: GenericObject,
    queryParams: { page?: number; limit?: number }
  ): Promise<{ schedules: Schedule[]; scheduleCount: number }> {
    const query = qs.stringify({
      page: queryParams.page,
      limit: queryParams.limit,
    })
    const response = await chainListAxios.get(
      SCHEDULES.findAll(chklSlug, query),
      headers && { headers }
    )
    return response.data
  }

  public static async findInfo(
    chklSlug: string,
    headers?: GenericObject
  ): Promise<{ scheduleInfo: ScheduleInfo }> {
    const response = await chainListAxios.get(
      SCHEDULES.info(chklSlug),
      headers && { headers }
    )
    return response.data
  }

  // Checklist 2.0
  public static async getScheduleChecklist(
    chklSlug: string,
    username?: string
  ): Promise<void> {
    try {
      const response = await chainListAxios.get(
        SCHEDULES.getScheduleChecklist(chklSlug, username)
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  public static async getScheduleChecklistItems(): Promise<void> {
    try {
      const response = await chainListAxios.get(
        SCHEDULES.getScheduleChecklistItems()
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  public static async getUnScheduleChecklistData(
    chklSlug: string,
    username?: string
  ): Promise<void> {
    try {
      const response = await chainListAxios.get(
        SCHEDULES.getUnScheduleChecklistData(chklSlug, username)
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  public static async getUnScheduleChecklist(chklSlug: string): Promise<any> {
    try {
      const response = await chainListAxios.get(
        SCHEDULES.getUnScheduleChecklist(chklSlug)
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  public static async upComingChecklistCheck(
    chklSlug: string,
    username: string,
    data: any
  ): Promise<void> {
    try {
      const response = await chainListAxios.post(
        SCHEDULES.upcomingChecklistCheck(chklSlug, username),
        data
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
  public static async unscheduleItemCheck(
    chklSlug: string,
    username: string,
    data: any
  ): Promise<void> {
    try {
      const response = await chainListAxios.put(
        SCHEDULES.unScheduleItemCheck(chklSlug, username),
        data
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  public static async getPastItems(
    chklSlug: string,
    username: string,
    date: Date | string,
    runSlug?: string
  ): Promise<any> {
    try {
      if (chklSlug) {
        const response = await chainListAxios.get(
          SCHEDULES.viewPastItems(chklSlug, username, date, runSlug)
        )
        return response.data
      }
    } catch (error) {
      console.log(error)
    }
  }
  public static async getPastItemsCount(
    chklSlug: string,
    username: string,
    runSlug?: string
  ): Promise<any> {
    try {
      if (chklSlug) {
        const response = await chainListAxios.get(
          SCHEDULES.viewPastItemsCount(chklSlug, username, runSlug)
        )
        return response.data
      }
    } catch (error) {
      console.log(error)
    }
  }
}
