import Head from "next/head"
import React from "react"
import striptags from "striptags"
import { APP_URL } from "~src/common/constants"

export interface MetaTagsProps {
  title?: string
  description?: string
  coverUrl?: string
  noIndex?: boolean
  avatar?: UserAvatar
  children?: React.ReactNode
}
const MetaTags = ({
  title = "ChainList",
  description = "ChainList - Discover Checklists",
  noIndex = false,
  coverUrl,
  avatar,
  children,
}: MetaTagsProps): JSX.Element => {
  const cleanedTitle = striptags(title)
  const shareImage = coverUrl || `${APP_URL}/share-image.png`
  return (
    <div>
      <Head>
        {title && <title>ChainList - {cleanedTitle}</title>}
        {description && <meta content={description} name="description" />}
        {/* Facebook Meta tags */}
        <meta content="website" property="og:type" />
        <meta content={cleanedTitle} name="title" property="og:title" />
        <meta
          content={description}
          name="description"
          property="og:description"
        />
        <meta content={"1200"} property="og:image:width" />
        <meta content={"630"} property="og:image:height" />
        {avatar ? (
          <meta content={avatar?.large.url} name="image" property="og:image" />
        ) : (
          <meta content={shareImage} name="image" property="og:image" />
        )}
        {/* Twitter Meta tags */}
        <meta content="summary_large_image" name="twitter:card" />
        <meta content="https://www.chainlist.com/" property="twitter:domain" />
        <meta content={cleanedTitle} name="twitter:title" />
        <meta content={description} name="twitter:description" />
        <meta content={shareImage} name="twitter:image" />
        <meta content="@chainlist" name="twitter:image:alt" />
        <meta content="@chainlist" name="twitter:site" />

        <meta content="en_US" property="og:locale" />
        {noIndex && <meta content="noindex, nofollow" name="robots" />}
      </Head>
      {children}
    </div>
  )
}

export default MetaTags
