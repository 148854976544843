import React from "react"
import { useRouter } from "next/router"
import {
  FEED_SUMMARY,
  ACTIVITY_TYPES,
  CHECKLIST_VISIBILITY_PUBLIC,
  CHECKLIST_VISIBILITY_UNLISTED,
} from "~src/common/constants"
import { Link } from "~src/components"
import { routes } from "~src/routes"
import { useState } from "~src/store"
import { ChecklistHelper } from "~src/common/lib"

export function Activity({
  activity,
  className,
  showChecklistTitle,
  cb,
}: {
  activity: ActivityLog
  className?: string
  showChecklistTitle: boolean
  cb?: () => void
}): JSX.Element {
  const {
    auth: { user },
  } = useState()

  const { type } = activity

  const router = useRouter()
  function handleOnClick(url: string) {
    router.push(url)
    cb && cb()
  }

  const [checklistRun] = Array.isArray(activity?.checklistRun)
    ? activity?.checklistRun
    : [activity?.checklistRun]
  const [branchedChecklist] = Array.isArray(activity?.branchedChecklist)
    ? activity?.branchedChecklist
    : [activity?.branchedChecklist]

  // activity?.checklist should be at the end
  const chklHelper = new ChecklistHelper(
    activity?.branchedFrom ||
      branchedChecklist ||
      activity?.followedFrom ||
      activity?.unFollowedFrom ||
      activity?.checklist
  )
  let chklOwner = chklHelper?.ownerUsername

  const actor = !activity?.actor ? null : activity?.actor?.username ===
    user?.username ? (
    "by you"
  ) : (
    <span>
      by{" "}
      <Link
        onClick={() =>
          handleOnClick(routes.users.profile(activity?.actor?.username))
        }
      >
        @{activity?.actor?.username}
      </Link>
    </span>
  )

  const checklist =
    showChecklistTitle && activity?.checklist ? (
      <Link
        onClick={() =>
          handleOnClick(
            routes.checklists.show(
              activity.checklist.slug,
              new ChecklistHelper(
                activity.type === "follow-checklist"
                  ? activity.followedChecklist
                  : activity.checklist
              )?.ownerUsername
            )
          )
        }
      >
        {activity.checklist.title}
      </Link>
    ) : (
      "This template"
    )

  const checkVisibility = (checklist: Checklist): boolean => {
    return (
      checklist?.visibility.toLocaleLowerCase() ===
        CHECKLIST_VISIBILITY_PUBLIC &&
      (user ? checklist?.owner?.username !== user?.username : true)
    )
  }

  const listVisibility = activity?.checklist?.visibility

  switch (type) {
    case ACTIVITY_TYPES.CREATE_CHECKLIST:
    case ACTIVITY_TYPES.EDIT_CHECKLIST:
    case ACTIVITY_TYPES.TRASH_CHECKLIST:
    case ACTIVITY_TYPES.RESTORE_CHECKLIST:
    case ACTIVITY_TYPES.FOLLOW_CHECKLIST:
    case ACTIVITY_TYPES.FAVOURITE_CHECKLIST:
    case ACTIVITY_TYPES.UN_FOLLOW_CHECKLIST:
      return (
        <div className={className}>
          {checklist} {FEED_SUMMARY[type as keyof typeof FEED_SUMMARY]}&nbsp;
          {actor}.
        </div>
      )
    case ACTIVITY_TYPES.SET_CHECKLIST_VERIFIED:
      return (
        <div className={className}>
          {checklist} {FEED_SUMMARY[type as keyof typeof FEED_SUMMARY]}&nbsp; by
          chainlist
        </div>
      )
    case ACTIVITY_TYPES.FOLLOWED_FROM:
    case ACTIVITY_TYPES.UN_FOLLOWED_FROM:
      chklOwner = new ChecklistHelper(
        activity.branchedFrom ||
          activity.followedFrom ||
          activity.unFollowedFrom
      )?.ownerUsername
      return (
        <div className={className}>
          {actor === "by you" ? "You" : actor} &nbsp;
          {FEED_SUMMARY[type as keyof typeof FEED_SUMMARY]}&nbsp;
          <Link
            onClick={() =>
              handleOnClick(
                routes.checklists.show(
                  activity.branchedFrom?.slug ||
                    activity.followedFrom?.slug ||
                    activity.unFollowedFrom?.slug,
                  chklOwner
                )
              )
            }
          >
            {activity.checklist?.title ||
              activity.followedFrom?.title ||
              activity.unFollowedFrom?.title}
          </Link>
        </div>
      )

    case ACTIVITY_TYPES.CREATE_COPY:
      return (
        <div className={className}>
          {actor === "by you" ? (
            <>
              You copied {typeof checklist === "string" ? "this" : checklist}{" "}
              into{" "}
              <Link
                onClick={() =>
                  handleOnClick(
                    routes.checklists.show(
                      branchedChecklist?.slug,
                      new ChecklistHelper(branchedChecklist)?.ownerUsername
                    )
                  )
                }
              >
                {branchedChecklist?.title}
              </Link>{" "}
            </>
          ) : (
            <>
              {user ? (
                <>
                  {typeof checklist === "string" ? "This" : checklist}{" "}
                  {FEED_SUMMARY[type as keyof typeof FEED_SUMMARY]} into{" "}
                  {checkVisibility(branchedChecklist) ? (
                    <Link
                      onClick={() =>
                        handleOnClick(
                          routes.checklists.show(
                            branchedChecklist?.slug,
                            new ChecklistHelper(branchedChecklist)
                              ?.ownerUsername
                          )
                        )
                      }
                    >
                      {branchedChecklist?.title}{" "}
                    </Link>
                  ) : (
                    <span>{branchedChecklist?.title} </span>
                  )}
                  {actor}.
                </>
              ) : (
                <>
                  {checkVisibility(branchedChecklist) ? (
                    <>
                      {typeof checklist === "string" ? "this" : checklist}{" "}
                      {FEED_SUMMARY[type as keyof typeof FEED_SUMMARY]}
                      {" into "}
                      <Link
                        onClick={() =>
                          handleOnClick(
                            routes.checklists.show(
                              branchedChecklist?.slug,
                              new ChecklistHelper(branchedChecklist)
                                ?.ownerUsername
                            )
                          )
                        }
                      >
                        {branchedChecklist?.title}
                      </Link>{" "}
                      {actor}
                    </>
                  ) : (
                    <>
                      {typeof checklist === "string" ? "this" : checklist}{" "}
                      {FEED_SUMMARY[type as keyof typeof FEED_SUMMARY]}
                      {" into "}
                      <span>{branchedChecklist?.title}</span> {actor}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      )

    case ACTIVITY_TYPES.COPIED_FROM:
      chklOwner = new ChecklistHelper(activity.branchedFrom)?.ownerUsername
      return (
        <div className={className}>
          {actor === "by you" ? (
            <>
              You {FEED_SUMMARY[type as keyof typeof FEED_SUMMARY]}
              &nbsp;{typeof checklist === "string" ? "this" : checklist}{" "}
              from&nbsp;
              <Link
                onClick={() =>
                  handleOnClick(
                    routes.checklists.show(
                      activity.branchedFrom?.slug,
                      chklOwner
                    )
                  )
                }
              >
                {activity.branchedFrom?.title}
              </Link>
            </>
          ) : (
            <>
              {user ? (
                <>
                  {typeof checklist === "string" ? "this" : checklist} was{" "}
                  {FEED_SUMMARY[type as keyof typeof FEED_SUMMARY]} from{" "}
                  {!checkVisibility(activity.branchedFrom) ? (
                    <span>{activity.branchedFrom?.title}</span>
                  ) : (
                    <Link
                      onClick={() =>
                        handleOnClick(
                          routes.checklists.show(
                            activity.branchedFrom?.slug,
                            chklOwner
                          )
                        )
                      }
                    >
                      {activity.branchedFrom?.title}
                    </Link>
                  )}{" "}
                  {actor}
                </>
              ) : (
                <>
                  {checkVisibility(activity?.branchedFrom) ? (
                    <>
                      {typeof checklist === "string" ? "this" : checklist} was{" "}
                      {FEED_SUMMARY[type as keyof typeof FEED_SUMMARY]}
                      {" from "}
                      <Link
                        onClick={() =>
                          handleOnClick(
                            routes.checklists.show(
                              activity.branchedFrom?.slug,
                              chklOwner
                            )
                          )
                        }
                      >
                        {activity.branchedFrom?.title}
                      </Link>{" "}
                      {actor}
                    </>
                  ) : (
                    <>
                      {typeof checklist === "string" ? "this" : checklist} was{" "}
                      {FEED_SUMMARY[type as keyof typeof FEED_SUMMARY]}
                      {" from "} <span>{activity?.branchedFrom?.title}</span>{" "}
                      {actor}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      )
    case ACTIVITY_TYPES.CREATE_MANUAL_RUN:
    case ACTIVITY_TYPES.RUN_STARTED:
    case ACTIVITY_TYPES.RUN_COMPLETED:
      return (
        <div className={className}>
          <Link
            onClick={() => handleOnClick(routes.runs.show(checklistRun?.slug))}
          >
            {checklistRun?.title}
          </Link>
          &nbsp;
          {FEED_SUMMARY[type as keyof typeof FEED_SUMMARY]} {actor}.
        </div>
      )
    case ACTIVITY_TYPES.CREATE_SCHEDULED_RUN:
      return (
        <div className={className}>
          {FEED_SUMMARY[type as keyof typeof FEED_SUMMARY]}:{" "}
          <Link
            onClick={() =>
              handleOnClick(
                routes.runs.show(
                  checklistRun ? checklistRun?.slug : activity?.checklist?.slug
                )
              )
            }
          >
            {checklistRun ? checklistRun?.title : activity?.checklist?.title}
          </Link>
          .
        </div>
      )

    case ACTIVITY_TYPES.CREATE_SCHEDULE:
      return (
        <div className={className}>
          {FEED_SUMMARY[ACTIVITY_TYPES.CREATE_SCHEDULE]}{" "}
          {typeof checklist === "string"
            ? (checklist as string).toLowerCase()
            : checklist}
          .
        </div>
      )

    case ACTIVITY_TYPES.DELETE_SCHEDULE:
    case ACTIVITY_TYPES.EDIT_SCHEDULE:
      return (
        <div className={className}>
          {FEED_SUMMARY[type as keyof typeof FEED_SUMMARY]}&nbsp;for&nbsp;
          {checklist} {actor}.
        </div>
      )
    case ACTIVITY_TYPES.COMMENT_ADDED:
      return (
        <div className={className}>
          <Link
            onClick={() =>
              handleOnClick(
                routes.checklists.comments(
                  activity?.checklist?.slug,
                  activity?.checklistOwner?.username,
                  activity.comment
                )
              )
            }
          >
            {activity?.actor?.username}
            &nbsp; comment on &nbsp;
            {activity?.checklist?.title}
          </Link>
        </div>
      )

    case ACTIVITY_TYPES.COPIED_CHECKLIST_UPDATED:
      return (
        <div className={className}>
          <Link
            onClick={() =>
              handleOnClick(
                routes.checklists.show(activity.branchedFrom?.slug, chklOwner)
              )
            }
          >
            {activity.branchedFrom?.title}&nbsp;
          </Link>
          {FEED_SUMMARY[type as keyof typeof FEED_SUMMARY]}&nbsp;
          <Link
            onClick={() =>
              handleOnClick(routes.checklists.show(branchedChecklist.slug))
            }
          >
            {branchedChecklist.title}
          </Link>
        </div>
      )
    default:
      return <></>
  }
}
