import React from "react"

import { Button } from "../Button"

interface UploadMenuProps {
  handleFileSelected: (e: React.ChangeEvent<HTMLInputElement>) => void
  error?: string
}

export function UploadMenu({
  handleFileSelected,
  error,
}: UploadMenuProps): JSX.Element {
  const fileField = React.useRef<HTMLInputElement>(null)

  const handleShowFileDialog = (): void => {
    // Clear out file field in case user selects the
    // same item again.
    fileField.current.value = ""
    fileField.current.click()
  }

  return (
    <div className="flex flex-col items-center py-6">
      <input
        accept="image/png, image/jpeg, image/gif"
        hidden
        onChange={handleFileSelected}
        ref={fileField}
        type="file"
      />

      <Button onClick={handleShowFileDialog}>
        <span className="text-xs font-semibold">Upload file</span>
      </Button>
      {error && (
        <p className="font-normal text-xs text-red-600 my-2 text-center max-w-xs ">
          {error}
        </p>
      )}
      <p className="text-xs font-normal text-gray2 py-2">
        Allowed formats: JPG, PNG
      </p>
    </div>
  )
}
