import debug from "debug"
import { OnInitialize } from "overmind"
import { isServerRendered } from "~src/common/lib"

const d = debug("ChainList/store/auth/onInitialize")

export const onInitialize: OnInitialize = async ({
  actions: {
    auth: { loginFromLocalCredentials },
  },
}) => {
  d("initializing auth")

  // Attempt to log the user in with their cached credentials
  // when we startup the application.
  if (!isServerRendered()) {
    d("attempting to login with local credentials")
    await loginFromLocalCredentials()
  }
}
