import debug from "debug"
import Axios from "axios"

const d = debug("ChainList/lib/uploadFile")

export class FileHelper {
  public static async uploadFile(uploadUrl: string, file: File): Promise<void> {
    d("uploading file:", { uploadUrl, filename: file.name })
    await Axios.put(uploadUrl, file, {
      headers: {
        "Content-Type": file.type,
        "x-amz-acl": "public-read",
      },
    })
  }

  /**
   * Return if a given attachment (File) is a PDF.
   */
  public static isPDF(type: string): boolean {
    return type.toLowerCase().includes("pdf")
  }

  /**
   * Return if a given attachment (File) is a PDF.
   */
  public static isVideo(type: string): boolean {
    return type.toLowerCase().includes("video/quicktime")
  }

  /**
   * Return if a given attachment (File) is an image.
   */
  public static isImage(type: string): boolean {
    return type.toLowerCase().includes("image")
  }
}
