import React from "react"
import AppleLogin from "react-apple-login"
import AppleLogo from "./apple.svg"
import { Button, ButtonProps, SocialTextImage } from "~src/components"
import { useAppleLogin } from "~src/hooks/useAppleLogin"
import loaderImage from "~public/icons/chainlist-loading.svg"

export function AppleLoginButton(props: ButtonProps): JSX.Element {
  const { viaApple } = useAppleLogin()
  const [loading, setLoading] = React.useState<boolean>(false)
  const appleResponse = (res: any) => {
    if (!res?.error) {
      viaApple(res.authorization?.code).finally(() => {
        setLoading(false)
      })
      return
    }
    setLoading(false)
  }
  const btnText = loading ? "Please wait..." : "Continue with Apple"
  const btnImage = loading ? loaderImage : AppleLogo

  return (
    <>
      <AppleLogin
        callback={appleResponse}
        clientId={process.env.NEXT_PUBLIC_APPLE_CLIENT_ID}
        redirectURI={process.env.NEXT_PUBLIC_APPLE_REDIRECT_URI}
        render={(renderProps) => (
          <Button
            loading={loading}
            onClick={() => {
              setLoading(true)
              renderProps?.onClick()
            }}
            variant="secondary"
            {...props}
          >
            <SocialTextImage
              text={btnText}
              imageSpin={loading}
              image={btnImage}
            />
          </Button>
        )}
        responseMode="query"
        scope="email name"
        usePopup
      />
    </>
  )
}
