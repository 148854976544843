export class APIError extends Error {
  apiError: any

  constructor(error: Error) {
    super()
    this.apiError = error
  }

  get message(): string {
    const msg = this.apiError.response?.data.message
    return Array.isArray(msg) ? msg[0] : msg
  }

  get statusCode(): number {
    return this.apiError.response.data.statusCode
  }
}
