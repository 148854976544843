import React from "react"
import { isMobile } from "react-device-detect"

export const useMobileDetect = (): boolean => {
  const [width, setWidth] = React.useState(0)
  const handleWindowResize = () => {
    setWidth(window.innerWidth)
  }

  React.useEffect(() => {
    setWidth(window?.innerWidth)
    window.addEventListener("resize", handleWindowResize)
    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [])
  if (isMobile) return isMobile
  return width <= 768
}
