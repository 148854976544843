import React, { useMemo } from "react"
import dayjs from "dayjs"
import { ChecklistItemsHelper } from "@bonsaichecklist/bonsai-utils"

import { ScheduleHelper } from "~src/common/lib"
import { useMobileDetect } from "../useMobileDetect"
import TimelineItem from "./TimelineItem"

interface Props {
  occurance?: any
  occuranceDate?: Date
  checklist?: Checklist
  runs: any
  hideCompletedItems: boolean
  showChecklist?: boolean
  disableCheckbox?: boolean
}

export function TimelineDetail({
  occurance,
  occuranceDate,
  checklist,
  runs,
  hideCompletedItems,
  showChecklist,
  disableCheckbox,
}: Props): JSX.Element {
  const itemMap = ChecklistItemsHelper.makeItemMap(checklist?.items)
  
  const isMobile = useMobileDetect()
  const itemsToSchedule = useMemo(() => {
    const scheduleItems: any = []
    occurance?.map((occ: any) => {
      const helper = new ScheduleHelper(occ)
      if (occ?.runType === "item") {
        occ?.checklist?.items?.map((item: any) => {
          occ?.itemsToSchedule?.map((i: any) => {
            if (i === item?.slug) {
              scheduleItems.push({
                item: {
                  ...item,
                  parent: !occ.itemsToSchedule.includes(item.parent)
                    ? ""
                    : item.parent,
                },
                isRecurring: occ.recurring,
                pattern: occ.recurrencePattern,
                friendlyDescription: helper.friendlyDescription,
                scheduleId: occ._id,
                occurrenceId: occ.occurrences._id,
                checklistTitle: occ.checklist.title,
                checklistSlug: occ.checklist.slug,
              })
            }
          })
        })
      } else {
        occ?.checklist?.items?.map((item: any) => {
          scheduleItems.push({
            item,
            isRecurring: occ.recurring,
            pattern: occ.recurrencePattern,
            friendlyDescription: helper.friendlyDescription,
            scheduleId: occ._id,
            occurrenceId: occ.occurrences._id,
            checklistTitle: occ.checklist.title,
            checklistSlug: occ.checklist.slug,
          })
        })
      }
    })
    return scheduleItems
  }, [occurance])

  const occDate = `${dayjs(occuranceDate).format("MMM").toUpperCase()}. ${dayjs(
    occuranceDate
  ).format("D")} ${
    !dayjs().isSame(occuranceDate, "year")
      ? dayjs(occuranceDate).format("YYYY")
      : ""
  }`

  return (
    <div className={` gap-3 ${isMobile ? "block" : "flex"}`}>
      {!isMobile && (
        <>
          <div className="flex flex-col items-center w-5">
            <div>
              <div className="flex items-center justify-center">
                <div className="w-5 h-5 bg-white rounded-full border-solid border-primaryBlue border-4" />
              </div>
            </div>
            <div className="w-px h-full bg-gray4" />
          </div>
        </>
      )}
      <h2 className="text-xs max-w-32 bottom-24 font-medium text-customGray mb-10">
        {occDate}
      </h2>
      <div className="flex flex-col mb-10 flex-1">
        {itemsToSchedule.map(
          (
            {
              item,
              isRecurring,
              scheduleId,
              occurrenceId,
              pattern,
              friendlyDescription,
              checklistTitle,
              checklistSlug,
            }: {
              item: ChecklistItem
              isRecurring: boolean
              scheduleId: string
              occurrenceId: string
              isCompleted: boolean
              pattern: string
              friendlyDescription: string
              checklistTitle: string
              checklistSlug: string
            },
            i: React.Key
          ): JSX.Element => {
            if (!item.parent) {
              return (
                <TimelineItem
                  bold
                  friendlyDescription={friendlyDescription}
                  hideCompletedItem={hideCompletedItems}
                  isRecurring={isRecurring}
                  item={item}
                  itemMap={itemMap}
                  key={i}
                  occurrenceId={occurrenceId}
                  pattern={pattern}
                  runs={runs}
                  scheduleId={scheduleId}
                  checklistTitle={checklistTitle}
                  showChecklist={showChecklist}
                  checklistSlug={checklistSlug}
                  disableCheckbox={disableCheckbox}
                />
              )
            } else return null
          }
        )}
      </div>
    </div>
  )
}
