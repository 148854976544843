import React from "react"
import { ExpansionCaret } from "../ExpansionCaret"

type Props = {
  itemName: string
  itemCount: number
  handleToggleExpand: () => void
  expanded: boolean
}

export function CategoryHeader({
  itemName,
  itemCount = 0,
  handleToggleExpand,
  expanded,
}: Props): JSX.Element {
  return (
    <div className="flex items-center">
      <h4 className=" text-base text-black font-bold">{`${itemName} (${itemCount})`}</h4>
      <div className="border border-gray4 flex-1 mx-3" />
      <span className="cursor-pointer" onClick={handleToggleExpand}>
        <ExpansionCaret
          chklCategoryItems
          className=" text-gray-600 hover:text-primaryBlue"
          expanded={expanded}
        />
      </span>
    </div>
  )
}
