import React from "react"
import { Modal } from "src/components"
import { SubscriberForm } from "../SubscriberForm"

type SubscriberModalProps = {
  onClose: () => void
  checklist: Checklist
  showConfirmModal: boolean
}

export function SubscriberModal({
  onClose,
  checklist,
  showConfirmModal,
}: SubscriberModalProps): JSX.Element {
  React.useEffect(() => {
    const closeModal = (e: any) => {
      if (e.key === "Escape") {
        onClose()
      }
    }

    window.addEventListener("keydown", closeModal)

    return () => window.removeEventListener("keydown", closeModal)
  }, [])

  return (
    <Modal
      config={{
        showCloseButton: false,
      }}
      onClose={onClose}
    >
      <SubscriberForm
        checklist={checklist}
        onClose={onClose}
        showConfirmModal={showConfirmModal}
      />
    </Modal>
  )
}
