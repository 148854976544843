import debug from "debug"
import { Context } from "overmind"
import rfdc from "rfdc"
import { findChildren } from "~src/common/lib"
import { ChecklistService } from "~src/store/checklist/effects"

import { generateSlug } from "@bonsaichecklist/bonsai-utils"
import dayjs from "dayjs"

const d = debug("ChainList/store/checklist/actions/insert")

export async function storeChecklistItem(
  {
    state: {
      checklist: { current, itemMap, checklistScheduleMap, schedules },
    },
  }: Context,
  item: ChecklistItem
): Promise<void> {
  if (!current) return

  itemMap[item.slug] = item

  if (item?.itemType === "unScheduled" && !item?.parent) {
    current.unscheduleItems.push(item.slug)
  }

  if (item?.itemType === "scheduled" && !item?.parent) {
    checklistScheduleMap[item.slug] = {
      itemSlug: item.slug,
      recurrencePattern: "single-occurrence",
      startedAt: dayjs().set("hour", 9).set("minute", 0).add(1, "day").toDate(),
      recurring: false,
      itemsToSchedule: [item.slug],
    }
    schedules.push(item.slug)
  }
}

export function copyItems(
  {
    actions: {
      checklist: { focusItemWithDelay, placeAfter },
    },
    state: {
      checklist: {
        itemMap,
        rootItems,
        current,
        checklistScheduleMap,
        schedules,
      },
    },
  }: Context,
  values: {
    item: ChecklistItem
    copiedFromChecklist: Checklist
    refItemMap?: ChecklistItemMap
    subItems?: ChecklistItem[]
    scheduleMap?: ChecklistScheduleMap
  }
): ChecklistItem {
  const {
    item,
    copiedFromChecklist,
    refItemMap,
    subItems,
    scheduleMap,
  } = values

  const newParentSlug = generateSlug()

  const newItem = rfdc({ proto: false })(item)
  newItem.slug = newParentSlug

  if (newItem.linkedList) {
    delete newItem.linkedList
    newItem.body = newItem.body.replace(/[[\]]/g, "")
  }

  newItem.order = rootItems.length + 1

  newItem.isCopied = true

  delete newItem?._id
  delete newItem?.id

  if (item.itemType === "unScheduled") {
    current.unscheduleItems.push(newItem.slug)
  } else {
    checklistScheduleMap[newItem.slug] = {
      itemSlug: newItem.slug,
      recurrencePattern:
        scheduleMap?.[item?.slug]?.recurrencePattern || "single-occurrence",
      startedAt:
        scheduleMap?.[item?.slug]?.startedAt ||
        dayjs().set("hour", 9).set("minute", 0).add(1, "day").toDate(),
      recurring: scheduleMap[item.slug]?.recurring || false,
      until: scheduleMap[item.slug]?.until || null,
      itemsToSchedule: [newItem.slug],
      customOptions: scheduleMap[item.slug]?.customOptions,
    }
    schedules.push(newItem.slug)
  }
  newItem.itemType = item.itemType
  itemMap[newItem.slug] = newItem
  itemMap[newItem.slug]["copiedFrom"] = copiedFromChecklist
  itemMap[newItem.slug]["copiedAt"] = new Date()

  if (itemMap[newParentSlug].parent) delete itemMap[newParentSlug].parent

  if (subItems) {
    const newSubItems = rfdc({ proto: false })(subItems)

    newSubItems.forEach((subItem) => {
      subItem.parent = newParentSlug
      subItem.isCopied = true
      subItem.itemType = newItem.itemType

      delete subItem?._id
      delete subItem?.id

      findChildren(subItem, itemMap, refItemMap)
    })
    itemMap[newItem.slug].children = newSubItems
  }

  const lastRootItem = rootItems[rootItems.length - 1]

  placeAfter({
    currentItemSlug: newItem.slug,
    targetItemSlug: lastRootItem?.slug,
  })
  focusItemWithDelay(newItem.slug)
  return newItem
}

export function insertItem(
  {
    actions: {
      checklist: { storeChecklistItem },
    },
    state: {
      checklist: { current, rootItems },
    },
  }: Context,
  values: {
    value: string
    type?: ItemType
  }
): ChecklistItem | undefined {
  if (!current) return undefined

  const { value, type } = values

  const item = ChecklistService.generateNewItem({
    body: value,
    order: rootItems.length + 1,
    itemType: type,
  })

  storeChecklistItem(item)

  return item
}

export function insertItemOld(
  {
    actions: {
      checklist: { storeChecklistItem },
    },
    state: {
      checklist: { current, rootItems },
    },
  }: Context,
  value: string
): ChecklistItem | undefined {
  if (!current) return undefined
  const item = ChecklistService.generateNewItem({
    body: value,
    order: rootItems.length + 1,
  })
  storeChecklistItem(item)
  return item
}

export function insertItemAbove(
  {
    actions: {
      checklist: { focusItemWithDelay, placeBefore, storeChecklistItem },
    },
    state: {
      checklist: { current, itemMap, schedules },
    },
  }: Context,
  slug: string
): ChecklistItem | undefined {
  if (!current) return undefined

  const item = itemMap[slug]

  if (!item) return undefined

  // Don't create blank items around a blank item
  if (!item.body) return undefined

  d("insert item after:", item)

  const newItem = ChecklistService.generateNewItem({
    parent: item.parent,
    itemType: item?.itemType,
  })

  d("insert new item:", newItem)

  storeChecklistItem(newItem)

  placeBefore({ currentItemSlug: newItem.slug, targetItemSlug: item.slug })

  d("focus new item")

  focusItemWithDelay(newItem.slug)

  return newItem
}

export function insertItemBelow(
  {
    actions: {
      checklist: { focusItemWithDelay, placeAfter, storeChecklistItem },
    },
    state: {
      checklist: { current, itemMap, schedules },
    },
  }: Context,
  slug: string
): ChecklistItem | undefined {
  const item = itemMap[slug]

  d("insert item after:", item)

  if (!item) return undefined

  // Don't create blank items around a blank item
  if (!item.body) return undefined

  const newItem = ChecklistService.generateNewItem({
    parent: item.parent,
    itemType: item?.itemType,
  })

  d("insert new item:", newItem)

  storeChecklistItem(newItem)

  placeAfter({ currentItemSlug: newItem.slug, targetItemSlug: item.slug })

  d("focus new item")

  focusItemWithDelay(newItem.slug)

  return newItem
}

export function handleUpdateFollowers(
  {
    state: {
      checklist: { current },
    },
  }: Context,
  followers: Follower[]
): void {
  current.followers = followers
}
