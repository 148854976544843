type AvatarSize = "small" | "medium" | "large"
import dayjs from "dayjs"
import { isString } from "lodash"
import { SINGUP_TOOLTIP, SUBBED_AS_LOCAL_KEY } from "~src/common/constants"

export class ChecklistHelper {
  constructor(private checklist?: Checklist) {}

  isOwner(user?: User): boolean {
    if (!user || !this.checklist?.owner) return false
    const ownerId = isString(this.checklist.owner)
      ? this.checklist.owner
      : this.checklist.owner.id
    return user.id === ownerId
  }

  findFollowers(user?: User): Follower | undefined {
    return this.checklist?.followers?.find(
      (f) => f?.user.toString() === user?.id
    )
  }

  isSubscribed(user?: User): boolean {
    return Boolean(this.findFollowers(user))
  }
  static sortSchedulesByNextRun = (
    scheduleMap: ChecklistScheduleMap,
    a: ChecklistItem,
    b: ChecklistItem
  ) => {
    const scheduleA = scheduleMap[a.slug]
    const scheduleB = scheduleMap[b.slug]
    return dayjs(scheduleA.nextRunAt).diff(dayjs(scheduleB.nextRunAt))
  }
  getGuestSubscribtion(slug: string): boolean {
    try {
      const localSubs = JSON.parse(localStorage.getItem(SUBBED_AS_LOCAL_KEY))

      const hasSubbedItem = localSubs?.find(
        (item: LocalSubbedTemplate) => item.slug === slug && item.isSubscribed
      )

      return hasSubbedItem
    } catch (e) {
      console.log(e)
      return false
    }
  }

  getSubscriberEmail(slug: string): string | null {
    try {
      const localSubs = JSON.parse(localStorage.getItem(SUBBED_AS_LOCAL_KEY))
      const hasSubbedItem = localSubs?.filter(
        (item: LocalSubbedTemplate) => item.slug === slug && item.isSubscribed
      )

      if (!hasSubbedItem.length) {
        return null
      }

      return hasSubbedItem[0]?.email
    } catch (e) {
      return null
    }
  }

  guestUnsubscribe(slug: string, email: string): string | null {
    try {
      const localSubs = JSON.parse(localStorage.getItem(SUBBED_AS_LOCAL_KEY))

      const hasSubbedItem = localSubs?.filter(
        (item: LocalSubbedTemplate) =>
          item.slug !== slug && item.email !== email
      )
      localStorage.setItem(SUBBED_AS_LOCAL_KEY, JSON.stringify(hasSubbedItem))
      return null
    } catch (e) {
      console.log(e)
      return null
    }
  }

  markAsSubscribe(slug: string): boolean {
    try {
      const localSubs = JSON.parse(localStorage.getItem(SUBBED_AS_LOCAL_KEY))
      let isSubscribed = false
      for (let i in localSubs) {
        if (localSubs[i].slug === slug) {
          localSubs[i].isSubscribed = true
          isSubscribed = true
          break
        }
      }
      localStorage.setItem(SUBBED_AS_LOCAL_KEY, JSON.stringify(localSubs))
      return isSubscribed
    } catch (e) {
      console.log(e)
      return false
    }
  }

  setleTooltip(value: boolean) {
    localStorage.setItem(SINGUP_TOOLTIP, "" + value)
  }

  findSubscribedAt(user?: User): Date | undefined {
    return this.findFollowers(user)?.followedAt
  }

  ownerAvatarUrl(size: AvatarSize): string {
    return (
      this.checklist?.owner?.avatar && this.checklist?.owner?.avatar[size]?.url
    )
  }

  get copy(): Checklist | undefined {
    return this.checklist?.parent
  }

  get isCopy(): boolean {
    return Boolean(this.copy)
  }

  get ownerUsername(): string {
    return this.checklist?.owner?.username
  }

  get linkedListMap(): LinkedListMap {
    if (!Object.keys(this.checklist?.linkedLists || {}).length) return {}

    const linkedListMap = this.checklist?.linkedLists.reduce(
      (map, chkl: Checklist) => {
        map[chkl.id] = chkl
        return map
      },
      {} as LinkedListMap
    )
    return linkedListMap
  }

}
