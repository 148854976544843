import { MutateOptions, useMutation } from "react-query"
import { FEEDBACK } from "~src/common/apiRoutes"
import { chainListAxios } from "~src/common/lib"

interface RequestData {
  username?: string
  text?: string
  metadata?: GenericObject
}

async function sendFeedback(body: RequestData): Promise<any> {
  const { data } = await chainListAxios.post<any>(FEEDBACK.send, body)
  return data
}

type Options = MutateOptions<any, Error, RequestData>

export function useFeedback(options?: Options) {
  const metadata = {
    url: window?.location?.href,
  }
  return useMutation(
    ({ username, text }) => sendFeedback({ username, text, metadata }),
    options
  )
}
