import { AuthResponse } from "~src/services"
type AuthState = "unauthenticated" | "authenticating" | "authenticated"

type State = {
  admin?: boolean
  state: AuthState
  error?: Error
  user?: User
  requestUsername?: boolean
  searchAPIToken?: string
  communitySearchAPIToken?: string
  showLoginModal?: boolean
  showSignupModal?: boolean
  action?: { key: string; description?: string }
  tempAuthResponse?: AuthResponse,
  infoDialogs?: InfoDialog
}

export const state: State = {
  state: "authenticating", //"unauthenticated",
  showLoginModal: false,
  showSignupModal: false,
}

/*
import { statemachine } from "overmind"

type State =
  | {
      state: "unauthenticated"
      error?: Error
    }
  | { state: "authenticating" }
  | {
      state: "authenticated"
      user: User
    }

export const state = statemachine<State>(
  {
    unauthenticated: ["authenticated"],
    authenticating: ["unauthenticated", "authenticated"],
    authenticated: ["unauthenticated"],
  },
  {
    state: "unauthenticated",
  }
)
*/
