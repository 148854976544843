import React from "react"
import styles from "./Radio.module.css"
import classnames from "classnames"

interface RadioProps {
  id: string
  labelFor: string
  checked: boolean
  label: JSX.Element
  name: string
}

export function Radio({
  id,
  checked,
  label,
  labelFor,
  name,
  ...props
}: RadioProps): JSX.Element {
  const radioLabelClasses = classnames(styles.radioLabel, {
    [styles.checked]: checked,
  })

  const radioButtonClasses = classnames(styles.radioButton, {
    [styles.checked]: checked,
  })

  return (
    <div className="flex py-3">
      <input
        checked={checked}
        className="hidden"
        id={id}
        name={name}
        readOnly
        type="radio"
        {...props}
      />
      <label className={radioLabelClasses} htmlFor={labelFor}>
        <span className={radioButtonClasses} />
        {label}
      </label>
    </div>
  )
}
