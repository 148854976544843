import { Context } from "overmind"
import { ChecklistItemsHelper } from "@bonsaichecklist/bonsai-utils"

export async function deleteItem(
  { state: { checklist } }: Context,
  slug: string
): Promise<void> {
  const item: ChecklistItem = checklist.itemMap[slug]
  if (!item) return

  checklist.focusedTitle = false
  checklist.focusedDescription = false

  checklist.current.unscheduleItems = checklist.current?.unscheduleItems?.filter(
    (item) => item !== slug
  )

  const subItems = ChecklistItemsHelper.getSubItemsOfParent(
    checklist.itemMap,
    slug
  )

  if (subItems?.length) {
    subItems.forEach((subItem) => {
      delete checklist.itemMap[subItem.slug]
    })
  }

  checklist.schedules = checklist?.schedules?.filter((item) => item !== slug)

  if (checklist.checklistScheduleMap[item?.parent || item.slug]) {
    checklist.checklistScheduleMap[
      item?.parent || item?.slug
    ].itemsToSchedule = checklist.checklistScheduleMap[
      item?.parent || item?.slug
    ]?.itemsToSchedule.filter((s) => s !== slug)
  }

  delete checklist.activeItemSlug
  delete checklist.itemMap[slug]
  delete checklist.checklistScheduleMap[slug]
}
