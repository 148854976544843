import React from "react"
import { inputClassNames } from "~src/common/lib/inputClassNames"

export interface InputProps
  extends Omit<React.HTMLProps<HTMLInputElement>, "size" | "ref"> {
  size?: "sm" | "md" | "lg"
  innerPlaceholder?: string
}

export const Input = React.forwardRef(
  (
    {
      className,
      size = "md",
      defaultValue,
      disabled,
      placeholder,
      onFocus,
      onBlur,
      onChange,
      value,
      id,
      ...props
    }: InputProps,
    ref: React.Ref<HTMLInputElement>
  ): JSX.Element => {
    const [isFocused, setIsFocused] = React.useState(false)

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>): void => {
      onFocus && onFocus(e)
      setIsFocused(true)
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
      onBlur && onBlur(e)
      setIsFocused(false)
    }

    const handleChange = (e: React.FocusEvent<HTMLInputElement>): void => {
      onChange && onChange(e)
    }

    const classes = inputClassNames({
      className,
      disabled,
      size,
      focused: isFocused,
    })

    id = id || randomID()

    return (
      <div className="flex flex-col flex-1">
        <label className="font-bold mb-2" htmlFor={id}>
          {placeholder}
        </label>
        <div className={classes}>
          <input
            aria-label={placeholder}
            defaultValue={defaultValue}
            id={id}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            placeholder={props.innerPlaceholder}
            ref={ref}
            value={value}
            {...props}
          />
        </div>
      </div>
    )
  }
)

function randomID(): string {
  return `input-${Math.floor(Math.random() * 10000)}`
}
