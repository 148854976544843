import classnames from "classnames"
import React from "react"

export interface ContainerProps extends React.HTMLProps<HTMLDivElement> {
  className?: string
  children: React.ReactNode
  width?:
    | "xs"
    | "sm"
    | "md"
    | "lg"
    | "xl"
    | "2xl"
    | "3xl"
    | "4xl"
    | "5xl"
    | "6xl"
    | "7xl"
    | "full"
}

export function Container({
  children,
  className,
  width = "3xl",
  ...props
}: ContainerProps): JSX.Element {
  const classes = classnames(
    "mx-auto px-0 sm:px-4",
    {
      "max-w-xs": width === "xs",
      "max-w-sm": width === "sm",
      "max-w-md": width === "md",
      "max-w-lg": width === "lg",
      "max-w-xl": width === "xl",
      "max-w-2xl": width === "2xl",
      "max-w-3xl": width === "3xl",
      "max-w-4xl": width === "4xl",
      "max-w-5xl": width === "5xl",
      "max-w-6xl": width === "6xl",
      "max-w-7xl": width === "7xl",
      "max-w-full": width === "full",
    },
    className
  )
  return (
    <section {...props} className={classes}>
      {children}
    </section>
  )
}
