import algoliasearch from "algoliasearch/lite"
import React from "react"
import { InstantSearch } from "react-instantsearch-dom"
import {
  ALGOLIA_APP_ID,
  ALGOLIA_INDEX,
  ALGOLIA_SEARCH_ONLY_API_KEY,
  INITIATE_SEARCH_ON_CHARS,
} from "~src/common/constants"
import { useState, useStore } from "~src/store"

export interface SearchProviderProps {
  children?: React.ReactNode
  hitsPerPage?: number
  type?: "community" | "nav"
}

export function SearchProvider({
  children,
  hitsPerPage,
  type = "nav",
}: SearchProviderProps): JSX.Element {
  const {
    auth: { searchAPIToken, communitySearchAPIToken },
  } = useState()

  if (process.env.NODE_ENV === "development")
    console.table({
      ALGOLIA_INDEX,
      ALGOLIA_APP_ID,
      ALGOLIA_SEARCH_ONLY_API_KEY,
      searchAPIToken,
    })

  const getSearchAPIToken = () => {
    if (type === "community") {
      return communitySearchAPIToken
    }
    return searchAPIToken
  }

  const searchClient = algoliasearch(ALGOLIA_APP_ID, getSearchAPIToken())

  const algoliaSearchClient = {
    ...searchClient,
    search(requests: any) {
      const {
        params: { query = false },
      } = requests[0] || {}

      if (!query || !query.length) {
        return Promise.resolve({
          results: requests.map(() => ({
            nbHits: 0,
            hits: [] as string[],
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
          })),
        })
      }

      if (query?.length >= INITIATE_SEARCH_ON_CHARS) {
        requests[0]["params"] = {
          ...requests[0]["params"],
          hitsPerPage: hitsPerPage || 10,
        }
        return searchClient.search(requests)
      }

      return null
    },
  }

  return (
    <InstantSearch indexName={ALGOLIA_INDEX} searchClient={algoliaSearchClient}>
      {children}
    </InstantSearch>
  )
}
