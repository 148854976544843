import { Context } from "overmind"
import { AttachmentService } from "~src/services"

export async function uploadAttachments(
  {
    state: {
      checklist: { itemMap, current },
    },
  }: Context,
  { itemSlug, files }: { itemSlug: string; files: File[] }
): Promise<void> {
  const attachments = await AttachmentService.upload(
    current.slug,
    itemSlug,
    files
  )
  const item = itemMap[itemSlug]
  item.attachments = [...(item.attachments || []), ...attachments]
}

export function removeAttachment(
  {
    state: {
      checklist: { itemMap },
    },
  }: Context,
  { itemSlug, attachmentSlug }: { itemSlug: string; attachmentSlug: string }
): void {
  const item = itemMap[itemSlug]
  item.attachments = item.attachments.filter((i) => i.slug !== attachmentSlug)
}
