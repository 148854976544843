import { OnInitialize } from "overmind"
import debug from "debug"

const d = debug("ChainList/store/checklist/onInitialize")

export const onInitialize: OnInitialize = async (
  { state, actions, effects },
  overmind
) => {
  d("initializing checklist")
}
